import { useStyletron } from "baseui";
import {
  Card as BaseCard,
  CardProps as BaseCardProps,
  StyledBody,
} from "baseui/card";
import React from "react";
import { StyleObject } from "styletron-react";

type CardProps = {
  $style?: StyleObject;
  children?: React.ReactChild | React.ReactChild[];
} & BaseCardProps;

export default function Card({
  $style,
  children,
  ...props
}: CardProps): React.ReactElement {
  const [, theme] = useStyletron();

  return (
    <BaseCard
      overrides={{
        Root: {
          style: {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            marginBottom: "20px",
            backgroundColor: theme.colors.backgroundPrimary,
            ...$style,
          },
        },
        HeaderImage: {
          style: { width: "100%" },
        },
      }}
      {...props}
    >
      <StyledBody>{children}</StyledBody>
    </BaseCard>
  );
}
