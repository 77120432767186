import { useStyletron } from "baseui";
import React, {
  ButtonHTMLAttributes,
  cloneElement,
  DetailedHTMLProps,
  ReactElement,
} from "react";

type IconsSwitchProps = {
  value: boolean | number;
  iconFalse: ReactElement<SVGSVGElement>;
  iconTrue: ReactElement<SVGSVGElement>;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export default function IconsSwitch({
  value,
  iconFalse,
  iconTrue,
  ...props
}: IconsSwitchProps): ReactElement {
  const [css] = useStyletron();

  return (
    <button
      className={css({
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        border: "none",
        borderRadius: "8px",
        padding: 0,
      })}
      {...props}
    >
      <div
        className={css({
          margin: "8px",
          marginRight: "6px",
        })}
      >
        {cloneElement(iconFalse, {
          className: css({
            opacity: !value ? 1 : 0.4,
          }),
        })}
      </div>

      <div
        className={css({
          margin: "8px",
          marginLeft: "6px",
        })}
      >
        {cloneElement(iconTrue, {
          className: css({
            opacity: value ? 1 : 0.4,
          }),
        })}
      </div>
    </button>
  );
}
