import { Textarea as BaseTextArea, TextareaProps } from "baseui/textarea";
import { ChangeEvent, FormEvent } from "react";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-react";

type Props = {
  $style?: StyleObject;
} & TextareaProps;

const TextArea = ({ $style, ...rest }: Props): React.ReactElement => {
  return (
    <BaseTextArea
      overrides={
        {
          Root: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            style: ({ $theme }: any) => ({
              borderTopLeftRadius: $theme.borders.radius300,
              borderTopRightRadius: $theme.borders.radius300,
              borderBottomRightRadius: $theme.borders.radius300,
              borderBottomLeftRadius: $theme.borders.radius300,
            }),
          },
          Input: {
            style: {
              backgroundColor: "transparent",
              ":-webkit-autofill": {
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
              },
              ...$style,
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
      rows={5}
      {...rest}
    />
  );
};

export default TextArea;

export function ControlledTextArea({
  control,
  name,
  rules,
  ...rest
}: UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <TextArea
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            onChange(e.target.value)
          }
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}
