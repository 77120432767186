import { Article } from "../Articles/articles";

export enum CommentableType {
  Article = "Article",
  Game = "Game",
  Magazine = "Magazine",
  Video = "Video",
}

export type Comment = {
  id: number;
  content: string;
  upvoteCount: number;
  downvoteCount: number;
  isFeatured: boolean;
  isHidden: boolean;
  commentableId: number;
  commentableType: string;
  article: Article;
  authorId: number;
  author: User;
  createdAt: string;
  updatedAt: string;
  activityLogs?: ActivityLog[];
};

export type ActivityLog = {
  activity: string;
  createdAt: string;
  id: number;
  properties: {
    client: {
      ipAddress: string;
    };
  };
  subjectId: number;
  subjectType: string;
  userId: number;
};
