import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  name: string;
  taggableId?: number | null;
  taggableTypes?: { id: string; label?: string }[];
  taggableType?: string | null;
  gamesSelect?: { id: number; label?: string }[];
  articlesSelect?: { id: number; label?: string }[];
  kind?: string;
  kinds?: { id: string; label?: string }[];
};

export const TAGS_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "kind",
        label: "Rodzaj",
        type: FIELDS.Input,
        dataType: "tagKind",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "taggableType",
        label: "Typ",
        type: FIELDS.Input,
        span: 4,
        dataType: "taggableType",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "taggableId",
        label: "Przypisany obiekt",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "kinds",
        label: "Rodzaj",
        type: FIELDS.TagsKindSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "taggableTypes",
        label: "Typ",
        type: FIELDS.TaggableTypesSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        span: 4,
        dataType: "date",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        span: 4,
        dataType: "date",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "taggables",
    label: "Otagowane obiekty?",
    fields: [
      // {
      //   id: "taggables",
      //   label: "",
      //   type: FIELDS.Input,
      //   span: 12,
      //   show: { visible: true },
      //   create: { visible: false },
      //   edit: { visible: false },
      // },
    ],
  },
];
