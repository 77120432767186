import {
  API,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";

interface TableOfContentsData extends BlockToolData {
  leftColumn: string;
  rightColumn: string;
}

export default class TableOfContents implements BlockTool {
  public static isReadOnlySupported = true;
  public static enableLineBreaks = true;
  public static sanitize = {
    div: true,
    br: true,
    ul: true,
    li: true,
    h1: true,
    h2: true,
    h3: true,
    h4: true,
    h5: true,
    h6: true,
  };

  private data: TableOfContentsData;
  private isReadOnly: boolean;
  private api: API;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Spis treści",
      // TODO: Find appropriate icon
      icon: "T",
    };
  }

  constructor({
    data,
    readOnly,
    api,
  }: BlockToolConstructorOptions<TableOfContentsData>) {
    this.data = data;
    this.isReadOnly = readOnly;
    this.api = api;
  }

  render(): HTMLElement {
    const container = document.createElement("div");
    container.classList.add(this.api.styles.block);
    container.style.display = "flex";

    const leftColumn = document.createElement("div");
    leftColumn.classList.add(this.api.styles.input, "leftColumn");
    leftColumn.contentEditable = String(!this.isReadOnly);
    leftColumn.innerHTML = this.data.leftColumn ?? "";

    const rightColumn = document.createElement("div");
    rightColumn.classList.add(this.api.styles.input, "rightColumn");
    rightColumn.contentEditable = String(!this.isReadOnly);
    rightColumn.style.marginTop = "0px";
    rightColumn.innerHTML = this.data.rightColumn ?? "";

    container.appendChild(leftColumn);
    container.appendChild(rightColumn);

    return container;
  }

  save(rootElement: HTMLElement): TableOfContentsData {
    const leftColumn =
      rootElement.querySelector(".leftColumn")?.innerHTML || "";
    const rightColumn =
      rootElement.querySelector(".rightColumn")?.innerHTML || "";

    return {
      ...this.data,
      leftColumn,
      rightColumn,
    };
  }
}
