import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, DeviceFloppy, FileOff } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import Content from "../../../components/content";
import DateTimePicker from "../../../components/date-time-picker";
import { ControlledEditor } from "../../../components/editor";
import { ControlledFilesPicker } from "../../../components/files-picker";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ImagePreview, {
  ImagePreviewSize,
} from "../../../components/image-preview";
import { ControlledInput } from "../../../components/input";
import {
  ControlledBrandSelect,
  ControlledEditorsSelect,
  ControlledGamesSelect,
  ControlledLabelsSelect,
  ControlledTagsSelect,
  ControlledVideoTypesSelect,
} from "../../../components/select";
import { ControlledTextArea } from "../../../components/text-area";
import { BASIC_AUTH } from "../../../constants";
import { useImageLibrary } from "../../../contexts/image-library-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Tag } from "../../Tags/tags";
import { FormInputs, VIDEOS_FIELDS } from "../videos.form";

export default function VideosEdit(): React.ReactElement {
  const [publishedAtDate, setPublishedAtDate] = useState<Date | null>(null);
  const [isSlugChangedByUser, setIsSlugChangedByUser] = useState(false);
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const publishedAt = publishedAtDate && publishedAtDate.toISOString();
  const { open } = useImageLibrary();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/videos/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: queryData, refetch } = useQuery(
    `video${id}CurrentData`,
    getData,
    {
      cacheTime: 0,
    }
  );

  const editData = async (values: FormInputs) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/videos/${id}`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("userEdit", editData);

  const secondsToHHMMSS = (seconds: number) => {
    if (seconds) {
      return (
        Math.floor(seconds / 3600) +
        ":" +
        ("0" + (Math.floor(seconds / 60) % 60)).slice(-2) +
        ":" +
        ("0" + (seconds % 60)).slice(-2)
      );
    }
    return 0;
  };

  const formatToSeconds = (duration: string) => {
    const splitedDuration = duration.split(":");
    return (
      Number(splitedDuration[0]) * 3600 +
      Number(splitedDuration[1]) * 60 +
      Number(splitedDuration[2])
    );
  };

  const onSubmit = async ({
    title,
    slug,
    lead,
    types,
    duration,
    source,
    thumbnailUrl,
    isFeaturedOnHomePage,
    isBlockedFromHomePage,
    tags,
    labels,
    editors,
    games,
    body,
    trackingCode,
    brands,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync({
        title,
        slug,
        lead,
        type: types && types[0]?.id,
        duration: formatToSeconds(String(duration)),
        source,
        thumbnailUrl,
        isFeaturedOnHomePage: !!isFeaturedOnHomePage,
        isBlockedFromHomePage: !!isBlockedFromHomePage,
        publishedAt,
        tagIds: tags && tags.map(({ id }: { id: number }) => id),
        labelIds: labels && labels.map(({ id }: { id: number }) => id),
        editorId: editors && editors[0]?.id,
        gameId: games && games[0]?.id,
        body,
        trackingCode,
        brand: brands && brands[0]?.id,
      });

      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/videos/${id}`);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (queryData) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (queryData) {
      setIsFetching(false);
    }
  }, [queryData]);

  useEffect(() => {
    setIsSlugChangedByUser(false);
  }, []);

  useEffect(() => {
    setValue("title", queryData?.title);
    setValue("slug", queryData?.slug);
    setValue("lead", queryData?.lead);
    setValue("types", [{ id: queryData?.type }]);
    setValue("duration", secondsToHHMMSS(queryData?.duration));
    setValue("source", queryData?.source);
    setValue("thumbnailUrl", queryData?.thumbnailUrl);
    setValue("isFeaturedOnHomePage", queryData?.isFeaturedOnHomePage);
    setValue("isBlockedFromHomePage", queryData?.isBlockedFromHomePage);
    setValue(
      "tags",
      queryData?.tags?.map((tag: Tag) => ({ id: tag.id, label: tag.name }))
    );
    setValue(
      "labels",
      queryData?.labels?.map((tag: Tag) => ({ id: tag.id, label: tag.name }))
    );
    setValue("editors", [
      { id: queryData?.editorId, label: queryData?.editor.nick },
    ]);

    setValue("games", [
      { id: queryData?.gameId, label: queryData?.game?.originalTitle },
    ]);
    queryData?.publishedAt
      ? setPublishedAtDate(new Date(queryData?.publishedAt))
      : setPublishedAtDate(null);
    setValue("body", queryData?.body);
    setValue("trackingCode", queryData?.trackingCode);
    queryData?.brand && setValue("brands", [{ id: queryData?.brand }]);
  }, [queryData]);

  return (
    <article>
      <Header
        title={queryData?.title}
        labels={["Wideo", "Edytowanie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () =>
              history.push(
                history.location.pathname.split("/").slice(0, -1).join("/")
              ),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {VIDEOS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={item.caption}
                      required={item.edit.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="48px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={isLoading}
                          $style={{ marginTop: "30px" }}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          <p
                            className={css({
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            })}
                          >
                            {item.label}
                          </p>
                        </ControlledCheckbox>
                      ) : item.type === FIELDS.FilesPicker ? (
                        <ControlledFilesPicker
                          control={control}
                          name={item.id}
                          accept={["image/jpeg", "image/png"]}
                          maxSize={134217728}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.TagsSelect ? (
                        <ControlledTagsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.VideoTypesSelect ? (
                        <ControlledVideoTypesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.BrandSelect ? (
                        <ControlledBrandSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ImagePicker ? (
                        <>
                          {watch(item.id as keyof FormInputs) && (
                            <ImagePreview
                              image={{
                                path: watch(item.id as keyof FormInputs),
                              }}
                              size={ImagePreviewSize.Large}
                            />
                          )}
                          <Button
                            type="button"
                            size="compact"
                            kind={KIND.secondary}
                            onClick={() =>
                              open((image) =>
                                setValue(
                                  item.id as keyof FormInputs,
                                  `${process.env.REACT_APP_CDN_URL}/images/${image.path}`
                                )
                              )
                            }
                            $style={{ marginTop: "10px" }}
                          >
                            Wybierz z biblioteki lub wgraj
                          </Button>
                        </>
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DateTimePicker ? (
                        <Block display="flex" alignItems="center">
                          <DateTimePicker
                            date={publishedAtDate}
                            setDate={setPublishedAtDate}
                          />

                          <Button
                            kind={KIND.secondary}
                            $style={{
                              marginBottom: "-12px",
                            }}
                            onClick={(event: MouseEvent) => {
                              event.preventDefault();
                              setPublishedAtDate(new Date());
                            }}
                          >
                            Ustaw aktualny czas
                          </Button>
                        </Block>
                      ) : item.type === FIELDS.Editor ? (
                        <ControlledEditor control={control} name={item.id} />
                      ) : item.type === FIELDS.LabelsSelect ? (
                        <ControlledLabelsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.EditorsSelect ? (
                        <ControlledEditorsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.GamesSelect ? (
                        <ControlledGamesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          masked={item.mask !== undefined}
                          mask={item.mask}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                          {...(item.id === "slug" && {
                            onKeyUp: () => setIsSlugChangedByUser(true),
                            $style: {
                              color: isSlugChangedByUser ? "#000" : "#999",
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
