import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, DeviceFloppy, FileOff } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import ColorPicker, {
  ControlledColorPicker,
} from "../../../components/color-picker";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ImagePreview, {
  ImagePreviewSize,
} from "../../../components/image-preview";
import { ControlledInput } from "../../../components/input";
import { BASIC_AUTH } from "../../../constants";
import { useImageLibrary } from "../../../contexts/image-library-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { FormInputs, SECTIONS_FIELDS } from "../sections.form";

export default function SectionsEdit(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { id } = useParams<{ id?: string }>();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const { open } = useImageLibrary();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/sections/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: queryData, refetch } = useQuery(
    `label${id}CurrentData`,
    getData
  );

  const editData = async (values: FormInputs) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/sections/${id}`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("labelEdit", editData);

  const onSubmit = async ({
    name,
    isVisible,
    subtitle,
    bannerUrl,
    darkBackgroundColor,
    lightBackgroundColor,
    hasWidget,
    widgetBackgroundColor,
    widgetButtonIcon,
    widgetButtonLabel,
    widgetButtonTargetUrl,
    widgetImageUrl,
    widgetTitle,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync({
        name,
        isVisible: !!isVisible,
        subtitle,
        bannerUrl,
        darkBackgroundColor,
        lightBackgroundColor,
        hasWidget: !!hasWidget,
        widgetBackgroundColor,
        widgetButtonIcon,
        widgetButtonLabel,
        widgetButtonTargetUrl,
        widgetImageUrl,
        widgetTitle,
      });
      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/sections/${id}`);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (queryData) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (queryData) {
      setIsFetching(false);
    }
  }, [queryData]);

  useEffect(() => {
    setValue("name", queryData?.name);
    setValue("subtitle", queryData?.subtitle);
    setValue("bannerUrl", queryData?.bannerUrl);
    setValue("lightBackgroundColor", queryData?.lightBackgroundColor);
    setValue("darkBackgroundColor", queryData?.darkBackgroundColor);
    setValue("isVisible", queryData?.isVisible);
    setValue("hasWidget", queryData?.hasWidget);
    setValue("widgetTitle", queryData?.widgetTitle);
    setValue("widgetButtonLabel", queryData?.widgetButtonLabel);
    setValue("widgetButtonIcon", queryData?.widgetButtonIcon);
    setValue("widgetButtonTargetUrl", queryData?.widgetButtonTargetUrl);
    setValue("widgetImageUrl", queryData?.widgetImageUrl);
    setValue("widgetBackgroundColor", queryData?.widgetBackgroundColor);
  }, [queryData]);

  return (
    <article>
      <Header
        title={queryData?.name}
        labels={["Sekcje", "Edytowanie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () =>
              history.push(
                history.location.pathname.split("/").slice(0, -1).join("/")
              ),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {SECTIONS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={item.caption}
                      required={item.edit.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="48px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.ColorPicker ? (
                        <ControlledColorPicker
                          control={control}
                          name={item.id}
                        />
                      ) : item.type === FIELDS.ImagePicker ? (
                        <>
                          {watch(item.id as keyof FormInputs) && (
                            <ImagePreview
                              image={{
                                path: watch(
                                  item.id as keyof FormInputs
                                ) as string,
                              }}
                              size={ImagePreviewSize.Large}
                            />
                          )}
                          <Button
                            type="button"
                            size="compact"
                            kind={KIND.secondary}
                            onClick={() =>
                              open((image) =>
                                setValue(
                                  item.id as keyof FormInputs,
                                  `${process.env.REACT_APP_CDN_URL}/images/${image.path}`
                                )
                              )
                            }
                            $style={{ marginTop: "10px" }}
                          >
                            Wybierz z biblioteki lub wgraj
                          </Button>
                        </>
                      ) : item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={isLoading}
                          $style={{ marginTop: "10px" }}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          <span
                            className={css({
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            })}
                          >
                            {item.label}
                          </span>
                        </ControlledCheckbox>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
