import { styled, useStyletron } from "baseui";
import { ButtonProps, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { Theme } from "baseui/theme";
import { HeadingSmall, LabelXSmall } from "baseui/typography";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { ArrowLeft } from "tabler-icons-react";

import { useLoading } from "../contexts/loading-context";
import Button from "./button";
import Cell from "./cell";
import Grid from "./grid";

const Container = styled(
  "header",
  ({ $theme, $sticked }: { $theme?: Theme; $sticked?: boolean }) => ({
    backgroundColor: "white",
    paddingTop: $sticked ? $theme?.sizing.scale600 : $theme?.sizing.scale1000,
    paddingBottom: $sticked ? $theme?.sizing.scale600 : $theme?.sizing.scale600,
    position: $sticked ? "sticky" : "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: 12,
    borderBottomWidth: $sticked ? "2px" : "0px",
    borderBottomStyle: "solid",
    borderBottomColor: $theme?.colors.backgroundTertiary,
    ...($sticked && {
      marginBottom: "-72px",
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(-200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
      } as any,
    }),
  })
);

type HeaderProps = {
  title: React.ReactNode;
  recordsNum?: number;
  labels?: string[];
  buttons?: ({ label: string; isNegative?: boolean } & ButtonProps)[];
  onBack?: () => void;
};

export default function Header({
  title,
  recordsNum,
  labels,
  buttons,
  onBack,
}: HeaderProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [sticked, setSticked] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isFetching } = useLoading();

  useEffect(() => {
    containerRef?.current?.parentNode?.parentNode?.addEventListener(
      "scroll",
      (event: Event) => {
        if ((event?.target as HTMLElement)?.scrollTop > 52) setSticked(true);
        else setSticked(false);
      }
    );
  }, [containerRef]);

  return (
    <Container $sticked={sticked} ref={containerRef}>
      <Helmet>
        <title>
          {typeof title === "string"
            ? `${title} - CD-Action CM`
            : labels
            ? `${labels.join(" - ")} - CD-Action CM`
            : `CD-Action CM`}
        </title>
      </Helmet>

      <Grid>
        <Cell span={12}>
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: "38px",
            })}
          >
            {onBack && (
              <span
                className={css({
                  position: "absolute",
                  display: "flex",
                  right: "100%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#444",
                  ":hover": {
                    color: "black",
                  },
                })}
                onClick={onBack}
              >
                <ArrowLeft />
              </span>
            )}
            <div
              className={css({
                display: sticked ? "flex" : "block",
                alignItems: "center",
              })}
            >
              {isFetching ? (
                <Skeleton
                  rows={0}
                  height="32px"
                  width={
                    title && (title as string).length > 0
                      ? `calc(min(${(title as string).length * 1.4}ch, 50vw))`
                      : "160px"
                  }
                  animation
                />
              ) : (
                <HeadingSmall
                  marginTop="0px"
                  marginBottom="0px"
                  marginRight="20px"
                  $style={{
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    maxWidth: sticked ? "30vw" : "42vw",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {title}
                </HeadingSmall>
              )}
              <div
                className={css({
                  display: "flex",
                  marginTop: "3px",
                })}
              >
                {recordsNum !== undefined && recordsNum >= 0 && (
                  <LabelXSmall
                    marginTop="0px"
                    marginBottom="0px"
                    $style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <span
                      className={css({
                        textDecorationLine: "underline",
                        textDecorationStyle: "dotted",
                      })}
                    >
                      {recordsNum}
                    </span>{" "}
                    {recordsNum === 1
                      ? "rekord"
                      : recordsNum % 10 >= 2 &&
                        recordsNum % 10 <= 4 &&
                        (recordsNum % 100 < 10 || recordsNum % 100 >= 20)
                      ? "rekordy"
                      : "rekordów"}
                  </LabelXSmall>
                )}
                {labels &&
                  labels.map((label, index) => (
                    <LabelXSmall
                      marginTop="0px"
                      marginBottom="0px"
                      key={`sublabel` + index}
                      $style={{
                        textTransform: "uppercase",
                        ":before": {
                          content: "'\\2022'",
                          marginRight: "6px",
                          marginLeft: "6px",
                          color: "#aaa",
                        },
                        ":first-of-type:before": {
                          content: "unset",
                        },
                      }}
                    >
                      {label}
                    </LabelXSmall>
                  ))}
              </div>
            </div>
            <div
              className={css({
                display: "flex",
                gap: "10px",
                marginLeft: "16px",
              })}
            >
              {buttons &&
                buttons.map((item, index) => (
                  <Button
                    key={`index` + index}
                    size={sticked ? SIZE.compact : SIZE.default}
                    $style={{
                      transitionDuration: "100ms",
                      transitionProperty: "all",
                      transitionTimingFunction: "ease-in-out",
                      ...(item?.isNegative && {
                        backgroundColor: theme.colors.negative400,
                        color: "#FFF",
                        ":hover": {
                          backgroundColor: theme.colors.negative500,
                        },
                      }),
                    }}
                    {...item}
                  >
                    {item?.label}
                  </Button>
                ))}
            </div>
          </div>
        </Cell>
      </Grid>
    </Container>
  );
}
