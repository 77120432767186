import { useStyletron } from "baseui";
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from "baseui/button";
import React from "react";

type ButtonProps = {
  children?: React.ReactChild | React.ReactChild[];
} & BaseButtonProps;

export default function Button({
  children,
  size,
  kind,
  ...props
}: ButtonProps): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <BaseButton
      overrides={{
        BaseButton: {
          style: {
            position: "relative",
            borderTopLeftRadius: theme.borders.radius300,
            borderTopRightRadius: theme.borders.radius300,
            borderBottomRightRadius: theme.borders.radius300,
            borderBottomLeftRadius: theme.borders.radius300,
            ...props.$style,
          },
        },
      }}
      kind={kind}
      size={size}
      {...props}
    >
      {Array.isArray(children) ? (
        children
      ) : (
        <span className={css({ position: "relative" })}>{children}</span>
      )}
    </BaseButton>
  );
}
