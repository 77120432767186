import { styled, useStyletron } from "baseui";
import { Avatar as BaseAvatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { HeadingXSmall, LabelMedium, LabelSmall } from "baseui/typography";
import React, { MouseEvent, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Book,
  Bookmark,
  BoxMultiple,
  DeviceGamepad,
  Direction,
  Files,
  Icon,
  Logout,
  Message2,
  MessageReport,
  Movie,
  News,
  Section,
  Tag,
  User,
  Users,
  Video,
  Writing,
} from "tabler-icons-react";

import { useAuth } from "../contexts/auth-context";
import { Logo } from "./brand";

const Container = styled("nav", {
  position: "relative",
  backgroundColor: "#f9f9f9",
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  height: "100vh",
  paddingBottom: "15px",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
  ":after": {
    position: "absolute",
    content: "''",
    right: "0px",
    height: "100%",
    width: "2px",
    backgroundColor: "#eeeeee",
    zIndex: 1,
  },
});

type NavCategory = {
  label?: string;
  items: NavItem[];
};

type NavItem = {
  label: string;
  icon: Icon;
  href: string;
  permission?: string;
  exact?: boolean;
};

const USER_NAV: NavItem[] = [
  {
    label: "Moje konto",
    icon: User,
    href: "/account",
  },
  {
    label: "Wyloguj się",
    icon: Logout,
    href: "/logout",
  },
];

const NAV: NavCategory[] = [
  {
    items: [
      {
        label: "Artykuły",
        icon: News,
        href: "/articles",
      },
      {
        label: "Gry",
        icon: DeviceGamepad,
        href: "/games",
      },
      {
        label: "Kategorie",
        icon: BoxMultiple,
        href: "/article-categories",
      },
      {
        label: "Komentarze",
        icon: Message2,
        href: "/comments",
      },
      {
        label: "Zgłoszone komentarze",
        icon: MessageReport,
        href: "/reported-comments",
      },
      {
        label: "Czasopisma",
        icon: Book,
        href: "/magazines",
      },
      {
        label: "Etykiety",
        icon: Bookmark,
        href: "/labels",
      },
      {
        label: "Redaktorzy",
        icon: Writing,
        href: "/editors",
      },
      {
        label: "Tagi",
        icon: Tag,
        href: "/tags",
      },
      {
        label: "Użytkownicy",
        icon: Users,
        href: "/users",
      },
      {
        label: "Wideo",
        icon: Video,
        href: "/videos",
      },
      {
        label: "Media",
        icon: Movie,
        href: "/media",
      },
      {
        label: "Strony statyczne",
        icon: Files,
        href: "/static-pages",
      },
      {
        label: "Sekcje",
        icon: Section,
        href: "/sections",
      },
    ],
  },
];

function UserSummary(): React.ReactElement {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState<boolean | null>(null);

  const location = useLocation();
  const history = useHistory();

  const { user } = useAuth();

  return (
    <div
      className={css({
        marginTop: theme.sizing.scale500,
        backgroundColor: "white",
        height: isOpen ? `${USER_NAV.length * 48 + 73}px` : "73px",
        overflow: "hidden",
        transition: "all 200ms ease",
        flexShrink: 0,
      })}
    >
      <div
        role="button"
        className={css({
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale600,
          paddingLeft: theme.sizing.scale600,
          paddingRight: theme.sizing.scale600,
          ":hover": {
            color: theme.colors.primary,
            backgroundColor: "white",
          },
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <BaseAvatar
          name={`${user?.nickname}`}
          size="scale1000"
          overrides={{
            Root: {
              style: { flexShrink: 0, marginRight: theme.sizing.scale400 },
            },
          }}
        />
        <div
          className={css({ flexShrink: 1, flexGrow: 1, overflow: "hidden" })}
        >
          <LabelMedium>{user?.nickname}</LabelMedium>
          <LabelSmall
            $style={{
              color: "#aaa",
              marginTop: "5px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {user?.email || "cj@grovestreet.sa"}
          </LabelSmall>
        </div>
        <Direction
          size={22}
          className={css({ marginLeft: theme.sizing.scale400, flexShrink: 0 })}
        />
      </div>
      <ul
        className={css({
          marginTop: "0px",
          paddingLeft: 0,
          paddingRight: 0,
        })}
      >
        {USER_NAV &&
          USER_NAV.map((item, index) => {
            const isActive =
              item.href.substring(1).length > 0 &&
              location.pathname.includes(item.href.substring(1));

            if (isActive && isOpen === null) setIsOpen(true);

            return (
              <li
                key={`user-nav` + index}
                className={css({
                  display: "flex",
                  position: "relative",
                  height: "28px",
                  alignItems: "center",
                  marginTop: theme.sizing.scale400,
                  marginBottom: theme.sizing.scale400,
                  paddingLeft: theme.sizing.scale600,
                  paddingRight: theme.sizing.scale600,
                  fontWeight: 500,
                  color: isActive
                    ? theme.colors.primary
                    : theme.colors.contentSecondary,
                  ":hover": {
                    color: isActive
                      ? theme.colors.primary
                      : theme.colors.contentPrimary,
                    cursor: "pointer",
                  },
                })}
                onClick={() => history.push(item.href)}
              >
                <div className={css({ marginRight: "15px", height: "22px" })}>
                  <item.icon size={22} />
                </div>
                {item.label}
                {isActive && (
                  <span
                    className={css({
                      position: "absolute",
                      zIndex: 2,
                      right: "0px",
                      height: "100%",
                      width: "2px",
                      backgroundColor: theme.colors.primary,
                    })}
                  ></span>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default function SideNav(): React.ReactElement {
  const [css, theme] = useStyletron();

  const history = useHistory();
  const location = useLocation();

  // const { user } = useAuth();

  // const permissionsList = user?.permissions?.map(
  //   (permission) => permission.systemName
  // );

  const permissionsList: string | string[] = [];

  return (
    <Container>
      <StyledLink
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          history.push("/");
        }}
        href={"/"}
        $style={{ textDecoration: "none" }}
      >
        <Block
          paddingTop="scale600"
          paddingBottom="scale600"
          paddingLeft="scale600"
          paddingRight="scale600"
          marginTop="0px"
          marginBottom="0px"
          position="sticky"
          backgroundColor="#f9f9f9"
          top="0px"
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          overrides={{
            Block: {
              style: {
                zIndex: 1,
              },
            },
          }}
        >
          <Logo fill={theme.colors.primary} width={160} height={21} />
          <HeadingXSmall marginTop="scale100" marginBottom="scale0">
            Content Management
          </HeadingXSmall>
        </Block>
      </StyledLink>

      <UserSummary />

      {NAV &&
        NAV.map((item, index) => (
          <div
            key={`nav-category` + index}
            className={css({ marginTop: theme.sizing.scale600 })}
          >
            {item.label &&
              item.items.filter(
                (item) =>
                  (item.permission &&
                    permissionsList?.includes(item.permission)) ||
                  !item.permission
              ).length !== 0 && (
                <LabelSmall
                  color="contentTertiary"
                  marginBottom="scale700"
                  $style={{
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                    paddingLeft: theme.sizing.scale600,
                    paddingRight: theme.sizing.scale600,
                  }}
                >
                  {item.label}
                </LabelSmall>
              )}
            <ul
              className={css({
                marginTop: "0px",
                paddingLeft: 0,
                paddingRight: 0,
              })}
            >
              {item.items
                .filter(
                  (item) =>
                    (item.permission &&
                      permissionsList?.includes(item.permission)) ||
                    !item.permission
                )
                .map((item, subindex) => {
                  const isActive =
                    item.href.substring(1).length > 0 &&
                    ((item.exact && location.pathname === item.href) ||
                      (!item.exact && location.pathname.includes(item.href)));
                  {
                    return (
                      <StyledLink
                        key={`nav-category` + index + `nav-item` + subindex}
                        onClick={(event: MouseEvent) => {
                          event.preventDefault();
                          history.push(item.href);
                        }}
                        href={item.href}
                        $style={{ textDecoration: "none" }}
                      >
                        <li
                          className={css({
                            display: "flex",
                            position: "relative",
                            height: "28px",
                            alignItems: "center",
                            marginTop: theme.sizing.scale400,
                            marginBottom: theme.sizing.scale400,
                            paddingLeft: theme.sizing.scale600,
                            paddingRight: theme.sizing.scale600,
                            fontWeight: 500,
                            color: isActive
                              ? theme.colors.primary
                              : theme.colors.contentSecondary,
                            ":hover": {
                              color: isActive
                                ? theme.colors.primary
                                : theme.colors.contentPrimary,
                              cursor: "pointer",
                            },
                          })}
                        >
                          <div
                            className={css({
                              marginRight: "15px",
                              height: "22px",
                            })}
                          >
                            <item.icon size={22} />
                          </div>
                          {item.label}
                          {isActive && (
                            <span
                              className={css({
                                position: "absolute",
                                zIndex: 2,
                                right: "0px",
                                height: "100%",
                                width: "2px",
                                backgroundColor: theme.colors.primary,
                              })}
                            ></span>
                          )}
                        </li>
                      </StyledLink>
                    );
                  }
                })}
            </ul>
          </div>
        ))}
      <LabelSmall
        marginTop="auto"
        paddingTop={theme.sizing.scale600}
        paddingLeft={theme.sizing.scale600}
        paddingRight={theme.sizing.scale600}
        color="contentInverseTertiary"
      >
        © 2021 • {process.env.REACT_APP_VERSION}
      </LabelSmall>
    </Container>
  );
}
