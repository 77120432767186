import axios from "axios";
import { styled } from "baseui";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Spinner, StyledSpinnerNext } from "baseui/spinner";
import { Theme } from "baseui/theme";
import { LabelSmall } from "baseui/typography";
import debounce from "lodash.debounce";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { useMutation, useQuery } from "react-query";

import Button from "../components/button";
import { ImageDetails } from "../components/image-details";
import ImagePreview from "../components/image-preview";
import Input from "../components/input";
import Modal from "../components/modal";
import Pagination from "../components/pagination";
import { BASIC_AUTH } from "../constants";
import { Image, PaginatedDto } from "../utils/models";

type ImageLibraryOpenCallback = (image: Image) => void;

type ImageLibraryContextProps = {
  open: (callback: ImageLibraryOpenCallback) => void;
};

export const ImageLibraryContext = createContext<ImageLibraryContextProps>(
  {} as ImageLibraryContextProps
);

const Dropzone = styled<{ isDragActive: boolean }, "div", Theme>(
  "div",
  ({ $theme, isDragActive }) => ({
    width: "100%",
    height: "100%",
    backgroundColor: isDragActive
      ? $theme.colors.backgroundLightAccent
      : $theme.colors.backgroundPrimary,
    borderRadius: $theme.borders.radius300,
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: isDragActive ? $theme.colors.accent : "transparent",
    padding: isDragActive ? $theme.sizing.scale400 : 0,
  })
);

const LibraryRoot = styled("div", {
  display: "flex",
  overflow: "hidden",
  height: "100%",
});

const Sidebar = styled("div", ({ $theme }) => ({
  width: "400px",
  flexShrink: 0,
  marginLeft: $theme.sizing.scale1200,
  boxSizing: "border-box",
  overflow: "scroll",
}));

const LibraryImages = styled("div", () => ({
  flexGrow: 1,
}));

const LibraryImagesOptions = styled("div", ({ $theme }) => ({
  display: "flex",
  marginBottom: $theme.sizing.scale400,
}));

const PAGE_SIZE = 18;

export function ImageLibraryProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [uploadingImages, setUploadingImages] = useState<Partial<Image>[]>([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [intermediateKeyword, setIntermediateKeyword] = useState("");
  const [isWaitingForInputEnd, setIsWaitingForInputEnd] = useState(false);

  const debouncedSetKeyword = useCallback(debounce(setKeyword, 1000), []);

  const openCallback = useRef<ImageLibraryOpenCallback | null>(null);

  const uploadImageMutation = useMutation((file: File) => {
    const data = new FormData();
    data.append("file", file);

    return axios.post(`${process.env.REACT_APP_API_URL}/admin/images`, data, {
      ...BASIC_AUTH,
    });
  });

  const imagesQuery = useQuery<PaginatedDto<Image>>(
    ["images", { page, keyword }],
    async () => {
      const params = new URLSearchParams({
        sort: "-createdAt",
        offset: ((page - 1) * PAGE_SIZE).toString(),
        limit: PAGE_SIZE.toString(),
        ...(keyword && { originalName: `iLike:%${keyword}%` }),
      });

      return (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/images?${params.toString()}`,
          {
            ...BASIC_AUTH,
          }
        )
      ).data;
    }
  );

  const onDrop = useCallback(async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      const image: Partial<Image> = {
        originalName: file.name,
      };

      setUploadingImages((uploadingImages) => [...uploadingImages, image]);

      await uploadImageMutation.mutateAsync(file);

      setUploadingImages((uploadingImages) =>
        uploadingImages.filter((uploadingImage) => uploadingImage !== image)
      );
      imagesQuery.refetch();
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: pickFiles,
  } = useDropzone({
    noClick: true,
    maxSize: 5000000,
    onDrop,
  });

  const open = useCallback((callback: ImageLibraryOpenCallback) => {
    openCallback.current = callback;
    setIsOpen(true);
  }, []);

  const select = useCallback(() => {
    selectedImage &&
      openCallback.current &&
      openCallback.current(selectedImage);

    close();
  }, [selectedImage, openCallback.current]);

  const close = useCallback(() => {
    openCallback.current = null;
    setSelectedImage(null);
    setIsOpen(false);
  }, []);

  const pageCount = useMemo(
    () => Math.ceil((imagesQuery.data?.totalCount || 1) / PAGE_SIZE),
    [imagesQuery.data?.totalCount]
  );

  useEffect(() => {
    setIsWaitingForInputEnd(true);
    debouncedSetKeyword(intermediateKeyword);
  }, [intermediateKeyword]);

  useEffect(() => {
    setIsWaitingForInputEnd(false);
  }, [keyword]);

  return (
    <ImageLibraryContext.Provider
      value={{
        open,
      }}
    >
      {children}

      <Modal
        onClose={close}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: "80vw",
              height: "80vh",
              display: "flex",
              flexDirection: "column",
            },
          },
          Root: {
            style: {
              zIndex: 20,
            },
          },
        }}
      >
        <ModalHeader>Biblioteka obrazków</ModalHeader>
        <ModalBody $style={{ flex: "1 1 0", overflow: "hidden" }}>
          <LibraryRoot>
            <LibraryImages>
              <LibraryImagesOptions>
                <Input
                  size="compact"
                  placeholder="Wyszukaj"
                  value={intermediateKeyword}
                  onChange={(event) =>
                    setIntermediateKeyword(event.currentTarget.value)
                  }
                  clearable={true}
                  {...(isWaitingForInputEnd && {
                    endEnhancer: () => <Spinner color="primary" size={14} />,
                  })}
                />
                <Pagination
                  numPages={pageCount}
                  currentPage={page}
                  onPageChange={(page) => setPage(page)}
                  $style={{ marginTop: 0 }}
                />
              </LibraryImagesOptions>
              <Dropzone {...getRootProps()} isDragActive={isDragActive}>
                {imagesQuery.isLoading ? (
                  <StyledSpinnerNext />
                ) : imagesQuery.data && imagesQuery.data.results.length > 0 ? (
                  <FlexGrid
                    flexGridColumnCount={6}
                    flexGridColumnGap="scale400"
                    flexGridRowGap="scale400"
                  >
                    {uploadingImages.map((image, index) => (
                      <FlexGridItem key={`uploadingImage${index}`}>
                        <ImagePreview image={image} isLoading={true} />
                      </FlexGridItem>
                    ))}
                    {imagesQuery.data &&
                      imagesQuery.data.results.map((image) => (
                        <FlexGridItem key={image.id}>
                          <ImagePreview
                            image={image}
                            isSelected={
                              !!selectedImage && selectedImage.id === image.id
                            }
                            onClick={() =>
                              setSelectedImage((selectedImage) =>
                                selectedImage?.id !== image.id ? image : null
                              )
                            }
                          />
                        </FlexGridItem>
                      ))}
                  </FlexGrid>
                ) : (
                  <LabelSmall>Brak obrazków</LabelSmall>
                )}
                <input {...getInputProps()} />
              </Dropzone>
            </LibraryImages>

            <Sidebar>
              {selectedImage && (
                <ImageDetails
                  onChange={imagesQuery.refetch}
                  image={selectedImage}
                />
              )}
            </Sidebar>
          </LibraryRoot>
        </ModalBody>
        <ModalFooter>
          <Button
            kind="tertiary"
            onClick={close}
            $style={{ marginRight: "8px" }}
          >
            Anuluj
          </Button>
          <Button
            kind="secondary"
            onClick={pickFiles}
            $style={{ marginRight: "8px" }}
          >
            Wgraj plik(i)
          </Button>
          <Button onClick={select} disabled={!selectedImage}>
            Wybierz
          </Button>
        </ModalFooter>
      </Modal>
    </ImageLibraryContext.Provider>
  );
}

export const useImageLibrary = (): ImageLibraryContextProps =>
  useContext(ImageLibraryContext);
