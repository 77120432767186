import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ButtonProps } from "baseui/button";
import { Pagination as BasePagination } from "baseui/pagination";
import { SelectProps } from "baseui/select";
import { Skeleton } from "baseui/skeleton";
import React from "react";
import { useHistory } from "react-router";
import { StyleObject } from "styletron-react";
import { ChevronDown, ChevronLeft, ChevronRight, X } from "tabler-icons-react";

import { useLoading } from "../contexts/loading-context";
import Button from "./button";
import Select from "./select";

type PaginationProps = {
  $style?: StyleObject;
  numPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
};

export default function Pagination({
  numPages,
  currentPage,
  onPageChange,
  $style,
}: PaginationProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { isFetching } = useLoading();

  return (
    <div
      className={css({
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
      })}
    >
      <BasePagination
        overrides={{
          Root: {
            style: {
              marginTop: theme.sizing.scale1000,
              ...$style,
            },
          },
          Select: {
            component: ({ overrides, ...props }: SelectProps) => (
              <Select {...props} />
            ),
          },
          PrevButton: {
            component: ({ onClick, disabled }: ButtonProps) => (
              <Button
                size="compact"
                kind="secondary"
                startEnhancer={<ChevronLeft size={16} />}
                {...{
                  onClick,
                  disabled,
                }}
              >
                Poprzednia
              </Button>
            ),
          },
          NextButton: {
            component: ({ onClick, disabled }: ButtonProps) => (
              <Button
                size="compact"
                kind="secondary"
                endEnhancer={<ChevronRight size={16} />}
                {...{
                  onClick,
                  disabled,
                }}
              >
                Następna
              </Button>
            ),
          },
          MaxLabel: {
            props: {
              className: css({ fontSize: "15px" }),
            },
            ...(isFetching && {
              component: () => (
                <Block marginRight="scale600" marginLeft="scale300">
                  <Skeleton width="24px" height="16px" animation />
                </Block>
              ),
            }),
          },
        }}
        labels={{
          preposition: "z",
        }}
        size="compact"
        numPages={numPages}
        currentPage={currentPage}
        onPageChange={({ nextPage }) => {
          onPageChange(nextPage);
          history?.push({ search: `?page=${nextPage}` });
        }}
      />
    </div>
  );
}
