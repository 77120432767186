import { BaseProvider } from "baseui";
import { SnackbarProvider } from "baseui/snackbar";
import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";

import theme from "../../baseweb";
import AutoScroll from "../../components/auto-scroll";
import Layout from "../../components/layout";
import Route from "../../components/route";
import { AuthProvider } from "../../contexts/auth-context";
import { ImageLibraryProvider } from "../../contexts/image-library-context";
import { LoadingProvider } from "../../contexts/loading-context";
import { SupportProvider } from "../../contexts/support-context";
import ROUTES from "../../routes";

const engine = new Styletron({
  prefix: "cda__",
});

export default function App(): React.ReactElement {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <LoadingProvider>
          <AuthProvider>
            <SupportProvider>
              <SnackbarProvider
                placement="bottom"
                overrides={{
                  Root: {
                    style: {
                      boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.16)",
                    },
                  },
                  PlacementContainer: {
                    style: {
                      transform: "unset",
                      animationIterationCount: "1",
                      animationDelay: "0ms",
                      animationDuration: "200ms",
                      animationFillMode: "both",
                      animationName: {
                        from: {
                          transform: "translateY(200px)",
                        },
                        to: {
                          transform: "translateY(0px)",
                        },
                      } as any,
                      transitionDuration: theme.animation.timing100,
                      zIndex: 20,
                    },
                  },
                }}
              >
                <ImageLibraryProvider>
                  <Router>
                    <AutoScroll />
                    <Switch>
                      {Object.values(ROUTES.auth).map((route, index) => (
                        <Route
                          key={`auth-route` + index}
                          exact={route.exact}
                          path={route.path}
                          component={route.component}
                        />
                      ))}

                      <Layout>
                        <Switch>
                          {Object.values(ROUTES.private.basic).map(
                            (route, index) => (
                              <Route
                                $private
                                key={`private-route` + index}
                                exact={route.exact}
                                path={route.path}
                                component={route.component}
                              />
                            )
                          )}

                          {Object.values(ROUTES.private.models).map((route) => {
                            return [
                              route.edit && (
                                <Route
                                  $private
                                  key={route.name + `-route-edit`}
                                  path={route.path + route?.edit?.path}
                                  component={route?.edit?.component}
                                  exact
                                />
                              ),
                              route.create && (
                                <Route
                                  $private
                                  key={route.name + `-route-create`}
                                  path={route.path + route?.create?.path}
                                  component={route?.create?.component}
                                  exact
                                />
                              ),
                              route.show && (
                                <Route
                                  $private
                                  key={route.name + `-route-show`}
                                  path={route.path + route?.show?.path}
                                  component={route?.show?.component}
                                  exact
                                />
                              ),
                              route.index && (
                                <Route
                                  $private
                                  key={route.name + `-route-index`}
                                  path={route.path + route?.index?.path}
                                  component={route?.index?.component}
                                  exact
                                />
                              ),
                            ];
                          })}
                        </Switch>
                      </Layout>
                    </Switch>
                  </Router>
                </ImageLibraryProvider>
              </SnackbarProvider>
            </SupportProvider>
          </AuthProvider>
        </LoadingProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}
