import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, Pencil, Trash } from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { TAGS_FIELDS } from "../tags.form";

export default function TagsShow(): React.ReactElement {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getTagData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/tags/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: tagData, isError, refetch } = useQuery(
    `tagShow${id}`,
    getTagData
  );
  const taggableId = tagData?.taggableId;
  const taggableType = tagData?.taggableType;

  const getTaggableData = async () => {
    const { data } = await axios.get(
      taggableType === "Article"
        ? `${process.env.REACT_APP_API_URL}/admin/articles/${taggableId}`
        : `${process.env.REACT_APP_API_URL}/admin/games/${taggableId}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: taggableQueryData, refetch: taggableRefetch } = useQuery(
    taggableType === "Article"
      ? `articleTitleShow${taggableId}`
      : `gameOriginalTitleShow${taggableId}`,
    getTaggableData,
    {
      enabled: !!taggableId,
    }
  );

  const taggableData = taggableId ? taggableQueryData : null;

  const { mutateAsync } = useMutation("tagDelete", async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/tags/${id}`,
      BASIC_AUTH
    );
  });

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync();

      enqueue({
        message: "Usunięto pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push("/tags");
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (tagData) refetch();
    if (taggableData) taggableRefetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (tagData) {
      taggableRefetch();
      setIsFetching(false);
    }
  }, [tagData]);

  return (
    <article>
      <Header
        title={tagData?.name}
        labels={["Tagi", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            onClick: () => history.push(`/tags/${id}/edit`),
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {TAGS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={
                          item.id === "taggableId" &&
                          tagData?.taggableType === "Article"
                            ? "model:articles"
                            : item.id === "taggableId" &&
                              tagData?.taggableType === "Game"
                            ? "model:games"
                            : item.dataType
                        }
                        data={item.id === "taggableId" && tagData?.taggableId}
                      >
                        {item.show.accessor
                          ? tagData &&
                            tagData[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : item.id === "taggableId"
                          ? taggableData
                            ? taggableType === "Article"
                              ? taggableData["title"]
                              : taggableData["originalTitle"]
                            : undefined
                          : tagData && tagData[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Usuń tag",
                  icon: Trash,
                  color: theme.colors.primary,
                  onClick: () => setIsConfirmDialogOpen(true),
                },
              ]}
            />
          </Cell>

          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie tagu ${tagData?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
