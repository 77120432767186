import { useStyletron } from "baseui";
import { Table as BaseTable } from "baseui/table-semantic";
import React from "react";
import { Cell, Column, Row, useExpanded, useTable } from "react-table";
import { StyleObject } from "styletron-react";
import { ListSearch } from "tabler-icons-react";

type Props<T> = {
  expandedNumber?: number;
  data: T[];
  columns: Column[];
  noOffset?: boolean;
  isHorizontalScroll?: boolean;
  $style?: StyleObject;
  $rows?: (rows: Row[], prepareRow: (row: Row) => void) => React.ReactNode[][];
};

export default function Table<T>({
  data,
  columns,
  noOffset,
  isHorizontalScroll,
  $style,
  $rows,
  expandedNumber,
}: Props<T>): React.ReactElement {
  const [css, theme] = useStyletron();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: data ? (data as any[]) : [],
      initialState: {
        expanded: Array.from(
          new Array(expandedNumber === undefined ? 100 : expandedNumber).keys()
        ).reduce((a, _b, index) => ({ ...a, [index]: true }), {}),
      },
      autoResetExpanded: false,
    },
    useExpanded
  );

  const tableColumns = headerGroups
    .map((headerGroup) => {
      return headerGroup.headers.map((column) => column.render("Header"));
    })
    .flat();

  const tableRows = rows.map((row: any, i) => {
    prepareRow(row);
    return row.cells.map((cell: Cell) => cell.render("Cell"));
  });

  return (
    <BaseTable
      data={($rows && $rows(rows, prepareRow)) || tableRows}
      columns={tableColumns}
      emptyMessage={() => (
        <div
          key="error"
          className={css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#999999",
          })}
        >
          <ListSearch
            color="#999999"
            size={18}
            className={css({ marginRight: "5px" })}
          />
          Brak rekordów
        </div>
      )}
      overrides={{
        Root: {
          style: {
            position: "static",
            overflowY: "unset",
            overflowX: isHorizontalScroll ? "auto" : "unset",
            ...$style,
          },
        },
        Table: {
          style: {
            position: "relative",
            ...getTableProps()?.style,
          },
        },
        TableBody: {
          style: { ...getTableBodyProps()?.style },
        },
        TableHeadCell: {
          style: {
            position: "sticky",
            top: isHorizontalScroll ? 0 : noOffset ? "72px" : "116px",
            zIndex: 12,
            fontSize: "15px",
            paddingLeft: "10px",
            paddingRight: "10px",
          },
        },
        TableBodyCell: {
          style: {
            verticalAlign: "middle",
            height: "44px",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRightStyle: "solid",
            borderRightWidth: "0.5px",
            borderRightColor: theme.colors.inputBorder,
            ":last-of-type": {
              borderRightWidth: "0px",
            },
            ":first-of-type": {
              wordBreak: "break-all",
            },
          },
        },
        TableBodyRow: {
          style: {
            borderBottomStyle: "solid",
            borderBottomWidth: "0.5px",
            borderBottomColor: theme.colors.inputBorder,
            ":last-of-type": {
              borderBottomWidth: "0px",
            },
          },
        },
      }}
    />
  );
}
