export enum FIELDS {
  ArticlesSelect = "articles_select",
  ArticlesAttributesSelect = "articles_attributes_select",
  ArticlesCategoriesSelect = "articles_categories_select",
  ArticlesStatusSelect = "articles_status",
  BrandSelect = "brand_select",
  Button = "button",
  Checkbox = "checkbox",
  DatePicker = "date_picker",
  DateTimePicker = "date_time_picker",
  FilesList = "files_list",
  FilesPicker = "files_picker",
  GamesSelect = "games_select",
  GamingPlatformsSelect = "gaming_platforms_select",
  Input = "input",
  NumberInput = "number_input",
  PermissionsTable = "permissions_table",
  Select = "select",
  SexSelect = "sex_select",
  TaggableTypesSelect = "taggable_types_select",
  TagsSelect = "tags_select",
  TextArea = "text_area",
  Editor = "editor",
  ImagePicker = "image_picker",
  VideoTypesSelect = "video_types_select",
  EditorsSelect = "editors_select",
  ColorPicker = "color_picker",
  LabelsSelect = "labels_select",
  TagsKindSelect = "tags_kind_select",
  GeneralTagsSelect = "general_tags_select",
  MagazinesStatusSelect = "magazines_status",
  TagsSelect = "general_tags_select",
  PegiAgeRatingSelect = "pegi_age_rating_select",
  PegiContentDescriptorsMultiSelect = "pegi_content_descriptors_multi_select",
  UserRolesSelect = "user_roles_select",
  SectionsSelect = "sections_select",
}

export type Field = {
  id: string;
  label: string;
  caption?: string;
  placeholder?: string;
  info?: string;
  span?: number;
  skip?: number;
  dataType?: string;
  typeName?: string;
  type: FIELDS[keyof FIELDS];
  show: { accessor?: string | string[]; visible: boolean };
  create: { visible: boolean; required?: boolean };
  edit: { visible: boolean; required?: boolean };
  mask?: string;
  role?: "Writer" | "Editor" | "Administrator";
};

export type FieldsGroup = {
  id: string;
  label: string;
  fields: Field[];
  accessor?: string;
};

export type NumericOption = {
  id: number;
  label?: string;
};

export type LiteralOption = {
  id: string;
  label?: string;
};
