import axios from "axios";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, Pencil, Trash } from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { ArticleCategory } from "../article-categories";
import { ARTICLE_CATEGORIES_FIELDS } from "../article-categories.form";

export default function ArticleCategoriesShow(): React.ReactElement {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/article-categories/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data, isError, refetch } = useQuery(
    `articleCategoryShow${id}`,
    getData
  );

  const { mutateAsync } = useMutation("articleCategoryDelete", async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/article-categories/${id}`,
      BASIC_AUTH
    );
  });

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync();

      enqueue({
        message: "Usunięto pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push("/article-categories");
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  const childrenColumns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        id: "name",
        Cell: ({ row }: { row: any }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/article-categories/${row?.original?.id}`);
            }}
            href={`/article-categories/${row?.original?.id}`}
          >
            {row?.original?.name}
          </StyledLink>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/article-categories/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/article-categories/${row?.original?.id}/edit`);
              }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.primary}
              size={SIZE.mini}
              $as="a"
              href={`/article-categories/${row?.original?.id}`}
              $style={{ marginLeft: "10px" }}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/article-categories/${row?.original?.id}`);
              }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    [data?.children]
  );

  return (
    <article>
      <Header
        title={data?.name}
        labels={["Kategorie", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            onClick: () => history.push(`/article-categories/${id}/edit`),
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {ARTICLE_CATEGORIES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={item.id === "parent" && data?.parent?.id}
                      >
                        {item.show.accessor
                          ? data &&
                            data[item.show.accessor[0]]?.[item.show.accessor[1]]
                          : data && data[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Kategorie podrzędne
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              <Table<ArticleCategory>
                columns={childrenColumns}
                data={data?.children}
                noOffset
              />
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Usuń kategorię",
                  icon: Trash,
                  color: theme.colors.primary,
                  onClick: () => setIsConfirmDialogOpen(true),
                },
              ]}
            />
          </Cell>

          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie kategorii ${data?.name}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
