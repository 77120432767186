import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertCircle,
  Check,
  Eye,
  Minus,
  Pencil,
  Trash,
} from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import Editor from "../../../components/editor";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { MAGAZINES_FIELDS } from "../magazines.form";

export default function MagazinesShow(): React.ReactElement {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/magazines/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data, isError, refetch } = useQuery(`magazineShow${id}`, getData);

  const { mutateAsync } = useMutation("magazineDelete", async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/magazines/${id}`,
      BASIC_AUTH
    );
  });

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync();

      enqueue({
        message: "Usunięto pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push("/magazines");
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title={data?.issueNumber}
        labels={["Czasopisma", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            $as: "a",
            href: `/magazines/${id}/edit`,
            startEnhancer: <Pencil size={18} />,
            onClick: (event: MouseEvent) => {
              event.preventDefault();
              history.push(`/magazines/${id}/edit`);
            },
          },
          {
            label: "Podgląd",
            kind: KIND.secondary,
            $as: "a",
            target: "_blank",
            href: `${process.env.REACT_APP_URL}api/preview?secret=${data?.previewToken}&slug=/czasopismo/${data?.id}`,
            startEnhancer: <Eye size={18} />,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {MAGAZINES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.id === "source" ? (
                      <StyledLink href={data?.source} target="_blank">
                        <FormattedValue dataType={item.dataType}>
                          {item.show.accessor
                            ? data &&
                              data[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                            : data && data[item.id]}
                        </FormattedValue>
                      </StyledLink>
                    ) : item.type === FIELDS.Editor ? (
                      <Editor placeholder={false} readOnly data={data?.body} />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        date={data?.publishedAt}
                      >
                        {item.show.accessor
                          ? data &&
                            data[item.show.accessor[0]]?.[item.show.accessor[1]]
                          : item.id === "isPublished"
                          ? data && !data[item.id]
                          : data && data[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Grafiki
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <FormControl label={"Okładka"} disabled={true}>
              {data?.issueCoverUrl ? (
                <img
                  src={data?.issueCoverUrl}
                  alt="banner"
                  className={css({ maxHeight: "400px" })}
                />
              ) : (
                <div
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    color: "#999999",
                  })}
                >
                  <>
                    <Minus
                      color="#999999"
                      size={18}
                      className={css({ marginRight: "5px" })}
                    />
                    Brak
                  </>
                </div>
              )}
            </FormControl>
          </Cell>
          <Cell span={12}>
            <FormControl label={"Art"} disabled={true}>
              {data?.issueCoverArtUrl ? (
                <img
                  src={data?.issueCoverArtUrl}
                  alt="banner"
                  className={css({ maxHeight: "400px" })}
                />
              ) : (
                <div
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    color: "#999999",
                  })}
                >
                  <>
                    <Minus
                      color="#999999"
                      size={18}
                      className={css({ marginRight: "5px" })}
                    />
                    Brak
                  </>
                </div>
              )}
            </FormControl>
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Baner
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {data?.bannerUrl ? (
              <img
                src={data?.bannerUrl}
                alt="banner"
                className={css({ maxHeight: "400px" })}
              />
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  color: "#999999",
                })}
              >
                <>
                  <Minus
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Brak
                </>
              </div>
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Usuń czasopismo",
                  icon: Trash,
                  color: theme.colors.primary,
                  onClick: () => setIsConfirmDialogOpen(true),
                },
              ]}
            />
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie czasopisma ${data?.issueNumber}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
