import { useSnackbar } from "baseui/snackbar";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Check } from "tabler-icons-react";

import { useAuth } from "../../../contexts/auth-context";
import { SnackbarSuccess } from "../../../utils/snackbarTypes";

export default function Logout(): React.ReactElement {
  const { enqueue } = useSnackbar();
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    enqueue({
      message: "Wylogowano pomyślnie",
      overrides: SnackbarSuccess,
      startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
    });
  }, []);

  return <Redirect to={"/login"} />;
}
