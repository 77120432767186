/* eslint-disable no-undef */
import "./deps/tooltip.css";

import {
  API,
  InlineTool,
  InlineToolConstructorOptions,
} from "@editorjs/editorjs";

/**
 * Tooltip for the Editor.js.
 * Add a tooltip inline in the Toolbar.
 * Requires no server-side uploader.
 *
 * @typedef {object} TooltipData
 * @description Tool's input and output data format
 * @property {string} tooltip — tooltip text
 */

const tooltipIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="14"
viewBox="0 0 21 21">
<path
  d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H16L12,22L8,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M4,4V16H8.83L12,19.17L15.17,16H20V4H4M6,7H18V9H6V7M6,11H16V13H6V11Z" />
</svg>`;

export default class Tooltip implements InlineTool {
  static isInline = true;
  _state: any;
  api: any;
  button: any;
  spanTooltip: any;
  tooltipLocation: any;
  highlightColor: any;
  underline: any;
  backgroundColor: any;
  textColor: any;
  tag: string;
  CSS: { input: string; tooltip: string; span: string; underline: string };
  tooltipInput: any;

  get state(): any {
    return this._state;
  }

  set state(state) {
    this._state = state;
    const { button } = this;
    const { inlineToolButtonActive } = this.api.styles;
    button.classList.toggle(inlineToolButtonActive, state);
  }

  /**
   * @param {object} api Editor.js api
   */
  constructor({ api, config = {} }: any) {
    this.api = api;
    this.button = null;
    this._state = false;
    this.spanTooltip = null;

    const { location = "bottom" } = config;
    this.tooltipLocation = location;
    this.highlightColor = config.highlightColor;
    this.underline = config.underline ? config.underline : false;
    this.backgroundColor = config.backgroundColor;
    this.textColor = config.textColor;

    this.tag = "SPAN";

    this.CSS = {
      input: "tooltip-tool__input",
      tooltip: "cdx-tooltip",
      span: "tooltip-tool__span",
      underline: "tooltip-tool__underline",
    };
    this.tooltipsObserver();
    if (this.backgroundColor || this.textColor) this.customTooltip();
  }

  /**
   * Customize the tooltips style with data passed in the config object
   * implementing a Mutation Observer in the dynamic tooltip tag.
   */

  customTooltip(): any {
    const tooltipTag = document.querySelector(".ct") as any;
    const tooltipContent = document.querySelector(".ct__content") as any;
    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type === "childList") {
          const content = tooltipContent.textContent;
        }
      });
    });

    observer.observe(tooltipContent, { childList: true });
  }

  /**
   * Search the editorjs-tooltip style sheet
   * @returns the editorjs-tooltip style sheet
   */
  tooltipSheet() {
    const sheetsList = document.styleSheets;
    const sheets = Object.values(sheetsList);
    return sheets.filter(
      (sheet: any) => sheet.ownerNode.id === "editorjs-tooltip"
    );
  }

  /**
   * Search for the cssRules of the selector passed
   * @param {string} selector is the CSS selector required
   * @returns the cssRules from the selector
   */
  tooltipCssRule(selector: string) {
    // const tooltipSheet = this.tooltipSheet();
    // const cssRules =
    //   tooltipSheet[0]?.cssRules &&
    //   (Object.values(tooltipSheet[0]?.cssRules) as any);
    // return (
    //   tooltipSheet[0]?.cssRules &&
    //   cssRules.filter((cssRule: any) => cssRule.selectorText === selector)
    // );
  }

  /**
   * Observe if some tooltip span is inserted and create the respective tooltip
   */
  tooltipsObserver() {
    const spanTooltips = document.querySelectorAll(".cdx-tooltip");

    spanTooltips.forEach((span: any) =>
      this.createTooltip(span.dataset.tooltip, span)
    );
  }

  /**
   * Create the Tooltips with the Tooltip API
   * @param {String} tooltipValue is the tooltip text
   * @param {HTMLElement} spanTooltip is the selected text where the tooltip is created
   */
  createTooltip(
    tooltipValue: string,
    spanTooltip: HTMLElement = this.spanTooltip
  ) {
    if (this.spanTooltip) {
      this.spanTooltip.dataset.tooltip = tooltipValue;
      this.setBackgroundColor(this.spanTooltip);
      this.setUnderlineDecoration(this.spanTooltip);
    } else {
      this.setBackgroundColor(spanTooltip);
      this.setUnderlineDecoration(spanTooltip);
    }
    const { tooltipLocation } = this;
    this.api.tooltip.onHover(spanTooltip, tooltipValue, {
      placement: tooltipLocation,
    });
  }

  /**
   * Set background-color and span custom class
   * @param {HTMLElement} spanTooltip is the tooltip element
   */

  setBackgroundColor(spanTooltip: HTMLElement) {
    const tooltip = spanTooltip as any;
    if (tooltip.childElementCount > 0) {
      tooltip.firstChild.classList.add(this.CSS.span);
      tooltip.firstChild.style.background = this.highlightColor;
    } else {
      tooltip.classList.add(this.CSS.span);
      tooltip.style.background = this.highlightColor;
    }
  }
  /**
   * Set underline class
   * @param {HTMLElement} spanTooltip is the tooltip element
   */

  setUnderlineDecoration(spanTooltip: HTMLElement) {
    const tooltip = spanTooltip as any;
    if (this.underline) {
      tooltip.childElementCount > 0
        ? tooltip.firstChild.classList.add(this.CSS.underline)
        : tooltip.classList.add(this.CSS.underline);
    }
  }

  /**
   * render the button in the inline toolbar
   * @returns the button element created to the inline toolbar
   */

  render() {
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.innerHTML = tooltipIcon;
    const { inlineToolButton } = this.api.styles;
    this.button.classList.add(inlineToolButton);

    return this.button;
  }
  /**
   * The method is called when the button rendered in render() is clicked
   * create a span to enclose the selected text.
   * @param {object} range is an object with info about the selected text
   * @returns
   */

  surround(range: any) {
    if (this.state) {
      this.unwrap(range);
      return;
    }

    this.wrap(range);
  }

  /**
   * wrap creates the span element for the selected text
   * @param {object} range is an object with info about the selected text
   */

  wrap(range: any) {
    const selectedText = range.extractContents();
    this.spanTooltip = document.createElement(this.tag);

    this.spanTooltip.classList.add(this.CSS.tooltip);
    this.spanTooltip.appendChild(selectedText);
    range.insertNode(this.spanTooltip);

    this.api.selection.expandToTag(this.spanTooltip);
  }

  /**
   * unwrap delete the span if the tool is disabled
   * @param {object} range is an object with info about the selected text
   */
  unwrap(range: any) {
    this.spanTooltip = this.api.selection.findParentTag(
      this.tag,
      this.CSS.tooltip
    );
    const text = range.extractContents();

    this.spanTooltip?.remove();

    range.insertNode(text);
  }

  /**
   * Checkstate is called when the user select any text
   * check the state of the tool in the selected text
   */
  checkState(): any {
    this.spanTooltip = this.api.selection.findParentTag(this.tag);
    this.state = !!this.spanTooltip;

    if (this.state) this.showActions();
    else this.hideActions();
  }

  /**
   * render actions in the Toolbar
   * @returns the input in the Toolbar to insert the tooltip
   */
  renderActions(): any {
    this.spanTooltip = this.api.selection.findParentTag(this.tag);
    this.tooltipInput = document.createElement("input");
    this.tooltipInput.placeholder = "Wprowadź tekst przypisu";
    this.tooltipInput.classList.add(this.api.styles.input);
    this.tooltipInput.classList.add(this.CSS.input);
    if (this.spanTooltip) {
      const tooltipStored = this.spanTooltip.dataset.tooltip || "";
      this.tooltipInput.value = tooltipStored;
    }
    this.tooltipInput.hidden = true;

    return this.tooltipInput;
  }

  /**
   * Show the input and create the tooltip when the user presses Enter
   */
  showActions(): any {
    this.tooltipInput.hidden = false;
    this.api.listeners.on(
      this.tooltipInput,
      "keydown",
      (e: any) => {
        if (e.key === "Enter") {
          const tooltipValue = this.tooltipInput.value;
          this.createTooltip(tooltipValue);
          this.closeToolbar();
        }
      },
      false
    );
  }

  /**
   * Hide the input if the user do not have tooltip in the selected text
   */
  hideActions(): any {
    this.tooltipInput.hidden = true;
  }

  /**
   * close the toolbar when the user presses Enter
   */
  closeToolbar(): any {
    const toolbar = document.querySelector(".ce-inline-toolbar--showed") as any;
    toolbar?.classList?.remove("ce-inline-toolbar--showed");
  }

  /**
   * satanize the data output
   */
  static get sanitize(): any {
    return {
      span: (e: any) => {
        e.classList.remove("tooltip-tool__span", "tooltip-tool__underline");
        return { class: true, "data-tooltip": true };
      },
    };
  }
}
