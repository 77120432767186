import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  name: string;
  subtitle?: string;
  bannerUrl?: string;
  lightBackgroundColor?: string;
  darkBackgroundColor?: string;
  isVisible?: boolean;
  hasWidget?: boolean;
  widgetTitle?: string;
  widgetButtonLabel?: string;
  widgetButtonIcon?: string;
  widgetButtonTargetUrl?: string;
  widgetImageUrl?: string;
  widgetBackgroundColor?: string;
};

export const SECTIONS_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "subtitle",
        label: "Podtytuł",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isVisible",
        label: "Widoczność",
        type: FIELDS.Checkbox,
        dataType: "inverted-visibility-boolean",
        span: 4,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "date",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "date",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "color",
    label: "Kolory tła",
    fields: [
      {
        id: "lightBackgroundColor",
        label: "Light mode",
        type: FIELDS.ColorPicker,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "darkBackgroundColor",
        label: "Dark mode",
        type: FIELDS.ColorPicker,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "bannerUrl",
    label: "Grafika",
    fields: [
      {
        id: "bannerUrl",
        label: "URL",
        type: FIELDS.ImagePicker,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "widget",
    label: "Widżet",
    fields: [
      {
        id: "widgetTitle",
        label: "Tytuł",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "hasWidget",
        label: "Widoczność",
        type: FIELDS.Checkbox,
        dataType: "inverted-visibility-boolean",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "widgetButtonLabel",
        label: "Tekst na przycisku",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "widgetButtonIcon",
        label: "Ikonka na przycisku",
        type: FIELDS.ImagePicker,
        span: 6,
        caption: "Ikonka powinna być w proporcjach 1:1.",
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "widgetButtonTargetUrl",
        label: "Link na przycisku",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "widgetImageUrl",
        label: "Grafika",
        type: FIELDS.ImagePicker,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "widgetBackgroundColor",
        label: "Kolor tła",
        type: FIELDS.ColorPicker,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
