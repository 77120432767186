import { styled, useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { Theme } from "baseui/theme";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Icon } from "tabler-icons-react";

import { useLoading } from "../contexts/loading-context";
import Cell from "./cell";
import Grid from "./grid";

const StickedActionsList = styled(
  "summary",
  ({ $theme, $sticked }: { $theme?: Theme; $sticked?: boolean }) => ({
    backgroundColor: "white",
    paddingTop: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    paddingBottom: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    position: "fixed",
    left: "max(min(300px, 30%), 250px)",
    right: "0px",
    bottom: "0px",
    zIndex: 10,
    borderTopWidth: $sticked ? "2px" : "0px",
    borderTopStyle: "solid",
    borderTopColor: $theme?.colors.backgroundTertiary,
    transform: "translateY(200px)",
    ...($sticked && {
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
      } as any,
    }),
  })
);

type ActionsListProps = {
  actions?: {
    label: string;
    icon: Icon;
    onClick?: () => void;
    color?: string;
    permission?: boolean;
  }[];
};

export default function ActionsList({
  actions,
}: ActionsListProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [sticked, setSticked] = useState(false);

  const { isFetching } = useLoading();

  const { pathname } = useLocation();

  const listRef = useRef<HTMLUListElement>(null);

  function checkPosition() {
    if (
      (listRef?.current?.getBoundingClientRect().top || 0) -
        window.innerHeight +
        20 >
      0
    )
      setSticked(true);
    else setSticked(false);
  }

  useEffect(() => {
    checkPosition();
  }, [isFetching]);

  useEffect(() => {
    checkPosition();
  }, [pathname]);

  useEffect(() => {
    document
      ?.querySelector("main > section")
      ?.addEventListener("scroll", checkPosition);
    window?.addEventListener("resize", checkPosition);
  }, []);

  return (
    <>
      <ul
        ref={listRef}
        className={css({
          marginTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
        })}
      >
        {actions &&
          actions.map((item, index) => {
            return (
              (item.permission || item.permission === undefined) && (
                <li
                  key={`action-${index}`}
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    position: "relative",
                    marginTop: theme.sizing.scale400,
                    marginBottom: theme.sizing.scale600,
                    paddingRight: theme.sizing.scale600,
                    color: item?.color,
                    fontWeight: 400,
                    ":hover": {
                      color: item?.color || theme.colors.primary,
                      cursor: "pointer",
                      ...(item?.color && { filter: "brightness(0.5)" }),
                    },
                  })}
                  onClick={item.onClick}
                >
                  {isFetching ? (
                    <Skeleton height="20px" width="200px" animation />
                  ) : (
                    <>
                      <div
                        className={css({ marginRight: "10px", height: "20px" })}
                      >
                        <item.icon size={20} />
                      </div>
                      {item.label}
                    </>
                  )}
                </li>
              )
            );
          })}
      </ul>
      <StickedActionsList $sticked={sticked}>
        <Grid>
          <Cell span={12}>
            <ul
              className={css({
                marginTop: "0px",
                marginBottom: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                display: "flex",
                flexWrap: "wrap",
              })}
            >
              {actions &&
                actions.map((item, index) => {
                  return (
                    (item.permission || item.permission === undefined) && (
                      <li
                        key={`sticked-action-${index}`}
                        className={css({
                          display: "flex",
                          width: "fit-content",
                          position: "relative",
                          alignItems: "center",
                          marginTop: theme.sizing.scale200,
                          marginBottom: theme.sizing.scale200,
                          paddingRight: theme.sizing.scale800,
                          color: item?.color,
                          fontWeight: 400,
                          ":hover": {
                            color: item?.color || theme.colors.primary,
                            cursor: "pointer",
                            ...(item?.color && { filter: "brightness(0.5)" }),
                          },
                        })}
                        onClick={item.onClick}
                      >
                        {isFetching ? (
                          <Skeleton height="20px" width="200px" animation />
                        ) : (
                          <>
                            <div
                              className={css({
                                marginRight: "10px",
                                height: "20px",
                              })}
                            >
                              <item.icon size={20} />
                            </div>
                            {item.label}
                          </>
                        )}
                      </li>
                    )
                  );
                })}
            </ul>
          </Cell>
        </Grid>
      </StickedActionsList>
    </>
  );
}
