import { FIELDS, FieldsGroup } from "../../fields.d";

export const REPORTED_COMMENTS_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "reason",
        label: "Treść zgłoszenia",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "status",
        label: "Status",
        type: FIELDS.TextArea,
        dataType: "reportedCommentStatus",
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "userId",
        label: "Autor",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 4,
        show: { accessor: ["user", "nickname"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "commentId",
        label: "Dotyczy komentarza",
        type: FIELDS.Input,
        dataType: "model:comments",
        span: 8,
        show: { accessor: ["comment", "content"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "ipAddress",
        label: "IP klienta",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdAt",
        label: "Data dodania",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
