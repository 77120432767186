import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { HeadingMedium } from "baseui/typography";
import React, { MouseEvent, useState } from "react";
import { Mail } from "tabler-icons-react";

import { Logo } from "../../../components/brand";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Grid from "../../../components/grid";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import { SUPPORT_ADMIN_MAIL, SUPPORT_ADMIN_NAME } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { useSupport } from "../../../contexts/support-context";

export default function Login(): React.ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [css, theme] = useStyletron();
  const { isLoading } = useLoading();
  const { openSupportModal } = useSupport();

  return (
    <main>
      <Loading />
      <Grid
        isFlex
        $style={{ height: "calc(100vh - 5px)" }}
        flexGridColumnGap={0}
        flexGridColumnCount={1}
      >
        <Cell isFlex>
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingLeft: "50px",
              paddingRight: "50px",
            })}
          >
            <div
              className={css({
                width: "100%",
                maxWidth: "400px",
              })}
            >
              <Logo fill={theme.colors.primary} width={230} height={30} />
              <HeadingMedium marginTop="scale100" marginBottom="scale1000">
                Content Management
              </HeadingMedium>
              <Button
                $as="a"
                href={`${process.env.REACT_APP_API_URL}/admin/auth/forum`}
                size={SIZE.large}
                kind={KIND.primary}
                isSelected={isLoading}
                isLoading={isLoading}
                $style={{ width: "100%", marginTop: "15px" }}
              >
                Zaloguj się przez forum
              </Button>
              <StyledLink
                href=""
                onClick={(event: MouseEvent) => {
                  event.preventDefault();
                  openSupportModal();
                }}
                $style={{ marginTop: "15px", display: "block" }}
              >
                Przypomnienie hasła
              </StyledLink>
            </div>
          </div>
        </Cell>
      </Grid>

      <Modal onClose={() => setIsModalOpen(false)} isOpen={isModalOpen}>
        <>
          <ModalHeader>Skontaktuj się z administratorem</ModalHeader>
          <ModalBody>
            <p>Brak uprawnień w systemie. Skontaktuj się z administratorem</p>
            <p>
              Opiekunem systemu jest: <strong>{SUPPORT_ADMIN_NAME}</strong>.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              $as="a"
              target="_blank"
              href={`mailto:${SUPPORT_ADMIN_MAIL}`}
              startEnhancer={<Mail size={20} />}
            >
              Skontaktuj się
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </main>
  );
}
