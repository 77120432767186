import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import React, { useEffect } from "react";
import { Lock, Pencil } from "tabler-icons-react";

import ActionsList from "../../components/actions-list";
import Cell from "../../components/cell";
import Content from "../../components/content";
import FormControl from "../../components/form-control";
import FormattedValue from "../../components/formatted-value";
import Grid from "../../components/grid";
import Header from "../../components/header";
import { useAuth } from "../../contexts/auth-context";
import { useLoading } from "../../contexts/loading-context";
import { useSupport } from "../../contexts/support-context";

export default function Account(): React.ReactElement {
  const [css, theme] = useStyletron();

  const { isFetching } = useLoading();
  const { user, refetch } = useAuth();
  const { openSupportModal } = useSupport();

  useEffect(refetch, []);

  return (
    <article>
      <Header title={"Moje konto"} />
      <Content>
        <Grid>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dane
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={6}>
            <FormControl label={"Pseudonim"}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{`${user?.nickname}`}</FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={6}>
            <FormControl label={"Adres e-mail"}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType={"email"}>
                  {user?.email}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Zmień dane",
                  icon: Pencil,
                  onClick: openSupportModal,
                },
                { label: "Zmień hasło", icon: Lock, onClick: openSupportModal },
              ]}
            />
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
