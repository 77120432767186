import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { Spinner } from "baseui/spinner";
import debounce from "lodash.debounce";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { AlertCircle } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import Pagination from "../../../components/pagination";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { BASIC_AUTH, PAGE_SIZE } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError } from "../../../utils/snackbarTypes";
import { Comment, CommentableType } from "../comments.d";

enum FieldName {
  Content = "content",
  Author = "author",
  Commentable = "commentable",
  CreatedAt = "createdAt",
  IsHidden = "isHidden",
  ReportCount = "reportCount",
}

export default function CommentsIndex(): React.ReactElement {
  const location = useLocation();
  let pageNumberFromUrl = Number(location.search.split("?page=").pop());
  if (pageNumberFromUrl < 1) {
    pageNumberFromUrl = 1;
  }
  const [paginationNumber, setPaginationNumber] = useState(pageNumberFromUrl);
  const [pageSize] = useState(PAGE_SIZE);
  const query = new URLSearchParams(useLocation().search);
  const pageNumber = parseInt(query.get("page") as string);
  const [sortBy, setSortBy] = useState<FieldName | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    null
  );
  const [searchingInputValue, setSearchingInputValue] = useState("");
  const [searchingPhrase, setSearchingPhrase] = useState("");
  const { control } = useForm<{ searchingPhrase: string }>();
  const debouncedSetSearchingPhrase = useCallback(
    debounce(setSearchingPhrase, 1000),
    []
  );
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueue } = useSnackbar();
  const { isFetching, setIsFetching } = useLoading();

  const {
    isError,
    data,
    isLoading: isQueryLoading,
    isFetching: isQueryFetching,
    refetch,
  } = useQuery(
    "comments",
    async () =>
      (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/comments/paginated`,
          {
            ...BASIC_AUTH,
            params: {
              limit: PAGE_SIZE,
              offset: (paginationNumber - 1) * pageSize,
              ...(searchingPhrase && { content: `iLike:%${searchingPhrase}%` }),
              sort: `${sortDirection === SortDirection.ASC ? "" : "-"}${
                sortBy || "createdAt"
              }`,
            },
          }
        )
      ).data
  );

  const { mutateAsync: hideComment } = useMutation(
    data?.isHidden ? `unhideComment` : `hideComment`,
    async (variables: { id: number; isHidden: boolean }) => {
      await axios.post(
        variables.isHidden
          ? `${process.env.REACT_APP_API_URL}/admin/comments/${variables.id}/unhide`
          : `${process.env.REACT_APP_API_URL}/admin/comments/${variables.id}/hide`,
        {},
        BASIC_AUTH
      );
    }
  );

  const handleSorting = (column: FieldName) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Content)}
            sortDirection={sortBy === FieldName.Content ? sortDirection : null}
          >
            Treść
          </SortingTableHeader>
        ),
        id: "content",
        Cell: ({ row }: { row: any }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/comments/${row?.original?.id}`);
            }}
            href={`/comments/${row?.original?.id}`}
          >
            <div
              className={css({
                width: "25vw",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              })}
            >
              {row.original.content}
            </div>
          </StyledLink>
        ),
      },
      {
        Header: "Autor",
        id: "author",
        Cell: ({ row }: { row: any }) => (
          <FormattedValue
            dataType="model:users"
            data={row?.original?.authorId}
            withoutIcon
            showBlankWhenEmpty
          >
            {row?.original?.author?.nickname}
          </FormattedValue>
        ),
      },
      {
        Header: "IP klienta",
        id: "ipAddress",
        Cell: ({ row }: { row: any }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="center">
              {
                row.original.activityLogs[row.original.activityLogs.length - 1]
                  ?.properties?.client?.ipAddress
              }
            </Block>
          );
        },
      },
      {
        Header: "Skomentowany obiekt",
        id: "commentable",
        Cell: ({ row }: { row: any }) => {
          const commentableType = row.original.commentableType;
          return (
            <FormattedValue
              dataType={
                commentableType === CommentableType.Article
                  ? "model:articles"
                  : commentableType === CommentableType.Game
                  ? "model:games"
                  : commentableType === CommentableType.Magazine
                  ? "model:magazines"
                  : commentableType === CommentableType.Video
                  ? "model:videos"
                  : undefined
              }
              data={row?.original?.commentableId}
              withoutIcon
              showBlankWhenEmpty
            >
              {commentableType === CommentableType.Article
                ? row?.original?.article?.title
                : commentableType === CommentableType.Game
                ? row?.original?.game?.originalTitle
                : commentableType === CommentableType.Magazine
                ? row?.original?.magazine?.issueNumber
                : commentableType === CommentableType.Video
                ? row?.original?.video?.title
                : undefined}
            </FormattedValue>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
            $style={{ justifyContent: "flex-end" }}
          >
            Data dodania
          </SortingTableHeader>
        ),
        accessor: "createdAt",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            <FormattedValue dataType="date" showBlankWhenEmpty>
              {cell?.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsHidden)}
            sortDirection={sortBy === FieldName.IsHidden ? sortDirection : null}
            $style={{ justifyContent: "center" }}
          >
            Widoczność
          </SortingTableHeader>
        ),
        accessor: "isHidden",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue dataType="visibility-icon" showBlankWhenEmpty>
              {cell?.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.ReportCount)}
            sortDirection={
              sortBy === FieldName.ReportCount ? sortDirection : null
            }
            $style={{ justifyContent: "center" }}
          >
            Zgłoszenia
          </SortingTableHeader>
        ),
        id: "reportCount",
        Cell: ({ row }: { row: any }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="center">
              {row.original.reportCount}
            </Block>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={async () => {
                try {
                  await hideComment({
                    id: row.original.id,
                    isHidden: row.original.isHidden,
                  });
                  refetch();
                } catch (error) {
                  enqueue(
                    {
                      message: "Wystąpił błąd",
                      overrides: SnackbarError,
                      startEnhancer: ({ size }: { size: number }) => (
                        <AlertCircle size={size} />
                      ),
                    },
                    DURATION.long
                  );
                }
              }}
            >
              {row.original.isHidden ? "Odkryj" : "Ukryj"}
            </Button>
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/comments/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/comments/${row?.original?.id}/edit`);
              }}
              $style={{ marginLeft: "10px" }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/comments/${row?.original?.id}`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/comments/${row?.original?.id}`);
              }}
              $style={{ marginLeft: "10px" }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection]
  );

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    refetch();
    setIsFetching(true);
  }, [paginationNumber, pageNumber]);

  useEffect(() => {
    refetch();
  }, [sortBy, sortDirection]);

  useEffect(() => {
    refetch();
  }, [searchingPhrase]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
    pageNumber && setPaginationNumber(pageNumber);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title="Komentarze"
        recordsNum={data?.totalCount}
        labels={["Lista"]}
      />
      <Content>
        <Grid>
          <Cell span={8} />
          <Cell span={4}>
            <Block marginBottom="30px">
              <FormControl>
                <ControlledInput
                  control={control}
                  value={searchingInputValue}
                  onChange={(event) => {
                    setSearchingInputValue(event?.currentTarget?.value);
                    debouncedSetSearchingPhrase(event?.currentTarget?.value);
                  }}
                  name="searchingPhrase"
                  size={SIZE.compact}
                  placeholder="Wyszukaj"
                  clearable
                  endEnhancer={
                    isQueryFetching && !isQueryLoading && <Spinner size={18} />
                  }
                />
              </FormControl>
            </Block>
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<Comment>
                  columns={columns}
                  data={data?.results}
                  noOffset
                />
              )
            )}
          </Cell>
          <Cell span={12}>
            {data && !!data?.totalCount && data?.totalCount > PAGE_SIZE && (
              <Pagination
                numPages={Math.ceil(data?.totalCount / pageSize)}
                currentPage={paginationNumber}
                onPageChange={setPaginationNumber}
              />
            )}
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
