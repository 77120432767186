import {
  API,
  InlineTool,
  InlineToolConstructorOptions,
} from "@editorjs/editorjs";

export default class InlineList implements InlineTool {
  public static isInline = true;

  private _state: boolean;
  private button: HTMLButtonElement | null;
  private api: API;
  private tag: keyof HTMLElementTagNameMap;
  private class: string;

  get state(): boolean {
    return this._state;
  }

  set state(state: boolean) {
    this._state = state;

    this.button?.classList.toggle(
      this.api.styles.inlineToolButtonActive,
      state
    );
  }

  constructor({ api }: InlineToolConstructorOptions) {
    this.api = api;
    this.button = null;
    this._state = false;

    this.tag = "ul";
    this.class = "inline-list";
  }

  render(): HTMLButtonElement {
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.innerHTML =
      '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>';
    this.button.classList.add(this.api.styles.inlineToolButton);

    return this.button;
  }

  surround(range: Range): void {
    if (this.state) {
      this.unwrap();
      return;
    }

    this.wrap(range);
  }

  wrap(range: Range): void {
    const selectedText = range.extractContents();
    const ul = document.createElement(this.tag);

    ul.classList.add(this.class);

    if (!selectedText.hasChildNodes()) {
      return;
    }

    for (const node of Array.from(selectedText.childNodes)) {
      if (node.textContent && node.textContent.trim().length > 0) {
        const li = document.createElement("li");
        li.appendChild(node);
        ul.appendChild(li);
      }
    }

    range.insertNode(ul);

    this.api.selection.expandToTag(ul);
  }

  unwrap(): void {
    const ul = this.api.selection.findParentTag(
      this.tag.toUpperCase(),
      this.class
    );

    if (ul) {
      for (const node of Array.from(ul.children)) {
        if (node.nodeName === "LI") {
          node.replaceWith(...Array.from(node.childNodes));
        }
      }

      ul.replaceWith(...Array.from(ul.childNodes));
    }
  }

  checkState(): boolean {
    const mark = this.api.selection.findParentTag(this.tag.toUpperCase());

    this.state = !!mark;
    return this.state;
  }
}
