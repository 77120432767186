import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, Eye, Pencil, Trash } from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import Editor from "../../../components/editor";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { STATIC_PAGES_FIELDS } from "../static-pages.form";

export default function StaticPagesShow(): React.ReactElement {
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(
    false
  );
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getData = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/static-pages/${id}`,
      BASIC_AUTH
    );

    return data;
  };

  const {
    data: staticPageData,
    refetch: staticPageRefetch,
    isError,
  } = useQuery(`staticPage${id}Data`, getData);

  const { mutateAsync: deletePage } = useMutation(
    `staticPage${id}Delete`,
    async () => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/static-pages/${id}`,
        BASIC_AUTH
      );
    }
  );

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      deletePage();

      enqueue({
        message: "Usunięto pomyślnie",

        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push("/static-pages");
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (staticPageData) staticPageRefetch();

    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (staticPageData) {
      setIsFetching(false);
    }
  }, [staticPageData]);

  return (
    <article>
      <Header
        title={staticPageData?.data.title}
        labels={["Strony statyczne", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            onClick: () => history.push(`/static-pages/${id}/edit`),
          },
          {
            label: "Podgląd",
            kind: KIND.secondary,
            $as: "a",
            target: "_blank",
            href: `${process.env.REACT_APP_URL}api/preview?secret=${staticPageData?.data.previewToken}&slug=/strona/${staticPageData?.data.slug}`,
            startEnhancer: <Eye size={18} />,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {STATIC_PAGES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl
                    label={item.label}
                    disabled={true}
                    caption={item.caption}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.type === FIELDS.Editor ? (
                      <Editor
                        placeholder={false}
                        readOnly
                        data={staticPageData?.data.body}
                      />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={staticPageData?.data[item.id]}
                      >
                        {item.show.accessor
                          ? staticPageData?.data &&
                            staticPageData?.data[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : staticPageData?.data &&
                            staticPageData?.data[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}

          {!staticPageData?.data.isSystem && (
            <>
              <Cell span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  Akcje
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
              <Cell span={12}>
                <ActionsList
                  actions={[
                    {
                      label: "Usuń stronę statyczną",
                      icon: Trash,
                      color: theme.colors.primary,
                      onClick: () => setIsDeleteConfirmDialogOpen(true),
                    },
                  ]}
                />
              </Cell>
              <ConfirmDialog
                isOpen={isDeleteConfirmDialogOpen}
                label={`Usunięcie strony statycznej ${staticPageData?.data.title}`}
                close={() => setIsDeleteConfirmDialogOpen(false)}
                confirm={() => {
                  onSubmit();
                  setIsDeleteConfirmDialogOpen(false);
                }}
              />
            </>
          )}
        </Grid>
      </Content>
    </article>
  );
}
