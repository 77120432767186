import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { AlertCircle, Check, FileOff, Plus } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ImagePreview, {
  ImagePreviewSize,
} from "../../../components/image-preview";
import { ControlledInput } from "../../../components/input";
import {
  ControlledGamesSelect,
  ControlledGamingPlatformsSelect,
} from "../../../components/select";
import { ControlledTextArea } from "../../../components/text-area";
import { BASIC_AUTH } from "../../../constants";
import { useImageLibrary } from "../../../contexts/image-library-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { slugifyText } from "../../../utils/slugify";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { EDITORS_FIELDS, FormInputs } from "../editors.form";

export default function EditorsCreate(): React.ReactElement {
  const [isSlugChangedByUser, setIsSlugChangedByUser] = useState(false);
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { isLoading, setIsLoading } = useLoading();
  const { open } = useImageLibrary();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const watchFields = watch();
  const watchNick = watchFields.nick;

  const postData = async (values: FormInputs) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/editors`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("editorCreate", postData);

  const onSubmit = async ({
    nick,
    slug,
    avatarUrl,
    backgroundImageUrl,
    description,
    socialMediaFacebook,
    socialMediaTwitter,
    socialMediaYoutube,
    socialMediaXbox,
    socialMediaSteam,
    socialMediaTwitch,
    socialMediaDiscord,
    // gamingPlatformsSelect,
    favouriteGamesSelect,
    consideredGamesSelect,
    ownedGamesSelect,
    playedGamesSelect,
    completedGamesSelect,
    isVisible,
    trackingCode,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const data = await mutateAsync({
        nick,
        slug,
        avatarUrl,
        backgroundImageUrl,
        description,
        socialMediaUrls: {
          ...(socialMediaFacebook && { facebook: socialMediaFacebook }),
          ...(socialMediaTwitter && { twitter: socialMediaTwitter }),
          ...(socialMediaYoutube && { youtube: socialMediaYoutube }),
          ...(socialMediaXbox && { xbox: socialMediaXbox }),
          ...(socialMediaSteam && { steam: socialMediaSteam }),
          ...(socialMediaTwitch && { twitch: socialMediaTwitch }),
          ...(socialMediaDiscord && { discord: socialMediaDiscord }),
        },
        gamingPlatforms: "",
        favouriteGameIds: favouriteGamesSelect?.map(
          (item: { id: number }) => item.id
        ),
        consideredGameIds: consideredGamesSelect?.map(
          (item: { id: number }) => item.id
        ),
        ownedGameIds: ownedGamesSelect?.map((item: { id: number }) => item.id),
        playedGameIds: playedGamesSelect?.map(
          (item: { id: number }) => item.id
        ),
        completedGameIds: completedGamesSelect?.map(
          (item: { id: number }) => item.id
        ),
        isVisible: !!isVisible,
        trackingCode,
      });
      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/editors/${data?.id}`);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    setIsSlugChangedByUser(false);
  }, []);

  useEffect(() => {
    watchNick !== undefined &&
      !isSlugChangedByUser &&
      setValue("slug", slugifyText(watchNick));
  }, [watchNick]);

  return (
    <article>
      <Header
        title="Nowy redaktor"
        labels={["Redaktorzy", "Tworzenie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () => history.push("/editors"),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {EDITORS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={item.caption}
                      required={item.create.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={isLoading}
                          $style={{ marginTop: "30px" }}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          <p
                            className={css({
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            })}
                          >
                            {item.label}
                          </p>
                        </ControlledCheckbox>
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.GamesSelect ? (
                        <ControlledGamesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          multi
                        />
                      ) : item.type === FIELDS.GamingPlatformsSelect ? (
                        <ControlledGamingPlatformsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          multi
                        />
                      ) : item.type === FIELDS.ImagePicker ? (
                        <>
                          {watch(item.id as keyof FormInputs) && (
                            <ImagePreview
                              image={{
                                path: watch(
                                  item.id as keyof FormInputs
                                ) as string,
                              }}
                              size={ImagePreviewSize.Large}
                            />
                          )}
                          <Button
                            type="button"
                            size="compact"
                            kind={KIND.secondary}
                            onClick={() =>
                              open((image) =>
                                setValue(
                                  item.id as keyof FormInputs,
                                  `${process.env.REACT_APP_CDN_URL}/images/${image.path}`
                                )
                              )
                            }
                            $style={{ marginTop: "10px" }}
                          >
                            Wybierz z biblioteki lub wgraj
                          </Button>
                        </>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                          {...(item.id === "slug" && {
                            onKeyUp: () => setIsSlugChangedByUser(true),
                            $style: {
                              color: isSlugChangedByUser ? "#000" : "#999",
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
