import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import React, { MouseEvent, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { Cell as TableCell, Row } from "react-table";
import {
  AlertCircle,
  ChevronDown,
  ChevronDownLeft,
  ChevronRight,
  Plus,
} from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError } from "../../../utils/snackbarTypes";
import { ArticleCategory } from "../article-categories";

export default function ArticleCategoriesIndex(): React.ReactElement {
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueue } = useSnackbar();
  const { isFetching, setIsFetching } = useLoading();

  const { isError, data, refetch } = useQuery(
    "article-categories",
    async () =>
      (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/article-categories/paginated`,
          {
            ...BASIC_AUTH,
            params: {
              limit: 100,
            },
          }
        )
      ).data
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        id: "name",
        Cell: ({ row }: { row: any }) => (
          <Block display="flex" justifyContent="flex-start" width="10vw">
            {row.canExpand ? (
              <div {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <ChevronDown
                    size={16}
                    className={css({
                      verticalAlign: "middle",
                      marginRight: "6px",
                      display: "inline",
                      marginLeft: `${row.depth * 16}px`,
                    })}
                  />
                ) : (
                  <ChevronRight
                    size={16}
                    className={css({
                      verticalAlign: "middle",
                      marginRight: "6px",
                      display: "inline",
                      marginLeft: `${row.depth * 16}px`,
                    })}
                  />
                )}
              </div>
            ) : (
              <div>
                <ChevronDownLeft
                  size={16}
                  className={css({
                    verticalAlign: "middle",
                    marginRight: "6px",
                    display: "inline",
                    marginLeft: `${row.depth * 16}px`,
                  })}
                />
              </div>
            )}
            <StyledLink
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/article-categories/${row.original.id}`);
              }}
              href={`/article-categories/${row.original.id}`}
              style={{ cursor: "pointer" }}
            >
              {row?.original?.name}
            </StyledLink>
          </Block>
        ),
      },
      {
        Header: "Widoczność",
        accessor: "isVisible",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue dataType="visibility-icon" showBlankWhenEmpty>
              {!cell?.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/article-categories/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/article-categories/${row?.original?.id}/edit`);
              }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/article-categories/${row?.original?.id}`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/article-categories/${row?.original?.id}`);
              }}
              $style={{ marginLeft: "10px" }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title="Kategorie artykułów"
        recordsNum={data?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj kategorię",
            kind: KIND.secondary,
            startEnhancer: <Plus size={18} />,
            onClick: () => history.push("/article-categories/create"),
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<ArticleCategory>
                  noOffset
                  expandedNumber={1000}
                  columns={columns}
                  data={data?.results}
                  $rows={(rows, prepareRow) => {
                    const nestChildrenUnits = (
                      category: ArticleCategory,
                      depth: number
                    ): Row<ArticleCategory>[] => {
                      return rows.filter(
                        (row) =>
                          (row.original as ArticleCategory).parentId ===
                          category?.id
                      )?.length > 0
                        ? rows
                            .filter(
                              (row) =>
                                (row.original as ArticleCategory).parentId ===
                                category?.id
                            )
                            .map((row: any) => {
                              row.depth = depth;

                              rows.filter(
                                (r) =>
                                  (r.original as ArticleCategory).parentId ===
                                  row?.original.id
                              )?.length > 0
                                ? (row.canExpand = true)
                                : (row.canExpand = false);

                              prepareRow(row);
                              return row.isExpanded
                                ? [
                                    row.cells.map((cell: TableCell) =>
                                      cell.render("Cell")
                                    ),
                                    ...nestChildrenUnits(
                                      row.original,
                                      depth + 1
                                    ),
                                  ]
                                : [
                                    row.cells.map((cell: TableCell) =>
                                      cell.render("Cell")
                                    ),
                                  ];
                            })
                            .flat()
                        : [];
                    };

                    return rows
                      .filter(
                        (row) => !(row.original as ArticleCategory).parentId
                      )
                      .map((row: any) => {
                        rows.filter(
                          (r) =>
                            (r.original as ArticleCategory).parentId ===
                            row?.original.id
                        )?.length > 0
                          ? (row.canExpand = true)
                          : (row.canExpand = false);
                        prepareRow(row);
                        return row.isExpanded
                          ? [
                              row.cells.map((cell: TableCell) =>
                                cell.render("Cell")
                              ),
                              ...nestChildrenUnits(row.original, 1),
                            ]
                          : [
                              row.cells.map((cell: TableCell) =>
                                cell.render("Cell")
                              ),
                            ];
                      })
                      .flat();
                  }}
                />
              )
            )}
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
