export const formValidation = {
  messages: {
    incorrectEmail: "Adres e-mail jest nieprawidłowy.",
    requiredDateInPublishedArticle:
      "Publikowany artykuł musi mieć podaną datę publikacji.",
    requiredPermission: "Proszę zaznaczyć przynajmniej jedno uprawnienie.",
    required: "To pole jest wymagane.",
    incorrectRating: "Ocena musi być z zakresu 1-10.",
  },
  patterns: {
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    text: "[a-zA-Z]",
  },
};
