/**
 * App Constants
 */

export const AUTH_TOKEN = "auth-token";
export const APP_NAME = "CD ACTION – CM";

export const SUPPORT_ADMIN_NAME = "Jan Kowalski";
export const SUPPORT_ADMIN_MAIL = "administrator@";

export const ARTICLE_ATTRIBUTES = [
  { id: "isSponsored", label: "Sponsorowany" },
  { id: "isForAdults", label: "Dla pełnoletnich" },
  { id: "isPinned", label: "Przypięty" },
  { id: "isRecommended", label: "Polecany" },
  { id: "isFeatured", label: "Wyróżniony" },
];

export const BASIC_AUTH = {
  auth: {
    username: "apiuser",
    password: "codeshine@123;",
  },
};

export const GAMING_PLATFORMS = [
  { id: "PC", label: "PC" },
  { id: "PS4", label: "Play Station 4" },
  { id: "PS5", label: "Play Station 5" },
  { id: "XSX", label: "Xbox Series X" },
  { id: "XO", label: "Xbox One" },
  { id: "Switch", label: "Nintendo Switch" },
];

export const PAGE_SIZE = 30;

export const TAGGABLE_TYPES = [
  { id: "Article", label: "Artykuł" },
  { id: "Game", label: "Gra" },
];

export const TAG_KINDS = [
  { id: "General", label: "General" },
  { id: "Multiplayer", label: "Multiplayer" },
  { id: "Genre", label: "Gatunek" },
  { id: "Platform", label: "Platforma" },
  { id: "Developer", label: "Producent" },
  { id: "Publisher", label: "Wydawca" },
  { id: "LanguageVersion", label: "Wersja językowa" },
];

export const PEGI_AGE_RATINGS = [
  { id: 3, label: "Od lat 3" },
  { id: 7, label: "Od lat 7" },
  { id: 12, label: "Od lat 12" },
  { id: 16, label: "Od lat 16" },
  { id: 18, label: "Od lat 18" },
];

export const PEGI_CONTENT_DESCRIPTORS = [
  { id: "BadLanguageAnnotated", label: "Wulgarny język" },
  { id: "Discrimination", label: "Dyskryminacja" },
  { id: "Drugs", label: "Narkotyki" },
  { id: "Fear", label: "Strach" },
  { id: "Gambling", label: "Hazard" },
  { id: "InGamePurchases", label: "Zakupy w grze" },
  { id: "Online", label: "Gra online" },
  { id: "Sex", label: "Seks" },
  { id: "Violence", label: "Przemoc" },
];

export const USER_ROLES = [
  { id: "Writer", label: "Edytor" },
  { id: "Editor", label: "Redaktor" },
  { id: "Administrator", label: "Administrator" },
];
