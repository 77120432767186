import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, DeviceFloppy, FileOff } from "tabler-icons-react";

import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import { ControlledArticlesCategoriesSelect } from "../../../components/select";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Error } from "../article-categories";
import {
  ARTICLE_CATEGORIES_FIELDS,
  FormInputs,
} from "../article-categories.form";

export default function ArticleCategoriesEdit(): React.ReactElement {
  const [isSlugChangedByUser, setIsSlugChangedByUser] = useState(false);
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<FormInputs>();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/article-categories/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: queryData, refetch } = useQuery(
    `articleCategory${id}CurrentData`,
    getData
  );

  const editData = async (values: FormInputs) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/article-categories/${id}`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("articleCategoryEdit", editData);

  const onSubmit = async ({
    name,
    slug,
    parent,
    isVisible,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync({
        name,
        slug,
        parentId: parent && parent[0].id,
        isVisible,
      });

      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/article-categories/${id}`);
    } catch (error: any) {
      (error.response.data as Error).validationErrors.map((error) => {
        setError(error.property as keyof FormInputs, {
          type: "manual",
          message: error.errors[0],
        });
      });
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    setIsSlugChangedByUser(false);
    if (queryData) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (queryData) {
      setIsFetching(false);
    }
  }, [queryData]);

  useEffect(() => {
    if (queryData) {
      setValue("name", queryData?.name);
      setValue("slug", queryData?.slug);
      setValue("isVisible", queryData?.isVisible);
      queryData?.parent && setValue("parent", [{ id: queryData?.parent?.id }]);
    }
  }, [queryData]);

  return (
    <article>
      <Header
        title={queryData?.name}
        labels={["Kategorie", "Edytowanie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () =>
              history.push(
                history.location.pathname.split("/").slice(0, -1).join("/")
              ),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {ARTICLE_CATEGORIES_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      required={item.edit.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="48px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.ArticlesCategoriesSelect ? (
                        <ControlledArticlesCategoriesSelect
                          control={control}
                          name={item.id}
                          disable="children"
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={isLoading}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          <span
                            className={css({
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            })}
                          >
                            {item.label}
                          </span>
                        </ControlledCheckbox>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                          {...(item.id === "slug" && {
                            onKeyUp: () => setIsSlugChangedByUser(true),
                            $style: {
                              color: isSlugChangedByUser ? "#000" : "#999",
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
