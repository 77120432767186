import axios from "axios";
import { useStyletron } from "baseui";
import { styled } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { Spinner } from "baseui/spinner";
import { Theme } from "baseui/theme";
import { LabelSmall } from "baseui/typography";
import debounce from "lodash.debounce";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import {
  AlertCircle,
  LayoutGrid,
  LayoutList,
  Plus,
  Upload,
} from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import IconsSwitch from "../../../components/icons.switch";
import ImagePreview from "../../../components/image-preview";
import { ControlledInput } from "../../../components/input";
import Pagination from "../../../components/pagination";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import Thumbnail from "../../../components/thumbnail";
import { BASIC_AUTH, PAGE_SIZE } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { Image } from "../../../utils/models";
import { SnackbarError } from "../../../utils/snackbarTypes";
import { Media } from "../media";

enum ViewType {
  Grid,
  List,
}

enum FieldName {
  OriginalName = "originalName",
  Size = "size",
  Mimetype = "mimetype",
  CreatedAt = "createdAt",
}

const Dropzone = styled<{ isDragActive: boolean }, "div", Theme>(
  "div",
  ({ $theme, isDragActive }) => ({
    width: "100%",
    height: "100%",
    backgroundColor: isDragActive
      ? $theme.colors.backgroundLightAccent
      : $theme.colors.backgroundPrimary,
    borderRadius: $theme.borders.radius300,
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: isDragActive ? $theme.colors.accent : "transparent",
    padding: isDragActive ? $theme.sizing.scale400 : 0,
  })
);

export default function MediaIndex(): React.ReactElement {
  const location = useLocation();
  let pageNumberFromUrl = Number(location.search.split("?page=").pop());
  if (pageNumberFromUrl < 1) {
    pageNumberFromUrl = 1;
  }
  const [paginationNumber, setPaginationNumber] = useState(pageNumberFromUrl);
  const [pageSize] = useState(PAGE_SIZE);
  const query = new URLSearchParams(useLocation().search);
  const pageNumber = parseInt(query.get("page") as string);
  const [sortBy, setSortBy] = useState<FieldName | null>(FieldName.CreatedAt);
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.DESC
  );
  const [searchingInputValue, setSearchingInputValue] = useState("");
  const [searchingPhrase, setSearchingPhrase] = useState("");
  const { control } = useForm<{ searchingPhrase: string }>();
  const debouncedSetSearchingPhrase = useCallback(
    debounce(setSearchingPhrase, 1000),
    []
  );
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueue } = useSnackbar();
  const { isFetching, setIsFetching } = useLoading();
  const [view, setView] = useState<ViewType>(
    localStorage.getItem("media-view") === "0" ? ViewType.Grid : ViewType.List
  );

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const {
    isError,
    data,
    isLoading: isQueryLoading,
    isFetching: isQueryFetching,
    refetch,
  } = useQuery(
    "media",
    async () =>
      (
        await axios.get(`${process.env.REACT_APP_API_URL}/admin/images`, {
          ...BASIC_AUTH,
          params: {
            limit: PAGE_SIZE,
            offset: (paginationNumber - 1) * pageSize,
            originalName: `iLike:%${searchingPhrase}%`,
            mimetype: `,iLike:%${searchingPhrase}%`,
            sort: `${sortDirection === SortDirection.ASC ? "" : "-"}${
              sortBy || "createdAt"
            }`,
          },
        })
      ).data
  );

  const handleSorting = (column: FieldName) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    refetch();
    setIsFetching(true);
  }, [paginationNumber, pageNumber]);

  useEffect(() => {
    refetch();
  }, [sortBy, sortDirection]);

  useEffect(() => {
    refetch();
  }, [searchingPhrase]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
    pageNumber && setPaginationNumber(pageNumber);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.OriginalName)}
            sortDirection={
              sortBy === FieldName.OriginalName ? sortDirection : null
            }
          >
            Nazwa
          </SortingTableHeader>
        ),
        id: "name",
        Cell: ({ row }: { row: any }) => (
          <Thumbnail
            href={`/media/${row.original.id}`}
            imageUrl={`${process.env.REACT_APP_CDN_URL}/images/${row.original.path}`}
            label={row?.original?.originalName}
          />
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Size)}
            sortDirection={sortBy === FieldName.Size ? sortDirection : null}
            $style={{ justifyContent: "flex-end" }}
          >
            Rozmiar
          </SortingTableHeader>
        ),
        id: "size",
        Cell: ({ row }: { row: any }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="flex-end">
              {formatBytes(row?.original?.size)}
            </Block>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Mimetype)}
            sortDirection={sortBy === FieldName.Mimetype ? sortDirection : null}
            $style={{ justifyContent: "center" }}
          >
            Typ
          </SortingTableHeader>
        ),
        id: "mimetype",
        Cell: ({ row }: { row: any }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="center">
              {row?.original?.mimetype}
            </Block>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
            $style={{ justifyContent: "flex-end" }}
          >
            Data dodania
          </SortingTableHeader>
        ),
        id: "createdAt",
        Cell: ({ row }: { row: any }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="flex-end">
              <FormattedValue dataType="date" showBlankWhenEmpty>
                {row?.original?.createdAt}
              </FormattedValue>
            </Block>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/media/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/media/${row?.original?.id}/edit`);
              }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/media/${row?.original?.id}`}
              $style={{ marginLeft: "10px" }}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/media/${row?.original?.id}`);
              }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  const [uploadingImages, setUploadingImages] = useState<Partial<Image>[]>([]);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const uploadImageMutation = useMutation((file: File) => {
    const data = new FormData();
    data.append("file", file);

    return axios.post(`${process.env.REACT_APP_API_URL}/admin/images`, data, {
      ...BASIC_AUTH,
    });
  });

  const onDrop = useCallback(async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      const image: Partial<Image> = {
        originalName: file.name,
      };

      setUploadingImages((uploadingImages) => [...uploadingImages, image]);

      await uploadImageMutation.mutateAsync(file);

      setUploadingImages((uploadingImages) =>
        uploadingImages.filter((uploadingImage) => uploadingImage !== image)
      );
      refetch();
    }
  }, []);

  const handleGridImageClick = (image: Image) => {
    setSelectedImage((selectedImage) =>
      selectedImage?.id !== image.id ? image : null
    );
    history.push(`/media/${image.id}`);
  };

  useEffect(() => {
    localStorage.setItem("media-view", String(view));
  }, [view]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: pickFiles,
  } = useDropzone({
    noClick: true,
    onDrop,
  });

  return (
    <article>
      <Header
        title="Media"
        recordsNum={data?.totalCount}
        labels={view === ViewType.Grid ? ["Siatka"] : ["Lista"]}
        buttons={[
          {
            label: "Wgraj plik",
            kind: KIND.secondary,
            $as: "a",
            startEnhancer: <Upload size={18} />,
            onClick: pickFiles,
          },
          {
            label: "Dodaj plik",
            kind: KIND.secondary,
            $as: "a",
            href: "/media/create",
            startEnhancer: <Plus size={18} />,
            onClick: (event: MouseEvent) => {
              event.preventDefault();
              history.push("/media/create");
            },
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <div
              className={css({
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginBottom: "16px",
              })}
            >
              <span
                className={css({
                  textTransform: "uppercase",
                  fontSize: "12px",
                  marginRight: "8px",
                })}
              >
                Widok
              </span>
              <IconsSwitch
                aria-label="Set grid/list view"
                value={view}
                iconFalse={<LayoutGrid />}
                iconTrue={<LayoutList />}
                onClick={() =>
                  setView(
                    view === ViewType.Grid ? ViewType.List : ViewType.Grid
                  )
                }
              />
            </div>
          </Cell>
          <Cell span={8} />
          <Cell span={4}>
            <Block marginBottom="30px">
              <FormControl>
                <ControlledInput
                  control={control}
                  value={searchingInputValue}
                  onChange={(event) => {
                    setSearchingInputValue(event?.currentTarget?.value);
                    debouncedSetSearchingPhrase(event?.currentTarget?.value);
                  }}
                  name="searchingPhrase"
                  size={SIZE.compact}
                  placeholder="Wyszukaj"
                  clearable
                  endEnhancer={
                    isQueryFetching && !isQueryLoading && <Spinner size={18} />
                  }
                />
              </FormControl>
            </Block>
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching && (
              <Skeleton rows={0} height="300px" width="100%" animation />
            )}{" "}
            {!isFetching && data && (
              <div
                className={css({
                  display: view === ViewType.Grid ? "block" : "none",
                })}
              >
                <Dropzone {...getRootProps()} isDragActive={isDragActive}>
                  {data && data.results.length > 0 ? (
                    <FlexGrid
                      flexGridColumnCount={6}
                      flexGridColumnGap="scale400"
                      flexGridRowGap="scale400"
                    >
                      {uploadingImages.map((image, index) => (
                        <FlexGridItem key={`uploadingImage${index}`}>
                          <ImagePreview image={image} isLoading={true} />
                        </FlexGridItem>
                      ))}
                      {data &&
                        data.results.map((image: Image) => (
                          <FlexGridItem key={image.id}>
                            <ImagePreview
                              image={image}
                              isSelected={
                                !!selectedImage && selectedImage.id === image.id
                              }
                              onClick={() => {
                                handleGridImageClick(image);
                              }}
                            />
                          </FlexGridItem>
                        ))}
                    </FlexGrid>
                  ) : (
                    <LabelSmall>Brak obrazków</LabelSmall>
                  )}
                  <input {...getInputProps()} />
                </Dropzone>
              </div>
            )}{" "}
            {!isFetching && data && (
              <div
                className={css({
                  display: view === ViewType.List ? "block" : "none",
                })}
              >
                <Table<Media> columns={columns} data={data?.results} noOffset />
              </div>
            )}
          </Cell>
          <Cell span={12}>
            {data && !!data?.totalCount && data?.totalCount > PAGE_SIZE && (
              <Pagination
                numPages={Math.ceil(data?.totalCount / pageSize)}
                currentPage={paginationNumber}
                onPageChange={setPaginationNumber}
              />
            )}
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
