import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertCircle,
  Check,
  Eye,
  Minus,
  Pencil,
  Trash,
} from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import Editor from "../../../components/editor";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Comment } from "../../Comments/comments";
import { ARTICLES_FIELDS } from "../articles.form";

export default function ArticlesShow(): React.ReactElement {
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(
    false
  );
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getArticleData = async () => {
    const [article, comments] = await axios.all([
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/articles/${id}`,
        BASIC_AUTH
      ),
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/articles/${id}/comments`,
        BASIC_AUTH
      ),
    ]);
    return {
      article: article.data,
      comments: comments.data,
    };
  };

  const { data: articleData, refetch: articleRefetch, isError } = useQuery(
    `article${id}DataAndComments`,
    getArticleData
  );

  const categoryId = articleData?.article?.categoryId;
  const authorId = articleData?.article?.authorId;
  const editorId = articleData?.article?.editorId;

  const getCategoryName = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/article-categories/${categoryId}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: categoryData, refetch: categoryRefetch } = useQuery(
    `category${categoryId}Name`,
    getCategoryName,
    {
      enabled: !!categoryId,
    }
  );

  const getAuthorName = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/users/${authorId}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: authorData, refetch: authorRefetch } = useQuery(
    `user${authorId}Name`,
    getAuthorName,
    {
      enabled: !!authorId,
    }
  );

  const getEditorName = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/editors/${editorId}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: editorData, refetch: editorRefetch } = useQuery(
    `editor${editorId}Name`,
    getEditorName,
    {
      enabled: !!editorId,
    }
  );

  const { mutateAsync: deleteArticle } = useMutation(
    `article${id}Delete`,
    async () => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/articles/${id}`,
        BASIC_AUTH
      );
    }
  );

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      deleteArticle();

      enqueue({
        message: "Usunięto pomyślnie",

        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push("/articles");
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (articleData) articleRefetch();

    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (categoryData) categoryRefetch();
  }, []);

  useEffect(() => {
    if (authorData) authorRefetch();
  }, []);

  useEffect(() => {
    if (editorData) editorRefetch();
  }, []);

  useEffect(() => {
    if (articleData) {
      setIsFetching(false);
    }
  }, [articleData]);

  const commentsColumns = React.useMemo(
    () => [
      {
        Header: "Treść",
        id: "content",
        Cell: ({ row }: { row: any }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/comments/${row?.original?.id}`);
            }}
            href={`/comments/${row?.original?.id}`}
          >
            <FormattedValue>{row.original.content}</FormattedValue>
          </StyledLink>
        ),
      },
      {
        Header: "Autor",
        id: "author",
        Cell: ({ row }: { row: any }) => (
          <FormattedValue
            dataType="model:users"
            data={row?.original?.authorId}
            withoutIcon
          >
            {row?.original?.author?.nickname}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            Data dodania
          </Block>
        ),
        accessor: "createdAt",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            <FormattedValue dataType="date">{cell?.value}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" alignItems="center" justifyContent="center">
            Widoczność
          </Block>
        ),
        accessor: "isHidden",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" justifyContent="center" alignItems="center">
            <FormattedValue dataType="visibility-icon">
              {cell?.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/comments/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/comments/${row?.original?.id}/edit`);
              }}
              $style={{ marginLeft: "10px" }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/comments/${row?.original?.id}`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/comments/${row?.original?.id}`);
              }}
              $style={{ marginLeft: "10px" }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <article>
      <Header
        title={articleData?.article?.title}
        labels={["Artykuły", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            onClick: () => history.push(`/articles/${id}/edit`),
          },
          {
            label: "Podgląd",
            kind: KIND.secondary,
            $as: "a",
            target: "_blank",
            href: `${process.env.REACT_APP_URL}api/preview?secret=${articleData?.article?.previewToken}&slug=/${categoryData?.slug}/${articleData?.article?.slug}`,
            startEnhancer: <Eye size={18} />,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {ARTICLES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.type === FIELDS.Editor ? (
                      <Editor
                        placeholder={false}
                        readOnly
                        data={articleData?.article.body}
                      />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          (item.id === "category" &&
                            articleData?.article?.categoryId) ||
                          (item.id === "author" &&
                            articleData?.article?.authorId) ||
                          (item.id === "editor" &&
                            articleData?.article?.editorId) ||
                          (item.id === "games" && articleData?.article?.gameId)
                        }
                        date={articleData?.article?.publishedAt}
                      >
                        {item.show.accessor
                          ? articleData?.article &&
                            articleData?.article[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : item.id === "isPublished"
                          ? articleData?.article &&
                            !articleData?.article[item.id]
                          : item.id === "category"
                          ? categoryData && categoryData?.name
                          : item.id === "author"
                          ? authorData && authorData?.nickname
                          : item.id === "editor"
                          ? editorData && editorData?.nick
                          : articleData?.article &&
                            articleData?.article[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Baner
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {articleData?.article?.bannerUrl ? (
              <img
                src={articleData?.article?.bannerUrl}
                alt="banner"
                className={css({ maxHeight: "400px" })}
              />
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  color: "#999999",
                })}
              >
                <>
                  <Minus
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Brak
                </>
              </div>
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Komentarze
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              articleData && (
                <Table<Comment>
                  columns={commentsColumns}
                  data={articleData?.comments}
                  noOffset
                />
              )
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Usuń artykuł",
                  icon: Trash,
                  color: theme.colors.primary,
                  onClick: () => setIsDeleteConfirmDialogOpen(true),
                },
              ]}
            />
          </Cell>
          <ConfirmDialog
            isOpen={isDeleteConfirmDialogOpen}
            label={`Usunięcie artykułu ${articleData?.article?.title}`}
            close={() => setIsDeleteConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsDeleteConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
