import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, DeviceFloppy, FileOff } from "tabler-icons-react";

import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledSexSelect,
  ControlledUserRolesSelect,
} from "../../../components/select";
import { BASIC_AUTH } from "../../../constants";
import { useAuth } from "../../../contexts/auth-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { FormInputs, USERS_FIELDS } from "../users.form";

export default function UsersEdit(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormInputs>();

  const { user } = useAuth();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/users/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: queryData, refetch } = useQuery(
    `user${id}CurrentData`,
    getData
  );

  const editData = async (values: FormInputs) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/users/${id}`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("userEdit", editData);

  const onSubmit = async ({
    nickname,
    email,
    password,
    avatarUrl,
    backgroundImageUrl,
    age,
    sexSelect,
    rolesSelect,
    residence,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync({
        nickname,
        email,
        password,
        avatarUrl,
        backgroundImageUrl,
        age,
        sex: sexSelect && sexSelect[0] ? sexSelect[0].id : null,
        roles: rolesSelect && rolesSelect.map((item) => item.id),
        residence,
      });

      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/users/${id}`);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (queryData) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (queryData) {
      setIsFetching(false);
    }
  }, [queryData]);

  useEffect(() => {
    setValue("nickname", queryData?.nickname);
    setValue("email", queryData?.email);
    setValue("password", queryData?.password);
    setValue("avatarUrl", queryData?.avatarUrl);
    setValue("backgroundImageUrl", queryData?.backgroundImageUrl);
    setValue("age", queryData?.age);
    queryData?.sex && setValue("sexSelect", [{ id: queryData?.sex }]);
    setValue("residence", queryData?.residence);
    queryData?.roles?.length > 0 &&
      setValue(
        "rolesSelect",
        queryData?.roles.map((role: string) => ({
          id: role,
        }))
      );
  }, [queryData]);

  return (
    <article>
      <Header
        title={queryData?.nickname}
        labels={["Użytkownicy", "Edytowanie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () =>
              history.push(
                history.location.pathname.split("/").slice(0, -1).join("/")
              ),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {USERS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter(
                  (f) =>
                    f.edit.visible &&
                    (!f.role ||
                      (f.role &&
                        !!user?.roles?.find((role) => f.role === role)))
                )
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      required={item.edit.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="48px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.SexSelect ? (
                        <ControlledSexSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.UserRolesSelect ? (
                        <ControlledUserRolesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          rules={{
                            ...(item.create.required && {
                              required: formValidation.messages.required,
                            }),
                            ...(item.dataType === "email" && {
                              pattern: {
                                value: formValidation.patterns.email,
                                message: formValidation.messages.incorrectEmail,
                              },
                            }),
                          }}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
