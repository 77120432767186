import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { AlertCircle, Check, FileOff, Plus } from "tabler-icons-react";

import Cell from "../../../components/cell";
import ColorPicker from "../../../components/color-picker";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { FormInputs, LABELS_FIELDS } from "../labels.form";

export default function LabelsCreate(): React.ReactElement {
  const [choosedColor, setChoosedColor] = useState("#FFFFFF");
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { isLoading, setIsLoading } = useLoading();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormInputs>();

  const postData = async (values: FormInputs) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/labels`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("labelCreate", postData);

  const onSubmit = async ({ name }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const data = await mutateAsync({
        name,
        color: choosedColor,
      });
      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/labels/${data?.id}`);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  return (
    <article>
      <Header
        title="Nowa etykieta"
        labels={["Etykiety", "Tworzenie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () => history.push("/labels"),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {LABELS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      required={item.create.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.ColorPicker ? (
                        <ColorPicker
                          color={choosedColor.toUpperCase()}
                          setColor={setChoosedColor}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
