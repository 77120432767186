import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Tag as BaseTag, TagProps } from "baseui/tag";
import React from "react";
import { StyleObject } from "styletron-react";

type Props = {
  $style?: StyleObject;
  children: React.ReactNode;
  forLabels?: boolean;
  labelColor?: string;
} & TagProps;

export default function ObjectTag({
  children,
  $style,
  forLabels,
  labelColor,
  ...rest
}: Props): React.ReactElement {
  const [css] = useStyletron();

  return (
    <BaseTag
      closeable={false}
      overrides={{
        Root: {
          style: {
            marginTop: "0px",
            marginBottom: "0px",
          },
        },
        Text: {
          style: {
            ...$style,
          },
        },
      }}
      {...rest}
    >
      {forLabels ? (
        <Block display="flex" alignItems="center">
          <div
            className={css({
              width: "14px",
              height: "14px",
              backgroundColor: labelColor,
              marginRight: "6px",
              borderRadius: "50%",
            })}
          />

          {children}
        </Block>
      ) : (
        children
      )}
    </BaseTag>
  );
}
