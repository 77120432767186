import { OutputData } from "@editorjs/editorjs";

import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  title: string;
  slug: string;
  isVisible?: boolean;
  body?: OutputData[];
};

export const STATIC_PAGES_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "title",
        label: "Tytuł",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "slug",
        label: "Slug",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "isVisible",
        label: "Widoczność",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isSystem",
        label: "Stała strona",
        caption:
          "Stała strona nie może zostać usunięta i jest dostępna pod adresem bez zagnieżdżenia /strona/[slug].",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: "body",
        label: "",
        span: 12,
        type: FIELDS.Editor,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
