import { SnackbarElementOverridesT } from "baseui/snackbar";

import theme from "../baseweb";

export const SnackbarError: SnackbarElementOverridesT = {
  Root: {
    style: {
      backgroundColor: theme.colors.negative,
    },
  },
};

export const SnackbarWarning: SnackbarElementOverridesT = {
  Root: {
    style: {
      backgroundColor: theme.colors.warning,
    },
  },
};

export const SnackbarSuccess: SnackbarElementOverridesT = {
  Root: {
    style: {
      backgroundColor: theme.colors.positive,
    },
  },
};
