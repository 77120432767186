import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertCircle,
  Check,
  Eye,
  Minus,
  Pencil,
  ThumbDown,
  ThumbUp,
  Trash,
} from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Game } from "../../Games/games";
import { EDITORS_FIELDS } from "../editors.form";

export default function EditorsShow(): React.ReactElement {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/editors/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data, isError, refetch } = useQuery(`editorShow${id}`, getData);

  const getGames = async () => {
    const [considered, owned, played, completed] = await axios.all([
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/editors/${id}/considered-games`,
        BASIC_AUTH
      ),
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/editors/${id}/owned-games`,
        BASIC_AUTH
      ),
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/editors/${id}/played-games`,
        BASIC_AUTH
      ),
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/editors/${id}/completed-games`,
        BASIC_AUTH
      ),
    ]);
    return {
      consideredGamesData: considered.data,
      ownedGamesData: owned.data,
      playedGamesData: played.data,
      completedGamesData: completed.data,
    };
  };

  const { data: gamesData, refetch: gamesRefetch } = useQuery(
    `editor${id}Games`,
    getGames
  );

  const { mutateAsync } = useMutation("editorDelete", async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/editors/${id}`,
      BASIC_AUTH
    );
  });

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync();

      enqueue({
        message: "Usunięto pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push("/editors");
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    if (gamesData) gamesRefetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  const gamesColumns = React.useMemo(
    () => [
      {
        Header: "Tytuł",
        id: "originalTitle",
        Cell: ({ row }: { row: any }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/games/${row?.original?.id}`);
            }}
            href={`/games/${row?.original?.id}`}
          >
            {row?.original?.originalTitle}
          </StyledLink>
        ),
      },
      {
        Header: (
          <Block display="flex" alignItems="center" justifyContent="center">
            <ThumbUp color={theme.colors.positive} size="16" />
          </Block>
        ),
        accessor: "recommendedCount",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue>{cell?.value}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" alignItems="center" justifyContent="center">
            <ThumbDown color={theme.colors.negative} size="16" />
          </Block>
        ),
        accessor: "notRecommendedCount",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue>{cell?.value}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" alignItems="center" justifyContent="center">
            Ocena internautów
          </Block>
        ),
        accessor: "usersRating",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue dataType="date">{cell?.value}</FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <Block display="flex" alignItems="center" justifyContent="center">
            Ocena redakcji
          </Block>
        ),
        accessor: "editorRating",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue dataType="date">{cell?.value}</FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/games/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/games/${row?.original?.id}/edit`);
              }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.primary}
              size={SIZE.mini}
              $as="a"
              href={`/games/${row?.original?.id}`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/games/${row?.original?.id}`);
              }}
              $style={{ marginLeft: "10px" }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <article>
      <Header
        title={data?.nick}
        labels={["Redaktorzy", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            onClick: () => history.push(`/editors/${id}/edit`),
          },
          {
            label: "Podgląd",
            kind: KIND.secondary,
            $as: "a",
            target: "_blank",
            href: `${process.env.REACT_APP_URL}api/preview?secret=${data?.previewToken}&slug=/redakcja/${data?.slug}`,
            startEnhancer: <Eye size={18} />,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {EDITORS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => {
                return (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl label={item.label} disabled={true}>
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="20px"
                          width="100%"
                          animation
                        />
                      ) : item.id.includes("socialMedia") ? (
                        data &&
                        data?.socialMediaUrls &&
                        !!data?.socialMediaUrls[item.label.toLowerCase()] ? (
                          <StyledLink
                            href={
                              data.socialMediaUrls[item.label.toLowerCase()]
                            }
                            target="_blank"
                          >
                            {data.socialMediaUrls[item.label.toLowerCase()]}
                          </StyledLink>
                        ) : (
                          <FormattedValue />
                        )
                      ) : (
                        <FormattedValue dataType={item.dataType}>
                          {item.show.accessor
                            ? data &&
                              data[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                            : item.id === "isVisible"
                            ? data && !data[item.id]
                            : data && data[item.id]}
                        </FormattedValue>
                      )}
                    </FormControl>
                  </Cell>
                );
              }),
          ])}

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Ulubione gry
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              <Table<Game>
                columns={gamesColumns}
                data={data?.favouriteGames}
                noOffset
              />
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Rozważane gry
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              <Table<Game>
                columns={gamesColumns}
                data={gamesData?.consideredGamesData}
                noOffset
              />
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Posiadane gry
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              <Table<Game>
                columns={gamesColumns}
                data={gamesData?.ownedGamesData}
                noOffset
              />
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Aktualnie grane gry
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              <Table<Game>
                columns={gamesColumns}
                data={gamesData?.playedGamesData}
                noOffset
              />
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Ukończone gry
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              <Table<Game>
                columns={gamesColumns}
                data={gamesData?.completedGamesData}
                noOffset
              />
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Avatar
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : data?.avatarUrl ? (
              <img
                src={data?.avatarUrl}
                alt="banner"
                className={css({ maxHeight: "200px" })}
              />
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  color: "#999999",
                })}
              >
                <>
                  <Minus
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Brak
                </>
              </div>
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Tło
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : data?.backgroundImageUrl ? (
              <img
                src={data?.backgroundImageUrl}
                alt="banner"
                className={css({ maxHeight: "200px" })}
              />
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  color: "#999999",
                })}
              >
                <>
                  <Minus
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Brak
                </>
              </div>
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Usuń redaktora",
                  icon: Trash,
                  color: theme.colors.primary,
                  onClick: () => setIsConfirmDialogOpen(true),
                },
              ]}
            />
          </Cell>
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie redaktora ${data?.nick}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
