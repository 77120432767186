import {
  FormControl as BaseFormControl,
  FormControlProps,
} from "baseui/form-control";
import React from "react";

type Props = {
  required?: boolean;
} & FormControlProps;

const FormControl = ({ required, ...rest }: Props): React.ReactElement => {
  return (
    <BaseFormControl {...rest} {...(required && { label: rest.label + "*" })} />
  );
};

export default FormControl;
