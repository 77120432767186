import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  name: string;
  color: string;
};

export const LABELS_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "date",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "date",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "color",
    label: "Kolor",
    fields: [
      {
        id: "color",
        label: "",
        type: FIELDS.ColorPicker,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "color",
        label: "",
        type: FIELDS.Input,
        dataType: "pre",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
