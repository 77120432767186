import {
  API,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

type TweetBlockEditorProps = {
  initialData: TweetData;
  onChange(data: TweetData): void;
  isReadOnly: boolean;
};

function TweetBlockEditor({
  initialData,
  onChange,
  isReadOnly,
}: TweetBlockEditorProps) {
  const [data, setData] = useState<TweetData>(initialData);

  useEffect(() => onChange(data), [data]);

  return (
    <input
      value={data.url}
      onChange={(event) =>
        setData((data) => ({
          ...data,
          url: (event.target as HTMLInputElement).value,
        }))
      }
      disabled={isReadOnly}
      placeholder="Link do tweeta"
      style={{
        width: "100%",
        padding: "10px 12px",
        backgroundColor: "transparent",
        borderRadius: "3px",
        outline: "none",
        fontFamily: "inherit",
        fontSize: "16px",
        boxSizing: "border-box",
        border: "1px solid rgba(201,201,204,.48)",
      }}
    />
  );
}

interface TweetData extends BlockToolData {
  url: string;
}

export default class Tweet implements BlockTool {
  public static isReadOnlySupported = true;

  private data: TweetData;
  private isReadOnly: boolean;
  private api: API;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Tweet",
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>',
    };
  }

  constructor({ data, readOnly, api }: BlockToolConstructorOptions<TweetData>) {
    this.data = data;
    this.isReadOnly = readOnly;
    this.api = api;
  }

  validate(data: TweetData): boolean {
    return !!data.url.trim();
  }

  render(): HTMLElement {
    const root = document.createElement("div");
    root.classList.add(this.api.styles.block);

    ReactDOM.render(
      <TweetBlockEditor
        initialData={this.data}
        isReadOnly={this.isReadOnly}
        onChange={(data) => (this.data = data)}
      />,
      root
    );

    return root;
  }

  save(): TweetData {
    return this.data;
  }
}
