import { useStyletron } from "baseui";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "../contexts/auth-context";
import Loading from "./loading";
import SideNav from "./side-nav";

type LayoutProps = {
  children?: React.ReactNode;
};

export default function Layout({ children }: LayoutProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const location = useLocation();
  const { refetch } = useAuth();

  useEffect(() => {
    //refetch();
  }, [location]);

  return (
    <main className={css({ display: "flex", height: "100vh" })}>
      <aside
        className={css({
          width: "30%",
          maxWidth: "300px",
          minWidth: "250px",
          flexShrink: 0,
        })}
      >
        <SideNav />
      </aside>
      <section
        className={css({
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          position: "relative",
          paddingBottom: theme.sizing.scale1600,
        })}
      >
        <Loading />
        {children}
      </section>
    </main>
  );
}
