import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, Pencil, Trash } from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import Editor from "../../../components/editor";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ObjectTag from "../../../components/object-tag";
import { BASIC_AUTH, PEGI_CONTENT_DESCRIPTORS } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Tag } from "../../Tags/tags";
import { GAMES_FIELDS } from "../games.form";

export default function GamesShow(): React.ReactElement {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/games/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data, isError, refetch } = useQuery(`gameShow${id}`, getData);

  const { mutateAsync } = useMutation("gameDelete", async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/games/${id}`,
      BASIC_AUTH
    );
  });

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync();

      enqueue({
        message: "Usunięto pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push("/games");
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title={data?.originalTitle}
        labels={["Gry", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            onClick: () => history.push(`/games/${id}/edit`),
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {GAMES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.type === FIELDS.Editor ? (
                      <Editor placeholder={false} readOnly data={data?.body} />
                    ) : (
                      <FormattedValue dataType={item.dataType}>
                        {item.show.accessor ? (
                          data &&
                          data[item.show.accessor[0]]?.[item.show.accessor[1]]
                        ) : item.id.toLowerCase().includes("tags") ? (
                          data && !!data[item.id]?.length ? (
                            <Block
                              display="flex"
                              alignItems="center"
                              marginRight="10px"
                            >
                              {data[item.id]?.map((tag: Tag) => (
                                <ObjectTag key={`tag-${tag.id}`}>
                                  {tag.name}
                                </ObjectTag>
                              ))}
                            </Block>
                          ) : (
                            <FormattedValue />
                          )
                        ) : data &&
                          data[item.id] &&
                          Array.isArray(data[item.id]) &&
                          data[item.id]?.length > 0 ? (
                          <Block
                            display="flex"
                            alignItems="center"
                            marginRight="10px"
                          >
                            {data[item.id]?.map((item: string) => (
                              <ObjectTag key={`item-${item}`}>
                                {
                                  PEGI_CONTENT_DESCRIPTORS.find(
                                    (cD) => cD.id === item
                                  )?.label
                                }
                              </ObjectTag>
                            ))}
                          </Block>
                        ) : (
                          data && data[item.id]
                        )}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Usuń grę",
                  icon: Trash,
                  color: theme.colors.primary,
                  onClick: () => setIsConfirmDialogOpen(true),
                },
              ]}
            />
          </Cell>

          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie gry ${data?.originalTitle}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
