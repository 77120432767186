import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertCircle,
  Check,
  Eye,
  Minus,
  Pencil,
  Trash,
} from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import Editor from "../../../components/editor";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ObjectTag from "../../../components/object-tag";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Tag } from "../../Tags/tags";
import { VIDEOS_FIELDS } from "../videos.form";

export default function VideosShow(): React.ReactElement {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/videos/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data, isError, refetch } = useQuery(`videoShow${id}`, getData);

  const { mutateAsync } = useMutation("videoDelete", async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/videos/${id}`,
      BASIC_AUTH
    );
  });

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync();

      enqueue({
        message: "Usunięto pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push("/videos");
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title={data?.title}
        labels={["Wideo", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            $as: "a",
            href: `/videos/${id}/edit`,
            startEnhancer: <Pencil size={18} />,
            onClick: (event: MouseEvent) => {
              event.preventDefault();
              history.push(`/videos/${id}/edit`);
            },
          },
          {
            label: "Podgląd",
            kind: KIND.secondary,
            $as: "a",
            target: "_blank",
            href: `${process.env.REACT_APP_URL}api/preview?secret=${data?.previewToken}&slug=/wideo/${data?.slug}`,
            startEnhancer: <Eye size={18} />,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {data?.tags && (
            <Cell span={12}>
              <Block marginBottom="10px">
                {data.tags.map((tag: Tag) => (
                  <ObjectTag key={`tag-${tag.id}`}>{tag.name}</ObjectTag>
                ))}
              </Block>
            </Cell>
          )}
          {VIDEOS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.type === FIELDS.Editor ? (
                      <Editor placeholder={false} readOnly data={data?.body} />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "games" ? data?.gameId : data?.[item.id]
                        }
                      >
                        {item.show.accessor
                          ? data &&
                            data[item.show.accessor[0]]?.[item.show.accessor[1]]
                          : data && data[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Miniatura
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            {data?.thumbnailUrl ? (
              <img
                src={data?.thumbnailUrl}
                alt="banner"
                className={css({ maxHeight: "400px" })}
              />
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  color: "#999999",
                })}
              >
                <>
                  <Minus
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Brak
                </>
              </div>
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Usuń wideo",
                  icon: Trash,
                  color: theme.colors.primary,
                  onClick: () => setIsConfirmDialogOpen(true),
                },
              ]}
            />
          </Cell>

          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie wideo ${data?.title}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
