import { useStyletron } from "baseui";
import { DatePicker, DatepickerProps } from "baseui/datepicker";
import { FormControl } from "baseui/form-control";
import { SelectProps } from "baseui/select";
import { TimePicker } from "baseui/timepicker";
import pl from "date-fns/locale/pl";
import React from "react";
import { StyleObject } from "styletron-react";
import { ChevronDown } from "tabler-icons-react";

type Props = {
  $style?: StyleObject;
  isDisabled?: boolean;
  date: Date | null;
  setDate: (date: Date) => void;
} & DatepickerProps &
  SelectProps;

export default function DateTimePicker({
  date = null,
  setDate,
  $style,
  isDisabled,
  ...rest
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <div className={css({ display: "flex", flexGrow: 3 })}>
      <div
        className={css({
          marginRight: theme.sizing.scale300,
          flexGrow: 2,
        })}
      >
        <FormControl label="Data">
          <DatePicker
            value={date}
            onChange={({ date }) => setDate(date as Date)}
            locale={pl}
            formatString="dd.MM.yyyy"
            placeholder="DD.MM.RRRR"
            clearable
            overrides={{
              Popover: {
                props: {
                  overrides: {
                    Body: {
                      style: {
                        zIndex: 300,
                      },
                    },
                  },
                },
              },
              MonthYearSelectPopover: {
                props: {
                  overrides: {
                    Body: {
                      style: {
                        zIndex: 301,
                      },
                    },
                  },
                },
              },
              CalendarHeader: {
                style: {
                  backgroundColor: theme.colors.primary,
                },
              },
              Input: {
                props: {
                  overrides: {
                    Root: {
                      style: () => ({
                        borderTopLeftRadius: theme.borders.radius300,
                        borderTopRightRadius: theme.borders.radius300,
                        borderBottomRightRadius: theme.borders.radius300,
                        borderBottomLeftRadius: theme.borders.radius300,
                        ...$style,
                      }),
                    },
                  },
                  disabled: isDisabled,
                },
              },
              MonthHeader: {
                style: {
                  backgroundColor: theme.colors.backgroundTertiary,
                  color: "#000",
                },
              },
            }}
            {...rest}
          />
        </FormControl>
      </div>
      <div
        className={css({
          marginRight: theme.sizing.scale300,
          flexGrow: 1,
        })}
      >
        <FormControl label="Godzina">
          <TimePicker
            value={date}
            onChange={setDate}
            creatable
            format="24"
            overrides={{
              Select: {
                props: {
                  overrides: {
                    Root: {
                      style: {
                        ...$style,
                      },
                    },
                    ControlContainer: {
                      style: {
                        borderTopLeftRadius: theme.borders.radius300,
                        borderTopRightRadius: theme.borders.radius300,
                        borderBottomRightRadius: theme.borders.radius300,
                        borderBottomLeftRadius: theme.borders.radius300,
                        transitionDuration: "100ms",
                      },
                    },
                    LoadingIndicator: {
                      props: {
                        overrides: {
                          ActivePath: {
                            style: {
                              fill: theme.colors.borderSelected,
                            },
                          },
                          Svg: {
                            style: {
                              height: "16px",
                              marginRight: "5px",
                            },
                          },
                        },
                      },
                    },
                    SelectArrow: {
                      props: {
                        overrides: {
                          Svg: () => (
                            <ChevronDown
                              color={theme.colors.borderFocus}
                              size={18}
                            />
                          ),
                        },
                      },
                    },
                    Dropdown: {
                      style: {
                        boxShadow: "none",
                        borderTopLeftRadius: theme.borders.radius300,
                        borderTopRightRadius: theme.borders.radius300,
                        borderBottomRightRadius: theme.borders.radius300,
                        borderBottomLeftRadius: theme.borders.radius300,
                        backgroundColor: theme.colors.inputFill,
                        borderLeftWidth: "2px",
                        borderLeftStyle: "solid",
                        borderLeftColor: theme.colors.borderSelected,
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        borderBottomColor: theme.colors.borderSelected,
                        borderRightWidth: "2px",
                        borderRightStyle: "solid",
                        borderRightColor: theme.colors.borderSelected,
                        borderTopWidth: "2px",
                        borderTopStyle: "solid",
                        borderTopColor: theme.colors.borderSelected,
                      },
                    },
                    Popover: {
                      props: {
                        overrides: {
                          Body: {
                            style: {
                              zIndex: 200,
                              boxShadow: "none",
                              marginLeft: "1px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              backgroundColor: "transparent",
                            },
                          },
                          Inner: {
                            style: {
                              backgroundColor: "transparent",
                            },
                          },
                        },
                      },
                    },
                    StatefulMenu: {
                      props: {
                        overrides: {
                          EmptyState: {
                            style: {
                              color: "#999999",
                              paddingTop: theme.sizing.scale300,
                              paddingBottom: theme.sizing.scale300,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            }}
          />
        </FormControl>
      </div>
    </div>
  );
}
