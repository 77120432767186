import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  file?: File[];
  alt?: string;
  originalName?: string;
  tags?: { id: number; label?: string }[];
  tagIds?: number[] | [];
};

export const MEDIA_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "file",
        label: "Plik",
        type: FIELDS.FilesPicker,
        span: 5,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false, required: true },
      },
      {
        id: "originalName",
        label: "Nazwa pliku",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: false, required: true },
        edit: { visible: false, required: true },
      },
      {
        id: "alt",
        label: "Opis pliku",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: false },
        edit: { visible: true, required: false },
      },
      {
        id: "size",
        label: "Rozmiar pliku",
        type: FIELDS.NumberInput,
        dataType: "size",
        span: 3,
        show: { visible: true },
        create: { visible: false, required: true },
        edit: { visible: false, required: true },
      },
      {
        id: "mimetype",
        label: "Typ",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false, required: true },
        edit: { visible: false, required: true },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "tags",
        label: "Tagi",
        dataType: "set:tags",
        type: FIELDS.TagsSelect,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
