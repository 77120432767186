import axios from "axios";
import { useSnackbar } from "baseui/snackbar";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Check } from "tabler-icons-react";

import { client } from "..";
import Loading from "../components/loading";
import { AUTH_TOKEN, BASIC_AUTH } from "../constants";
import { User } from "../containers/Users/users";
import { SnackbarSuccess } from "../utils/snackbarTypes";
import { useLoading } from "./loading-context";

type AuthContextProps = {
  user: User | null;
  setUser: (user: User) => void;
  logout: () => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (authenticated: boolean) => void;
  refetch: () => void;
  checkPermission: (componentPermission: string) => boolean;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [
    isFetchingAuthenticatedUser,
    setIsFetchingAuthenticatedUser,
  ] = useState(true);

  const { setIsFetching } = useLoading();
  const { enqueue } = useSnackbar();

  const accessToken =
    typeof window !== "undefined" && localStorage?.getItem(AUTH_TOKEN);

  const getUserData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/account`,
      {
        headers: {
          "user-authorization": accessToken,
        },
        ...BASIC_AUTH,
      }
    );

    return data;
  };

  const { isSuccess, data, refetch, error } = useQuery(
    `userAccount`,
    getUserData,
    {
      enabled: !!accessToken,
      retry: false,
    }
  );

  useEffect(() => {
    if (accessToken) {
      setIsAuthenticated(true);
      axios.defaults.headers.common["user-authorization"] = accessToken;
    } else {
      setIsAuthenticated(false);
      axios.defaults.headers.common["user-authorization"] = null;
    }
  }, [accessToken, isAuthenticated]);

  useEffect(() => {
    if (isSuccess) {
      setUser(data);
      setIsAuthenticated(true);
      setIsFetchingAuthenticatedUser(false);
      setIsFetching(false);
    }

    if (error) {
      localStorage.removeItem(AUTH_TOKEN);
      setIsFetchingAuthenticatedUser(false);
      setIsFetching(false);
    }
  }, [error, isSuccess]);

  useEffect(() => {
    if (accessToken) {
      setIsFetching(true);
    } else {
      setIsFetchingAuthenticatedUser(false);
    }
  }, []);

  if (isFetchingAuthenticatedUser) {
    return <Loading />;
  }

  function logout() {
    localStorage.removeItem(AUTH_TOKEN);
    client.getQueryCache().clear();

    setUser(null);
    setIsAuthenticated(false);

    enqueue({
      message: "Wylogowano pomyślnie",
      overrides: SnackbarSuccess,
      startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
    });
  }

  function checkPermission(_componentPermission: string) {
    // TODO: zaktualizować funkcję do uprawnień
    return true;
    // return (
    //   !!user &&
    //   !!user.permissions &&
    //   user.permissions.some(
    //     (permission: any) => permission.systemName === componentPermission
    //   )
    // );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        logout,
        isAuthenticated,
        setIsAuthenticated,
        refetch,
        checkPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthContextProps => useContext(AuthContext);
