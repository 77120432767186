import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import React, { MouseEvent, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { AlertCircle, Plus } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError } from "../../../utils/snackbarTypes";
import { Label } from "../labels";

export default function LabelsIndex(): React.ReactElement {
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueue } = useSnackbar();
  const { isFetching, setIsFetching } = useLoading();

  const { isError, data, refetch } = useQuery(
    "labels",
    async () =>
      (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/labels`,
          BASIC_AUTH
        )
      ).data
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        id: "name",
        Cell: ({ row }: { row: any }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/labels/${row?.original?.id}`);
            }}
            href={`/labels/${row?.original?.id}`}
          >
            <FormattedValue showBlankWhenEmpty>
              {row.original.name}
            </FormattedValue>
          </StyledLink>
        ),
      },
      {
        Header: "Kolor",
        id: "color",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              width: "80px",
              height: "28px",
              backgroundColor: row.original.color,
              borderRadius: "5px",
              marginRight: "8px",
            })}
          />
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/labels/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/labels/${row?.original?.id}/edit`);
              }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/labels/${row?.original?.id}`}
              $style={{ marginLeft: "10px" }}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/labels/${row?.original?.id}`);
              }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title="Etykiety"
        recordsNum={data?.length}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj etykietę",
            kind: KIND.secondary,
            $as: "a",
            href: "/labels/create",
            startEnhancer: <Plus size={18} />,
            onClick: (event: MouseEvent) => {
              event.preventDefault();
              history.push("/labels/create");
            },
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && <Table<Label> columns={columns} data={data} noOffset />
            )}
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
