import { OutputData } from "@editorjs/editorjs";

import { Tag } from "../Tags/tags";

export type Video = {
  id: number;
  title: string;
  slug: string;
  lead: string;
  type: "YouTube" | "Twitch" | "Facebook" | "Iframe";
  source: string;
  duration: number;
  thumbnailUrl: string;
  isFeaturedOnHomePage: boolean;
  isBlockedFromHomePage: boolean;
  tags: Tag[];
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  body: OutputData[];
  previewToken: string;
};

export enum BRAND {
  CDAction = "CDAction",
  PCFormat = "PCFormat",
}
