import {
  API,
  BlockAPI,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";

import { useImageLibrary } from "../../../contexts/image-library-context";

interface ImageData extends BlockToolData {
  url?: string;
  caption?: string;
  alt?: string;
  isStretched?: boolean;
  href?: string;
  target?: string;
  rel?: string[];
}

interface ImageConfig {
  imageLibraryContext: ReturnType<typeof useImageLibrary>;
}

type ImageNodes = {
  button?: HTMLButtonElement;
  image?: HTMLImageElement;
  caption?: HTMLDivElement;
  alt?: HTMLDivElement;
  copyrights?: HTMLDivElement;
  href?: HTMLDivElement;
  linkOptions?: HTMLDivElement;
};

type ImageSetting = {
  icon: string;
  name: ImageSettingName;
};

enum ImageSettingName {
  IsStretched = "isStretched",
}

export default class Image implements BlockTool {
  public static isReadOnlySupported = true;
  public static enableLineBreaks = true;
  public static sanitize = {
    url: false,
    isStretched: false,
    caption: {},
    alt: {},
    copyrights: {},
    href: {},
    target: {},
    rel: {},
  };

  private _data: ImageData;
  private isReadOnly: boolean;
  private api: API;
  private config?: ImageConfig;
  private nodes: ImageNodes;
  private block?: BlockAPI;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Zdjęcie",
      // TODO: Find appropriate icon
      icon:
        '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  constructor({
    data,
    readOnly,
    api,
    config,
    block,
  }: BlockToolConstructorOptions<ImageData, ImageConfig>) {
    this.isReadOnly = readOnly;
    this.api = api;
    this.config = config;
    this.block = block;

    this.nodes = {
      button: undefined,
      image: undefined,
      caption: undefined,
      alt: undefined,
      copyrights: undefined,
      href: undefined,
      linkOptions: undefined,
    };

    this._data = {
      ...data,
      isStretched: !!data.isStretched,
    };
  }

  get settings(): ImageSetting[] {
    return [
      {
        icon: `<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>`,
        name: ImageSettingName.IsStretched,
      },
    ];
  }

  get data(): ImageData {
    return this._data;
  }

  set data(data: ImageData) {
    this._data = { ...this.data, ...data };

    if (this.block) {
      this.block.stretched = !!data.isStretched;
    }

    if (data.url) {
      if (this.nodes.image) {
        this.nodes.image.src = data.url;
        this.nodes.image.style.display = "block";
      }

      if (this.nodes.caption) {
        this.nodes.caption.style.display = "block";
      }

      if (this.nodes.alt) {
        this.nodes.alt.style.display = "block";
      }

      if (this.nodes.copyrights) {
        this.nodes.copyrights.style.display = "block";
      }

      if (this.nodes.href) {
        this.nodes.href.style.display = "block";
      }

      if (this.nodes.linkOptions) {
        this.nodes.linkOptions.style.display = "block";
      }

      if (this.nodes.button) {
        this.nodes.button.style.display = "none";
      }
    } else {
      if (this.nodes.image) {
        this.nodes.image.src = "";
        this.nodes.image.style.display = "none";
      }

      if (this.nodes.caption) {
        this.nodes.caption.style.display = "none";
      }

      if (this.nodes.alt) {
        this.nodes.alt.style.display = "none";
      }

      if (this.nodes.copyrights) {
        this.nodes.copyrights.style.display = "none";
      }

      if (this.nodes.href) {
        this.nodes.href.style.display = "none";
      }

      if (this.nodes.linkOptions) {
        this.nodes.linkOptions.style.display = "none";
      }

      if (this.nodes.button) {
        this.nodes.button.style.display = "block";
      }
    }
  }

  render(): HTMLElement {
    const container = document.createElement("div");
    container.classList.add(this.api.styles.block);
    container.style.textAlign = "center";

    const openImageLibrary = (event: Event) => {
      event.preventDefault();

      this.config?.imageLibraryContext.open((image) => {
        const url = `${process.env.REACT_APP_CDN_URL}/images/${image.path}`;

        this.data = {
          ...this.data,
          url,
        };

        this.api.blocks.insert(undefined, undefined, undefined, Infinity);
        this.api.blocks.delete(Infinity);
      });
    };

    const button = document.createElement("button");
    button.classList.add(this.api.styles.button);
    button.innerHTML = "Wgraj lub wybierz z biblioteki";
    button.addEventListener("click", openImageLibrary);

    const image = document.createElement("img");
    image.classList.add(this.api.styles.block, "image");
    image.style.width = "100%";

    const caption = document.createElement("div");
    caption.classList.add(this.api.styles.input, "caption");
    caption.contentEditable = String(!this.isReadOnly);
    caption.innerHTML = this.data.caption ?? "";
    caption.style.textAlign = "left";
    caption.dataset.placeholder = "Tytuł obrazka";

    const alt = document.createElement("div");
    alt.classList.add(this.api.styles.input, "alt");
    alt.contentEditable = String(!this.isReadOnly);
    alt.innerHTML = this.data.alt ?? "";
    alt.style.textAlign = "left";
    alt.dataset.placeholder = "Opis obrazka (alt)";

    const copyrights = document.createElement("div");
    copyrights.classList.add(this.api.styles.input, "copyrights");
    copyrights.contentEditable = String(!this.isReadOnly);
    copyrights.innerHTML = this.data.copyrights ?? "";
    copyrights.style.textAlign = "left";
    copyrights.dataset.placeholder = "Prawa autorskie";

    const href = document.createElement("div");
    href.classList.add(this.api.styles.input, "href");
    href.contentEditable = String(!this.isReadOnly);
    href.innerHTML = this.data.href ?? "";
    href.style.textAlign = "left";
    href.dataset.placeholder = "URL (opcjonalnie)";

    const linkOptions = document.createElement("div");
    linkOptions.classList.add(this.api.styles.input);

    const targetOptions = ["_blank", "_self"];
    const target = document.createElement("select");
    target.classList.add(this.api.styles.input, "target");
    target.onchange = () => {
      this.data.target = Array.from(target.selectedOptions)
        .map((el) => (el as HTMLOptionElement).value)
        .join("");
      this.update();
    };

    for (let i = 0; i < targetOptions.length; i++) {
      const option = document.createElement("option");
      option.value = targetOptions[i];
      option.text = targetOptions[i];
      option.selected = !!this.data.target?.includes(targetOptions[i]);
      target.appendChild(option);
    }

    const relOptions = ["nofollow", "dofollow", "noreferrer", "author"];
    const rel = document.createElement("select");
    rel.multiple = true;
    rel.classList.add(this.api.styles.input, "rel");
    rel.onchange = () => {
      this.data.rel = Array.from(rel.selectedOptions).map(
        (el) => (el as HTMLOptionElement).value
      );
      this.update();
    };

    for (let i = 0; i < relOptions.length; i++) {
      const option = document.createElement("option");
      option.value = relOptions[i];
      option.text = relOptions[i];
      option.selected = !!this.data.rel?.includes(relOptions[i]);
      rel.appendChild(option);
    }

    linkOptions.appendChild(target);
    linkOptions.appendChild(rel);

    if (this.data.url) {
      image.src = this.data.url;
      button.style.display = "none";
    } else {
      image.style.display = "none";
      caption.style.display = "none";
      alt.style.display = "none";
      copyrights.style.display = "none";
      href.style.display = "none";
      linkOptions.style.display = "none";
    }

    container.appendChild(button);
    container.appendChild(image);
    container.appendChild(caption);
    container.appendChild(alt);
    container.appendChild(copyrights);
    container.appendChild(href);
    container.appendChild(linkOptions);

    this.nodes.button = button;
    this.nodes.image = image;
    this.nodes.caption = caption;
    this.nodes.alt = alt;
    this.nodes.copyrights = copyrights;
    this.nodes.href = href;
    this.nodes.linkOptions = linkOptions;

    // If the stretched prop is set without setTimeout, the block doesn't render
    setTimeout(() => {
      if (this.block) {
        this.block.stretched = !!this.data.isStretched;
      }
    }, 0);

    return container;
  }

  renderSettings(): HTMLElement {
    const wrapper = document.createElement("div");
    const capitalize = (str: string) => str[0].toUpperCase() + str.substr(1);

    this.settings
      .map((setting: ImageSetting) => {
        const el = document.createElement("div");

        el.classList.add(this.api.styles.settingsButton);
        el.innerHTML = setting.icon;
        el.title = `${capitalize(setting.name)}`;

        el.classList.toggle(
          this.api.styles.settingsButtonActive,
          this.data.isStretched ?? false
        );

        wrapper.appendChild(el);

        return el;
      })
      .forEach((element, index, elements) => {
        element.addEventListener("click", () => {
          this.toggleIsStretched();

          elements.forEach((el) => {
            el.classList.toggle(
              this.api.styles.settingsButtonActive,
              this.data.isStretched ?? false
            );
          });
        });
      });

    return wrapper;
  }

  private toggleIsStretched(): void {
    this.data.isStretched = !this.data.isStretched;
    if (this.block) {
      this.block.stretched = !!this.data.isStretched;
    }

    this.update();
  }

  private update(): void {
    this.api.blocks.update(
      (this.api.blocks.getBlockByIndex(
        this.api.blocks.getCurrentBlockIndex()
      ) as BlockAPI)?.id,
      this.data
    );
  }

  save(rootElement: HTMLDivElement): ImageData {
    const caption = rootElement.querySelector(".caption")?.innerHTML || "";
    const alt = rootElement.querySelector(".alt")?.innerHTML || "";
    const copyrights =
      rootElement.querySelector(".copyrights")?.innerHTML || "";
    const href = rootElement.querySelector(".href")?.innerHTML || "";
    const target =
      (rootElement.querySelector(".target") as HTMLSelectElement)?.value || "";

    const selected = rootElement.querySelectorAll(".rel option:checked");
    const rel = Array.from(selected).map(
      (el) => (el as HTMLOptionElement).value
    );

    return {
      ...this.data,
      caption,
      alt,
      copyrights,
      href,
      target,
      rel,
    };
  }
}
