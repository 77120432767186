import { createTheme } from "baseui";

const primitives = {
  primaryFontFamily: "'Inter', sans-serif",
};

const overrides = {
  colors: {
    primary: "#A71C16",
    backgroundTertiary: "#f5f5f5",
    contentAccent: "#A71C16",
    buttonPrimaryFill: "#A71C16",
    buttonPrimaryHover: "#820600",
    buttonPrimaryActive: "#820600",
    buttonPrimarySpinnerForeground: "white",
    buttonPrimarySpinnerBackground: "rgba(255,255,255,0.6)",
    backgroundInverseSecondary: "#A71C16",
    inputFill: "#f9f9f9",
    inputBorder: "#eeeeee",
    progressbarTrackFill: "#f9f9f9",
    tagPrimarySolidBackground: "#eeeeee",
    tagPrimarySolidFont: "#000000",
    tagPrimaryOutlinedFont: "#000000",
    tagPrimaryOutlinedBackground: "#eeeeee",
  },
  typography: {
    LabelSmall: {
      fontWeight: 400,
    },
  },
};

export default createTheme(primitives, overrides);
