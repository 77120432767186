import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, DeviceFloppy, FileOff } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import Content from "../../../components/content";
import { ControlledDatePicker } from "../../../components/date-picker";
import { ControlledEditor } from "../../../components/editor";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ImagePreview, {
  ImagePreviewSize,
} from "../../../components/image-preview";
import { ControlledInput } from "../../../components/input";
import {
  ControlledPegiAgeRatingSelect,
  ControlledPegiContentDescriptorsMultiSelect,
  ControlledTagsSelect,
} from "../../../components/select";
import { ControlledTextArea } from "../../../components/text-area";
import { BASIC_AUTH } from "../../../constants";
import { useImageLibrary } from "../../../contexts/image-library-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Tag } from "../../Tags/tags";
import { FormInputs, GAMES_FIELDS } from "../games.form";

export default function GamesEdit(): React.ReactElement {
  const [isSlugChangedByUser, setIsSlugChangedByUser] = useState(false);
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const watchOriginalTitle = watch("originalTitle");

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/games/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: queryData, refetch } = useQuery(
    `game${id}CurrentData`,
    getData,
    {
      cacheTime: 0,
    }
  );

  const editData = async (values: FormInputs) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/games/${id}`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("gameEdit", editData);

  const onSubmit = async ({
    originalTitle,
    slug,
    altTitles,
    polishTitle,
    boxCoverUrl,
    editorRating,
    pcPlatformRating,
    ps4PlatformRating,
    ps5PlatformRating,
    xsxPlatformRating,
    xoPlatformRating,
    switchPlatformRating,
    usersRating,
    intro,
    description,
    systemRequirements,
    expectation,
    ownersCount,
    recommendedCount,
    notRecommendedCount,
    wishToPlayCount,
    tags,
    genreTags,
    multiplayerTags,
    platformTags,
    developerTags,
    publisherTags,
    languageVersionTags,
    pegiAgeRatingSelect,
    pegiContentDescriptorsSelect,
    body,
    isFeatured,
    releasedAt,
    trackingCode,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    const platformRatings = {
      pcPlatformRating,
      ps4PlatformRating,
      ps5PlatformRating,
      xsxPlatformRating,
      xoPlatformRating,
      switchPlatformRating,
    };

    try {
      await mutateAsync({
        originalTitle,
        slug,
        altTitles,
        polishTitle,
        boxCoverUrl,
        editorRating,
        platformRatings,
        usersRating,
        intro,
        description,
        systemRequirements,
        expectation,
        ownersCount,
        recommendedCount,
        notRecommendedCount,
        wishToPlayCount,
        tagIds: tags && tags.map(({ id }) => id),
        genreTagIds: genreTags && genreTags.map(({ id }) => id),
        multiplayerTagIds:
          multiplayerTags && multiplayerTags.map(({ id }) => id),
        platformTagIds: platformTags && platformTags.map(({ id }) => id),
        developerTagIds: developerTags && developerTags.map(({ id }) => id),
        publisherTagIds: publisherTags && publisherTags.map(({ id }) => id),
        languageVersionTagIds:
          languageVersionTags && languageVersionTags.map(({ id }) => id),
        pegiAgeRating: pegiAgeRatingSelect && pegiAgeRatingSelect[0]?.id,
        pegiContentDescriptors:
          pegiContentDescriptorsSelect &&
          pegiContentDescriptorsSelect.map(({ id }) => id),
        body,
        isFeatured,
        releasedAt,
        trackingCode,
      });

      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/games/${id}`);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    setIsSlugChangedByUser(false);
    if (queryData) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (queryData) {
      setIsFetching(false);
    }
  }, [queryData]);

  useEffect(() => {
    if (queryData) {
      setValue("originalTitle", queryData?.originalTitle);
      setValue("slug", queryData?.slug);
      setValue("altTitles", queryData?.altTitles);
      setValue("polishTitle", queryData?.polishTitle);
      setValue("intro", queryData?.intro);
      setValue("description", queryData?.description);
      setValue("systemRequirements", queryData?.systemRequirements);
      setValue("expectation", queryData?.expectation);
      queryData?.tags &&
        setValue(
          "tags",
          queryData?.tags?.map((tag: Tag) => ({ id: tag.id, label: tag.name }))
        );
      queryData?.genreTags &&
        setValue(
          "genreTags",
          queryData?.genreTags?.map((tag: Tag) => ({
            id: tag.id,
            label: tag.name,
          }))
        );
      queryData?.multiplayerTags &&
        setValue(
          "multiplayerTags",
          queryData?.multiplayerTags?.map((tag: Tag) => ({
            id: tag.id,
            label: tag.name,
          }))
        );
      queryData?.platformTags &&
        setValue(
          "platformTags",
          queryData?.platformTags?.map((tag: Tag) => ({
            id: tag.id,
            label: tag.name,
          }))
        );
      queryData?.developerTags &&
        setValue(
          "developerTags",
          queryData?.developerTags?.map((tag: Tag) => ({
            id: tag.id,
            label: tag.name,
          }))
        );
      queryData?.publisherTags &&
        setValue(
          "publisherTags",
          queryData?.publisherTags?.map((tag: Tag) => ({
            id: tag.id,
            label: tag.name,
          }))
        );
      queryData?.languageVersionTags &&
        setValue(
          "languageVersionTags",
          queryData?.languageVersionTags?.map((tag: Tag) => ({
            id: tag.id,
            label: tag.name,
          }))
        );
      setValue("boxCoverUrl", queryData.boxCoverUrl);
      queryData?.pegiAgeRating &&
        setValue("pegiAgeRatingSelect", [{ id: queryData?.pegiAgeRating }]);
      queryData?.pegiContentDescriptors &&
        setValue(
          "pegiContentDescriptorsSelect",
          queryData?.pegiContentDescriptors?.map((id: string) => ({ id }))
        );
      setValue("body", queryData.body);
      queryData?.editorRating &&
        setValue("editorRating", queryData.editorRating);
      setValue(
        "pcPlatformRating",
        queryData?.platformRatings?.pcPlatformRating
      );
      setValue(
        "ps4PlatformRating",
        queryData?.platformRatings?.ps4PlatformRating
      );
      setValue(
        "ps5PlatformRating",
        queryData?.platformRatings?.ps5PlatformRating
      );
      setValue(
        "xsxPlatformRating",
        queryData?.platformRatings?.xsxPlatformRating
      );
      setValue(
        "xoPlatformRating",
        queryData?.platformRatings?.xoPlatformRating
      );
      setValue(
        "switchPlatformRating",
        queryData?.platformRatings?.switchPlatformRating
      );
      setValue("isFeatured", queryData.isFeatured);
      setValue("releasedAt", queryData.releasedAt);
      setValue("trackingCode", queryData.trackingCode);
    }
  }, [queryData]);

  const { open } = useImageLibrary();

  return (
    <article>
      <Header
        title={queryData?.originalTitle}
        labels={["Gry", "Edytowanie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () =>
              history.push(
                history.location.pathname.split("/").slice(0, -1).join("/")
              ),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {GAMES_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={item.caption}
                      required={item.edit.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="48px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={isLoading}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          <span
                            className={css({
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            })}
                          >
                            {item.label}
                          </span>
                        </ControlledCheckbox>
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.TagsSelect ? (
                        <ControlledTagsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          kind={item.typeName}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ImagePicker ? (
                        <>
                          {watch(item.id as keyof FormInputs) && (
                            <ImagePreview
                              image={{
                                path: watch(
                                  item.id as keyof FormInputs
                                ) as string,
                              }}
                              size={ImagePreviewSize.Large}
                            />
                          )}
                          <Button
                            type="button"
                            size="compact"
                            kind={KIND.secondary}
                            onClick={() =>
                              open((image) =>
                                setValue(
                                  item.id as keyof FormInputs,
                                  `${process.env.REACT_APP_CDN_URL}/images/${image.path}`
                                )
                              )
                            }
                            $style={{ marginTop: "10px" }}
                          >
                            Wybierz z biblioteki lub wgraj
                          </Button>
                        </>
                      ) : item.type === FIELDS.Editor ? (
                        <ControlledEditor control={control} name={item.id} />
                      ) : item.type === FIELDS.PegiAgeRatingSelect ? (
                        <ControlledPegiAgeRatingSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type ===
                        FIELDS.PegiContentDescriptorsMultiSelect ? (
                        <ControlledPegiContentDescriptorsMultiSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DatePicker ? (
                        <ControlledDatePicker
                          control={control}
                          name={item.id}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.dataType === "rating" && {
                            rules: {
                              max: {
                                value: 10,
                                message:
                                  formValidation.messages.incorrectRating,
                              },
                              min: {
                                value: 1,
                                message:
                                  formValidation.messages.incorrectRating,
                              },
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                          {...(item.id === "slug" && {
                            onKeyUp: () => setIsSlugChangedByUser(true),
                            $style: {
                              color: isSlugChangedByUser ? "#000" : "#999",
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
