import axios from "axios";
import { styled } from "baseui";
import { FormControl } from "baseui/form-control";
import { useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { Fragment, ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Check } from "tabler-icons-react";

import { BASIC_AUTH } from "../constants";
import { Image, Tag } from "../utils/models";
import { SnackbarSuccess } from "../utils/snackbarTypes";
import Button from "./button";
import FormattedValue from "./formatted-value";
import { ControlledInput } from "./input";
import { ControlledTagsSelect } from "./select";

type ImageDetailsProps = {
  image: Image;
  onChange?: () => void;
};

type FormInputs = {
  alt?: string;
  tags?: { id: number; label?: string }[];
  tagIds?: number[] | [];
};

const ImageThumbnail = styled("div", ({ $theme }) => ({
  backgroundColor: $theme.colors.backgroundTertiary,
  backgroundPosition: "center center",
  backgroundSize: "auto 90%",
  backgroundRepeat: "no-repeat",
  height: "200px",
}));

export function ImageDetails({
  image,
  onChange,
}: ImageDetailsProps): ReactElement {
  const updateImageMutation = useMutation(
    "imageEdit",
    async (values: FormInputs) => {
      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/admin/images/${image.id}`,
        values,
        BASIC_AUTH
      );
      return data;
    }
  );
  const { enqueue } = useSnackbar();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormInputs>();

  useEffect(() => {
    setValue("alt", image.alt ?? "");
    setValue(
      "tags",
      image.tags &&
        image.tags.map((tag: Tag) => ({ id: tag.id, label: tag.name }))
    );
  }, [image]);

  const onSubmit = async ({ alt, tags }: FormInputs): Promise<void> => {
    await updateImageMutation.mutateAsync({
      alt,
      tagIds: tags && tags.map(({ id }: { id: number }) => id),
    });

    onChange && onChange();

    enqueue({
      message: "Zapisano pomyślnie",
      overrides: SnackbarSuccess,
      startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
    });
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LabelMedium marginBottom="scale400">{image.originalName}</LabelMedium>

        <ImageThumbnail
          $style={{
            backgroundImage: `url(${process.env.REACT_APP_CDN_URL}/images/${image.path}?preset=thumbnail)`,
          }}
        />
        <FormControl label="Wielkość" disabled={true}>
          <FormattedValue dataType="size">{image.size}</FormattedValue>
        </FormControl>
        <FormControl label="Data utworzenia" disabled={true}>
          <FormattedValue dataType="date">{image.createdAt}</FormattedValue>
        </FormControl>
        <FormControl label="Opis" error={errors.alt && errors.alt.message}>
          <ControlledInput control={control} name="alt" error={!!errors.alt} />
        </FormControl>
        <FormControl label="Tagi" error={errors.tags && errors.tags.message}>
          <ControlledTagsSelect
            control={control}
            name="tags"
            error={!!errors.tags}
          />
        </FormControl>

        <Button type="submit">Zapisz</Button>
      </form>
    </Fragment>
  );
}
