import axios from "axios";
import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, CircleCheck, CircleX } from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { REPORTED_COMMENTS_FIELDS } from "../reported-comments.form";

export default function ReportedCommentsShow(): React.ReactElement {
  const [
    isAcceptReportedCommentConfirmDialogOpen,
    setIsAcceptReportedCommentConfirmDialogOpen,
  ] = useState(false);
  const [
    isRejectReportedCommentConfirmDialogOpen,
    setIsRejectReportedCommentConfirmDialogOpen,
  ] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id, commentId } = useParams<{ id?: string; commentId?: string }>();

  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/comments/${commentId}/reports/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data, isError, refetch } = useQuery(
    `reportCommentShow${id}`,
    getData
  );

  const { mutateAsync: acceptReportedComment } = useMutation(async () => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/comments/${commentId}/reports/${id}/accept`,
      {},
      BASIC_AUTH
    );
  });

  const { mutateAsync: rejectReportedComment } = useMutation(async () => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/comments/${commentId}/reports/${id}/reject`,
      {},
      BASIC_AUTH
    );
  });

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);
    try {
      isAcceptReportedCommentConfirmDialogOpen
        ? await acceptReportedComment()
        : await rejectReportedComment();

      enqueue({
        message: isAcceptReportedCommentConfirmDialogOpen
          ? "Zaakceptowano pomyślnie"
          : "Odrzucono pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      refetch();
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title={`Zgłoszony komentarz ${data?.id}`}
        labels={["Zgłoszone komentarze", "Wyświetlanie"]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -3).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {REPORTED_COMMENTS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data && data[item?.id]}
                      >
                        {item.id === "ipAddress" && data?.comment?.activityLogs
                          ? data.comment.activityLogs[
                              data.comment.activityLogs.length - 1
                            ].properties.client.ipAddress
                          : item.show.accessor
                          ? data &&
                            data[item.show.accessor[0]]?.[item.show.accessor[1]]
                          : data && data[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          {data?.status === "Pending" && (
            <>
              <Cell span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  Akcje
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
              <Cell span={12}>
                <ActionsList
                  actions={[
                    {
                      label: "Potwierdź zgłoszenie",
                      icon: CircleCheck,
                      color: theme.colors.positive,
                      onClick: () =>
                        setIsAcceptReportedCommentConfirmDialogOpen(true),
                    },
                    {
                      label: "Odrzuć zgłoszenie",
                      icon: CircleX,
                      color: theme.colors.negative,
                      onClick: () =>
                        setIsRejectReportedCommentConfirmDialogOpen(true),
                    },
                  ]}
                />
              </Cell>
            </>
          )}
          <ConfirmDialog
            isOpen={isAcceptReportedCommentConfirmDialogOpen}
            label={`Akceptacja zgłoszenia ${data?.id}`}
            close={() => setIsAcceptReportedCommentConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsAcceptReportedCommentConfirmDialogOpen(false);
            }}
          />
          <ConfirmDialog
            isOpen={isRejectReportedCommentConfirmDialogOpen}
            label={`Odrzucenie zgłoszenia ${data?.id}`}
            close={() => setIsRejectReportedCommentConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsRejectReportedCommentConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
