import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertCircle,
  Ban,
  Check,
  LockOff,
  LockOpen,
  Pencil,
  Trash,
} from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { BanUser } from "../users";
import { USERS_FIELDS } from "../users.form";

export default function UsersShow(): React.ReactElement {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isBanUserConfirmDialogOpen, setIsBanUserConfirmDialogOpen] = useState(
    false
  );

  const [
    isUnbanUserConfirmDialogOpen,
    setIsUnbanUserConfirmDialogOpen,
  ] = useState(false);
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/users/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data, isError, refetch } = useQuery(`userShow${id}`, getData);
  const isUserBanned = data?.bans.length > 0;

  const { mutateAsync } = useMutation("userDelete", async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/users/${id}`,
      BASIC_AUTH
    );
  });

  const { mutateAsync: unbanUser } = useMutation("unbanUser", async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/bans/${data.bans[0].id}`,
      BASIC_AUTH
    );
  });

  const { mutateAsync: banUser } = useMutation(async (values: BanUser) => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/bans`,
      values,
      BASIC_AUTH
    );
  });

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync();

      enqueue({
        message: "Usunięto pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push("/users");
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onBanSubmit = async (textareaValue?: string): Promise<void> => {
    setIsLoading(true);
    try {
      isBanUserConfirmDialogOpen
        ? await banUser({
            type: "Access",
            userId: Number(id),
            reason: textareaValue || "",
          })
        : await unbanUser();

      enqueue({
        message: isBanUserConfirmDialogOpen
          ? "Zbanowano pomyślnie"
          : "Odbanowano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      refetch();
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title={
          isUserBanned ? (
            <div
              className={css({
                display: "flex",
                alignItems: "center",
              })}
            >
              {data?.nickname}{" "}
              <span
                className={css({
                  color: theme.colors.primary,
                  display: "flex",
                  marginLeft: "6px",
                })}
              >
                <Ban />
              </span>
            </div>
          ) : (
            data?.nickname
          )
        }
        labels={["Użytkownicy", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            onClick: () => history.push(`/users/${id}/edit`),
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {USERS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue dataType={item.dataType}>
                        {item.show.accessor
                          ? item.show.accessor.length > 1
                            ? data &&
                              data[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                            : data?.[item.show.accessor[0]]
                          : data && data[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          {data?.avatarUrl && data?.avatarUrl?.includes("http") && (
            <Cell span={12}>
              <LabelMedium marginBottom="scale200" marginTop="scale600">
                Avatar
              </LabelMedium>
              <hr
                className={css({
                  borderWidth: "0px",
                  height: "1px",
                  backgroundColor: "#eee",
                })}
              />
              <Cell span={12}>
                <img
                  src={data?.avatarUrl}
                  alt="avatar"
                  className={css({ maxHeight: "300px" })}
                />
              </Cell>
            </Cell>
          )}
          {data?.backgroundImageUrl &&
            data?.backgroundImageUrl?.includes("http") && (
              <Cell span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  Tło
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
                <Cell span={12}>
                  <img
                    src={data?.backgroundImageUrl}
                    alt="background"
                    className={css({ maxHeight: "300px" })}
                  />
                </Cell>
              </Cell>
            )}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Usuń użytkownika",
                  icon: Trash,
                  color: theme.colors.primary,
                  onClick: () => setIsConfirmDialogOpen(true),
                },
                {
                  label: isUserBanned
                    ? "Odbanuj użytkownika"
                    : "Zbanuj użytkownika",
                  icon: isUserBanned ? LockOpen : LockOff,
                  color: isUserBanned
                    ? theme.colors.positive
                    : theme.colors.primary,
                  onClick: () =>
                    isUserBanned
                      ? setIsUnbanUserConfirmDialogOpen(true)
                      : setIsBanUserConfirmDialogOpen(true),
                },
              ]}
            />
          </Cell>

          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie użytkownika ${data?.nickname}`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit();
              setIsConfirmDialogOpen(false);
            }}
          />
          <ConfirmDialog
            isOpen={isBanUserConfirmDialogOpen}
            label={`Powód zbanowania użytkownika ${data?.nickname}:`}
            close={() => setIsBanUserConfirmDialogOpen(false)}
            needComment
            confirm={(textareaValue) => {
              onBanSubmit((textareaValue as string) || "");
              setIsBanUserConfirmDialogOpen(false);
            }}
          />
          <ConfirmDialog
            isOpen={isUnbanUserConfirmDialogOpen}
            label={`Odbanowanie użytkownika ${data?.nickname}`}
            close={() => setIsUnbanUserConfirmDialogOpen(false)}
            confirm={() => {
              onBanSubmit();
              setIsUnbanUserConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
