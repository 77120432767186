import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import React, { MouseEvent } from "react";
import { useForm } from "react-hook-form";
import { X } from "tabler-icons-react";

import Button from "../components/button";
import { useLoading } from "../contexts/loading-context";
import { ControlledTextArea } from "./text-area";

type ConfirmDialogProps = {
  isOpen: boolean;
  label?: string;
  close: () => void;
  confirm: (value: MouseEvent | string) => void;
  needComment?: boolean;
};

export type FormInputs = {
  textarea: string;
};

export default function ConfirmDialog({
  isOpen,
  label,
  close,
  confirm,
  needComment,
}: ConfirmDialogProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const { isLoading } = useLoading();

  const { control, watch } = useForm<FormInputs>();
  const watchTextarea = watch("textarea");

  return (
    <Modal
      onClose={close}
      closeable={!isLoading}
      isOpen={isOpen}
      animate
      unstable_ModalBackdropScroll
      autoFocus
      overrides={{
        Root: {
          style: {
            zIndex: 14,
          },
        },
        Dialog: {
          style: {
            borderTopLeftRadius: theme.borders.radius300,
            borderTopRightRadius: theme.borders.radius300,
            borderBottomRightRadius: theme.borders.radius300,
            borderBottomLeftRadius: theme.borders.radius300,
            paddingTop: theme.sizing.scale300,
            paddingBottom: theme.sizing.scale600,
            paddingRight: theme.sizing.scale300,
            paddingLeft: theme.sizing.scale300,
          },
        },
        Close: {
          component: () => (
            <span
              className={css({
                position: "absolute",
                right: theme.sizing.scale600,
                top: theme.sizing.scale600,
                cursor: "pointer",
                color: "#444",
                ":hover": {
                  color: "black",
                },
              })}
              onClick={close}
            >
              <X size={20} />
            </span>
          ),
        },
      }}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Potwierdź akcję</ModalHeader>
      <ModalBody>
        {needComment ? (
          <>
            <p>Ta akcja wymaga dodatkowego potwierdzenia i komentarza.</p>
            {label && (
              <p>
                <strong>{label}</strong>
              </p>
            )}
            <ControlledTextArea control={control} name="textarea" />
          </>
        ) : (
          <>
            <p>Ta akcja wymaga dodatkowego potwierdzenia.</p>
            {label && (
              <p>
                <strong>{label}</strong>
              </p>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          kind={KIND.secondary}
          $style={{ marginRight: "10px" }}
          onClick={close}
          disabled={isLoading}
        >
          Anuluj
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={(e) => (needComment ? confirm(watchTextarea) : confirm(e))}
        >
          Potwierdź
        </Button>
      </ModalFooter>
    </Modal>
  );
}
