import {
  API,
  BlockAPI,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";

type AdditionSizeSetting = {
  icon: string;
  name: AdditionSize;
};

enum AdditionSize {
  Small = "small",
  Medium = "medium",
}

interface AdditionData extends BlockToolData {
  title: string;
  text: string;
  size: AdditionSize;
}

export default class Addition implements BlockTool {
  public static isReadOnlySupported = true;
  public static enableLineBreaks = true;
  public static sanitize = {
    div: true,
    br: true,
    ul: true,
    li: true,
  };

  private data: AdditionData;
  private isReadOnly: boolean;
  private api: API;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Dopisek",
      // TODO: Find appropriate icon
      icon: "D",
    };
  }

  constructor({
    data,
    readOnly,
    api,
  }: BlockToolConstructorOptions<AdditionData>) {
    this.data = {
      ...data,
      size: data.size ?? AdditionSize.Small,
    };
    this.isReadOnly = readOnly;
    this.api = api;
  }

  render(): HTMLElement {
    const container = document.createElement("div");
    container.classList.add(this.api.styles.block);

    const title = document.createElement("div");
    title.classList.add(this.api.styles.input, "title");
    title.contentEditable = String(!this.isReadOnly);
    title.innerHTML = this.data.title ?? "";
    title.dataset.placeholder = "Tytuł";

    const text = document.createElement("div");
    text.classList.add(this.api.styles.input, "text");
    text.contentEditable = String(!this.isReadOnly);
    text.innerHTML = this.data.text ?? "";
    text.dataset.placeholder = "Tekst";
    text.style.minHeight = "200px";

    const disclaimer = document.createElement("p");
    disclaimer.innerHTML =
      "Dopisek jest przypinany TYLKO, gdy bezpośrednio nad nim jest paragraf tekstu.";
    disclaimer.style.fontSize = "small";
    disclaimer.style.color = "gray";

    container.appendChild(title);
    container.appendChild(text);
    if (!this.isReadOnly) container.appendChild(disclaimer);

    return container;
  }

  save(additionRootElement: HTMLElement): AdditionData {
    const title = additionRootElement.querySelector(".title")?.innerHTML || "";
    const text = additionRootElement.querySelector(".text")?.innerHTML || "";

    return {
      ...this.data,
      title,
      text,
    };
  }

  get settings(): AdditionSizeSetting[] {
    return [
      {
        name: AdditionSize.Small,
        icon: "S",
      },
      {
        name: AdditionSize.Medium,
        icon: "M",
      },
    ];
  }

  renderSettings(): HTMLElement {
    const wrapper = document.createElement("div");
    const capitalize = (str: string) => str[0].toUpperCase() + str.substr(1);

    this.settings
      .map((setting: AdditionSizeSetting) => {
        const el = document.createElement("div");

        el.classList.add(this.api.styles.settingsButton);
        el.innerHTML = setting.icon;
        el.title = `${capitalize(setting.name)} size`;

        el.classList.toggle(
          this.api.styles.settingsButtonActive,
          setting.name === this.data.size
        );

        wrapper.appendChild(el);

        return el;
      })
      .forEach((element, index, elements) => {
        element.addEventListener("click", () => {
          this.changeSize(this.settings[index].name);

          elements.forEach((el, i) => {
            const { name } = this.settings[i];

            el.classList.toggle(
              this.api.styles.settingsButtonActive,
              name === this.data.size
            );
          });
        });
      });

    return wrapper;
  }

  private changeSize(size: AdditionSize): void {
    this.data.size = size;

    this.api.blocks.update(
      (this.api.blocks.getBlockByIndex(
        this.api.blocks.getCurrentBlockIndex()
      ) as BlockAPI)?.id,
      this.data
    );
  }
}
