import {
  API,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

type VideoBlockEditorProps = {
  initialData: VideoData;
  onChange(data: VideoData): void;
  isReadOnly: boolean;
};

function VideoBlockEditor({
  initialData,
  onChange,
  isReadOnly,
}: VideoBlockEditorProps) {
  const [data, setData] = useState<VideoData>(initialData);

  useEffect(() => onChange(data), [data]);

  return (
    <input
      value={data.url}
      onChange={(event) =>
        setData((data) => ({
          ...data,
          url: (event.target as HTMLInputElement).value,
        }))
      }
      disabled={isReadOnly}
      placeholder="Adres do filmu (YouTube, Facebook)"
      style={{
        width: "100%",
        padding: "10px 12px",
        backgroundColor: "transparent",
        borderRadius: "3px",
        outline: "none",
        fontFamily: "inherit",
        fontSize: "16px",
        boxSizing: "border-box",
        border: "1px solid rgba(201,201,204,.48)",
      }}
    />
  );
}

interface VideoData extends BlockToolData {
  url: string;
}

export default class Video implements BlockTool {
  public static isReadOnlySupported = true;

  private data: VideoData;
  private isReadOnly: boolean;
  private api: API;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Wideo",
      // TODO: Find appropriate icon
      icon: "V",
    };
  }

  constructor({ data, readOnly, api }: BlockToolConstructorOptions<VideoData>) {
    this.data = {
      ...data,
    };
    this.isReadOnly = readOnly;
    this.api = api;
  }

  render(): HTMLElement {
    const root = document.createElement("div");
    root.classList.add(this.api.styles.block);

    ReactDOM.render(
      <VideoBlockEditor
        initialData={this.data}
        isReadOnly={this.isReadOnly}
        onChange={(data) => (this.data = data)}
      />,
      root
    );

    return root;
  }

  validate(data: VideoData): boolean {
    return !!data.url.trim();
  }

  save(rootElement: HTMLElement): VideoData {
    const url = rootElement.querySelector("input")?.value || "";

    return {
      ...this.data,
      url,
    };
  }
}
