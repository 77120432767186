import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  name: string;
  slug: string;
  parent?: {
    id: number;
    label?: string;
  }[];
  parentId?: number;
  isVisible?: boolean;
};

export const ARTICLE_CATEGORIES_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "slug",
        label: "Slug",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "parent",
        label: "Kategoria nadrzędna",
        type: FIELDS.ArticlesCategoriesSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "parent",
        label: "Kategoria nadrzędna",
        type: FIELDS.Input,
        dataType: "model:article-categories",
        span: 4,
        show: { visible: true, accessor: ["parent", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "isVisible",
        label: "Widoczność",
        type: FIELDS.Checkbox,
        dataType: "inverted-visibility-boolean",
        span: 4,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
