import React, { createContext, useContext, useState } from "react";

type LoadingContextProps = {
  isFetching: boolean;
  setIsFetching: (isLoading: boolean) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const LoadingContext = createContext<LoadingContextProps>(
  {} as LoadingContextProps
);

export function LoadingProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        isFetching,
        setIsFetching,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = (): LoadingContextProps => useContext(LoadingContext);
