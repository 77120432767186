import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AlertCircle, Check, DeviceFloppy, FileOff } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ImagePreview, {
  ImagePreviewSize,
} from "../../../components/image-preview";
import { ControlledInput } from "../../../components/input";
import {
  ControlledGamesSelect,
  ControlledGamingPlatformsSelect,
} from "../../../components/select";
import { ControlledTextArea } from "../../../components/text-area";
import { BASIC_AUTH } from "../../../constants";
import { useImageLibrary } from "../../../contexts/image-library-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Game } from "../../Games/games";
import { EDITORS_FIELDS, FormInputs } from "../editors.form";

export default function EditorsEdit(): React.ReactElement {
  const [isSlugChangedByUser, setIsSlugChangedByUser] = useState(false);
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { open } = useImageLibrary();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const getEditorData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/editors/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: queryData, refetch } = useQuery(
    `editor${id}CurrentData`,
    getEditorData
  );

  const getGames = async () => {
    const [considered, owned, played, completed] = await axios.all([
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/editors/${id}/considered-games`,
        BASIC_AUTH
      ),
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/editors/${id}/owned-games`,
        BASIC_AUTH
      ),
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/editors/${id}/played-games`,
        BASIC_AUTH
      ),
      axios.get(
        `${process.env.REACT_APP_API_URL}/admin/editors/${id}/completed-games`,
        BASIC_AUTH
      ),
    ]);

    return {
      consideredGamesData: considered.data,
      ownedGamesData: owned.data,
      playedGamesData: played.data,
      completedGamesData: completed.data,
    };
  };

  const { data: gamesData, refetch: gamesRefetch } = useQuery(
    `editor${id}Games`,
    getGames
  );

  const patchData = async (values: FormInputs) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/editors/${id}`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("editoredit", patchData);

  const onSubmit = async ({
    nick,
    slug,
    avatarUrl,
    backgroundImageUrl,
    description,
    socialMediaFacebook,
    socialMediaTwitter,
    socialMediaYoutube,
    socialMediaXbox,
    socialMediaSteam,
    socialMediaTwitch,
    socialMediaDiscord,
    socialMediaPlayStation,
    // gamingPlatformsSelect,
    favouriteGamesSelect,
    consideredGamesSelect,
    ownedGamesSelect,
    playedGamesSelect,
    completedGamesSelect,
    isVisible,
    trackingCode,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const data = await mutateAsync({
        nick,
        slug,
        avatarUrl,
        backgroundImageUrl,
        description,
        socialMediaUrls: {
          ...(socialMediaFacebook && { facebook: socialMediaFacebook }),
          ...(socialMediaTwitter && { twitter: socialMediaTwitter }),
          ...(socialMediaYoutube && { youtube: socialMediaYoutube }),
          ...(socialMediaXbox && { xbox: socialMediaXbox }),
          ...(socialMediaSteam && { steam: socialMediaSteam }),
          ...(socialMediaTwitch && { twitch: socialMediaTwitch }),
          ...(socialMediaDiscord && { discord: socialMediaDiscord }),
          ...(socialMediaPlayStation && {
            playstation: socialMediaPlayStation,
          }),
        },
        gamingPlatforms: "",
        favouriteGameIds: favouriteGamesSelect?.map(
          (item: { id: number }) => item.id
        ),
        consideredGameIds: consideredGamesSelect?.map(
          (item: { id: number }) => item.id
        ),
        ownedGameIds: ownedGamesSelect?.map((item: { id: number }) => item.id),
        playedGameIds: playedGamesSelect?.map(
          (item: { id: number }) => item.id
        ),
        completedGameIds: completedGamesSelect?.map(
          (item: { id: number }) => item.id
        ),
        isVisible: !!isVisible,
        trackingCode,
      });
      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/editors/${data?.id}`);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (gamesData) gamesRefetch();
    if (queryData) refetch();
    setIsSlugChangedByUser(false);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (queryData) {
      setIsFetching(false);
    }
  }, [queryData]);

  useEffect(() => {
    if (queryData) {
      setValue("nick", queryData?.nick);
      setValue("slug", queryData?.slug);
      setValue("description", queryData?.description);
      setValue(
        "favouriteGamesSelect",
        queryData?.favouriteGames?.map(({ id, originalTitle }: Game) => ({
          id,
          label: originalTitle,
        }))
      );
      setValue(
        "completedGamesSelect",
        gamesData?.completedGamesData?.map(({ id, originalTitle }: Game) => ({
          id,
          label: originalTitle,
        }))
      );
      setValue(
        "consideredGamesSelect",
        gamesData?.consideredGamesData?.map(({ id, originalTitle }: Game) => ({
          id,
          label: originalTitle,
        }))
      );
      setValue(
        "ownedGamesSelect",
        gamesData?.ownedGamesData.map(({ id, originalTitle }: Game) => ({
          id,
          label: originalTitle,
        }))
      );
      setValue(
        "playedGamesSelect",
        gamesData?.playedGamesData?.map(({ id, originalTitle }: Game) => ({
          id,
          label: originalTitle,
        }))
      );
      setValue("avatarUrl", queryData?.avatarUrl);
      setValue("trackingCode", queryData?.trackingCode);
      setValue("backgroundImageUrl", queryData?.backgroundImageUrl);
      setValue("isVisible", queryData?.isVisible);
      queryData?.socialMediaUrls?.facebook &&
        setValue("socialMediaFacebook", queryData?.socialMediaUrls?.facebook);
      queryData?.socialMediaUrls?.twitter &&
        setValue("socialMediaTwitter", queryData?.socialMediaUrls?.twitter);
      queryData?.socialMediaUrls?.youtube &&
        setValue("socialMediaYoutube", queryData?.socialMediaUrls?.youtube);
      queryData?.socialMediaUrls?.xbox &&
        setValue("socialMediaXbox", queryData?.socialMediaUrls?.xbox);
      queryData?.socialMediaUrls?.steam &&
        setValue("socialMediaSteam", queryData?.socialMediaUrls?.steam);
      queryData?.socialMediaUrls?.twitch &&
        setValue("socialMediaTwitch", queryData?.socialMediaUrls?.twitch);
      queryData?.socialMediaUrls?.discord &&
        setValue("socialMediaDiscord", queryData?.socialMediaUrls?.discord);
      queryData?.socialMediaUrls?.playstation &&
        setValue(
          "socialMediaPlayStation",
          queryData?.socialMediaUrls?.playstation
        );
    }
  }, [queryData, gamesData]);

  return (
    <article>
      <Header
        title={queryData?.nick}
        labels={["Redaktorzy", "Edytowanie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () =>
              history.push(
                history.location.pathname.split("/").slice(0, -1).join("/")
              ),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {EDITORS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={item.caption}
                      required={item.edit.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="48px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={isLoading}
                          $style={{ marginTop: "30px" }}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          <p
                            className={css({
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            })}
                          >
                            {item.label}
                          </p>
                        </ControlledCheckbox>
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.GamesSelect ? (
                        <ControlledGamesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          multi
                        />
                      ) : item.type === FIELDS.GamingPlatformsSelect ? (
                        <ControlledGamingPlatformsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          multi
                        />
                      ) : item.type === FIELDS.ImagePicker ? (
                        <>
                          {watch(item.id as keyof FormInputs) && (
                            <ImagePreview
                              image={{
                                path: watch(
                                  item.id as keyof FormInputs
                                ) as string,
                              }}
                              size={ImagePreviewSize.Large}
                            />
                          )}
                          <Button
                            type="button"
                            size="compact"
                            kind={KIND.secondary}
                            onClick={() =>
                              open((image) =>
                                setValue(
                                  item.id as keyof FormInputs,
                                  `${process.env.REACT_APP_CDN_URL}/images/${image.path}`
                                )
                              )
                            }
                            $style={{ marginTop: "10px" }}
                          >
                            Wybierz z biblioteki lub wgraj
                          </Button>
                        </>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                          {...(item.id === "slug" && {
                            onKeyUp: () => setIsSlugChangedByUser(true),
                            $style: {
                              color: isSlugChangedByUser ? "#000" : "#999",
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
