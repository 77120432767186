import { OutputData } from "@editorjs/editorjs";

import {
  FIELDS,
  FieldsGroup,
  LiteralOption,
  NumericOption,
} from "../../fields.d";

export type FormInputs = {
  originalTitle: string;
  slug: string;
  altTitles: string;
  polishTitle: string;
  boxCoverUrl: string;
  editorRating: number;
  platformRatings?: PlatformRatings;
  pcPlatformRating?: number;
  ps4PlatformRating?: number;
  ps5PlatformRating?: number;
  xsxPlatformRating?: number;
  xoPlatformRating?: number;
  switchPlatformRating?: number;
  usersRating: number;
  intro: string;
  description: string;
  systemRequirements: string;
  expectation: number;
  ownersCount: number;
  recommendedCount: number;
  notRecommendedCount: number;
  wishToPlayCount: number;
  tags?: NumericOption[];
  tagIds?: number[];
  multiplayerTags?: NumericOption[];
  multiplayerTagIds?: number[];
  genreTags?: NumericOption[];
  genreTagIds?: number[];
  platformTags?: NumericOption[];
  platformTagIds?: number[];
  developerTags?: NumericOption[];
  developerTagIds?: number[];
  publisherTags?: NumericOption[];
  publisherTagIds?: number[];
  languageVersionTags?: NumericOption[];
  languageVersionTagIds?: number[];
  content?: Record<string, any>[];
  pegiAgeRatingSelect?: NumericOption[];
  pegiAgeRating?: number | string;
  pegiContentDescriptorsSelect?: LiteralOption[];
  pegiContentDescriptors?: string[];
  body?: OutputData[];
  isFeatured: boolean;
  releasedAt: Date;
  trackingCode?: string;
};

export type PlatformRatings = {
  pcPlatformRating: number | undefined;
  ps4PlatformRating: number | undefined;
  ps5PlatformRating: number | undefined;
  xsxPlatformRating: number | undefined;
  xoPlatformRating: number | undefined;
  switchPlatformRating: number | undefined;
};

export const GAMES_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "originalTitle",
        label: "Tytuł oryginalny",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "slug",
        label: "Slug",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "polishTitle",
        label: "Tytuł polski",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "altTitles",
        label: "Tytuły alternatywne",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "releasedAt",
        label: "Data premiery",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "intro",
        label: "Lead",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "boxCover",
    label: "Okładka gry",
    fields: [
      {
        id: "boxCoverUrl",
        label: "",
        type: FIELDS.ImagePicker,
        dataType: "image",
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "description",
    label: "Karta gry",
    fields: [
      {
        id: "description",
        label: "Opis",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: "body",
        label: "",
        span: 12,
        type: FIELDS.Editor,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "trackingCode",
        label: "Kod śledzenia",
        caption: "Ukryty kod HTML niewidoczny dla użytkownika.",
        span: 12,
        type: FIELDS.TextArea,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "attributes",
    label: "Atrybuty",
    fields: [
      {
        id: "isFeatured",
        label: "Wyróżniona",
        caption: "Wyświetlanie na górze w głównym widoku gier.",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 4,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "pegi",
    label: "Ocena PEGI",
    fields: [
      {
        id: "pegiAgeRatingSelect",
        label: "Ocena wiekowa",
        type: FIELDS.PegiAgeRatingSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "pegiContentDescriptorsSelect",
        label: "Opis zawartości",
        type: FIELDS.PegiContentDescriptorsMultiSelect,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "pegiAgeRating",
        label: "Ocena wiekowa",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "pegiContentDescriptors",
        label: "Opis zawartości",
        type: FIELDS.Input,
        span: 8,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "statistics",
    label: "Statystyki",
    fields: [
      {
        id: "wishToPlayCount",
        label: "Liczba chcących zagrać",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "ownersCount",
        label: "Liczba posiadaczy/oczekujących na grę",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "expectation",
        label: "Oczekiwana ocena",
        type: FIELDS.NumberInput,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "expectation",
        label: "Oczekiwana ocena",
        type: FIELDS.NumberInput,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "usersRating",
        label: "Ocena internautów",
        type: FIELDS.Input,
        dataType: "rating",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "editorRating",
        label: "Ocena redakcji",
        type: FIELDS.NumberInput,
        dataType: "rating",
        placeholder: "1-10",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "pcPlatformRating",
        label: "Ocena PC",
        type: FIELDS.NumberInput,
        dataType: "rating",
        placeholder: "1-10",
        span: 2,
        show: {
          visible: true,
          accessor: ["platformRatings", "pcPlatformRating"],
        },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "ps4PlatformRating",
        label: "Ocena PS4",
        type: FIELDS.NumberInput,
        dataType: "rating",
        placeholder: "1-10",
        span: 2,
        show: {
          visible: true,
          accessor: ["platformRatings", "ps4PlatformRating"],
        },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "ps5PlatformRating",
        label: "Ocena PS5",
        type: FIELDS.NumberInput,
        dataType: "rating",
        placeholder: "1-10",
        span: 2,
        show: {
          visible: true,
          accessor: ["platformRatings", "ps5PlatformRating"],
        },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "xsxPlatformRating",
        label: "Ocena XSX",
        type: FIELDS.NumberInput,
        dataType: "rating",
        placeholder: "1-10",
        span: 2,
        show: {
          visible: true,
          accessor: ["platformRatings", "xsxPlatformRating"],
        },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "xoPlatformRating",
        label: "Ocena XO",
        type: FIELDS.NumberInput,
        dataType: "rating",
        placeholder: "1-10",
        span: 2,
        show: {
          visible: true,
          accessor: ["platformRatings", "xoPlatformRating"],
        },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "switchPlatformRating",
        label: "Ocena SWITCH",
        type: FIELDS.NumberInput,
        dataType: "rating",
        placeholder: "1-10",
        span: 2,
        show: {
          visible: true,
          accessor: ["platformRatings", "switchPlatformRating"],
        },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "recommendedCount",
        label: "Liczba poleceń",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "notRecommendedCount",
        label: "Liczba “nie polecam”",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "tags",
    label: "Tagi",
    fields: [
      {
        id: "tags",
        label: "Generalne",
        type: FIELDS.Input,
        typeName: "General",
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "tags",
        label: "Generalne",
        type: FIELDS.TagsSelect,
        typeName: "General",
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "multiplayerTags",
        label: "Multiplayer",
        type: FIELDS.Input,
        typeName: "Multiplayer",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "multiplayerTags",
        label: "Multiplayer",
        type: FIELDS.TagsSelect,
        typeName: "Multiplayer",
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "genreTags",
        label: "Gatunek",
        type: FIELDS.Input,
        typeName: "Genre",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "genreTags",
        label: "Gatunek",
        type: FIELDS.TagsSelect,
        typeName: "Genre",
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "platformTags",
        label: "Platformy",
        type: FIELDS.Input,
        typeName: "Platform",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "platformTags",
        label: "Platformy",
        type: FIELDS.TagsSelect,
        typeName: "Platform",
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "developerTags",
        label: "Producent",
        type: FIELDS.Input,
        typeName: "Developer",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "developerTags",
        label: "Producent",
        type: FIELDS.TagsSelect,
        typeName: "Developer",
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "publisherTags",
        label: "Wydawca",
        type: FIELDS.Input,
        typeName: "Publisher",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },

      {
        id: "publisherTags",
        label: "Wydawca",
        type: FIELDS.TagsSelect,
        typeName: "Publisher",
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "languageVersionTags",
        label: "Wersje językowe",
        type: FIELDS.Input,
        typeName: "LanguageVersion",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "languageVersionTags",
        label: "Wersje językowe",
        type: FIELDS.TagsSelect,
        typeName: "LanguageVersion",
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
