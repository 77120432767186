import { BlockAPI, ToolboxConfig } from "@editorjs/editorjs";
import NestedList from "@editorjs/nested-list";

import Caret from "./deps/caret";

export default class List extends NestedList {
  constructor({ data, config, api, readOnly }: any) {
    super({ data, config, api, readOnly });
    /**
     * HTML nodes used in tool
     */
    this.nodes = {
      wrapper: null,
    };

    this.api = api;
    this.readOnly = readOnly;
    this.config = config;

    this.settings = [
      {
        name: "unordered",
        title: this.api.i18n.t("Unordered"),
        icon:
          '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>',
        default: false,
      },
      {
        name: "ordered",
        title: this.api.i18n.t("Ordered"),
        icon:
          '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"><path d="M5.819 4.607h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 1 1 0-2.138zm0-4.607h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 1 1 0-2.138zm0 9.357h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 0 1 0-2.137zM1.468 4.155V1.33c-.554.404-.926.606-1.118.606a.338.338 0 0 1-.244-.104A.327.327 0 0 1 0 1.59c0-.107.035-.184.105-.234.07-.05.192-.114.369-.192.264-.118.475-.243.633-.373.158-.13.298-.276.42-.438a3.94 3.94 0 0 1 .238-.298C1.802.019 1.872 0 1.975 0c.115 0 .208.042.277.127.07.085.105.202.105.351v3.556c0 .416-.15.624-.448.624a.421.421 0 0 1-.32-.127c-.08-.085-.121-.21-.121-.376zm-.283 6.664h1.572c.156 0 .275.03.358.091a.294.294 0 0 1 .123.25.323.323 0 0 1-.098.238c-.065.065-.164.097-.296.097H.629a.494.494 0 0 1-.353-.119.372.372 0 0 1-.126-.28c0-.068.027-.16.081-.273a.977.977 0 0 1 .178-.268c.267-.264.507-.49.722-.678.215-.188.368-.312.46-.371.165-.11.302-.222.412-.334.109-.112.192-.226.25-.344a.786.786 0 0 0 .085-.345.6.6 0 0 0-.341-.553.75.75 0 0 0-.345-.08c-.263 0-.47.11-.62.329-.02.029-.054.107-.101.235a.966.966 0 0 1-.16.295c-.059.069-.145.103-.26.103a.348.348 0 0 1-.25-.094.34.34 0 0 1-.099-.258c0-.132.031-.27.093-.413.063-.143.155-.273.279-.39.123-.116.28-.21.47-.282.189-.072.411-.107.666-.107.307 0 .569.045.786.137a1.182 1.182 0 0 1 .618.623 1.18 1.18 0 0 1-.096 1.083 2.03 2.03 0 0 1-.378.457c-.128.11-.344.282-.646.517-.302.235-.509.417-.621.547a1.637 1.637 0 0 0-.148.187z"/></svg>',
        default: true,
      },
    ];

    /**
     * This list-style will be used by default
     */
    this.defaultListStyle = "ordered";

    const initialData = {
      style: this.defaultListStyle,
      items: [],
    };

    //this.data = data && Object.keys(data).length ? data : initialData;
    this.data = initialData;
    if (data && data.items && data.items.length > 0) {
      this.data.style = data.style;
      const item0 = data.items[0];
      if (typeof item0 === "object") this.data.items = data.items;
      else {
        // compatible with @editorjs/list
        const items: { content: any; items: never[] }[] = [];
        data.items.forEach((di: any) => {
          items.push({
            content: di,
            items: [],
          });
        });
        this.data.items = items;
      }
    }

    /**
     * Instantiate caret helper
     */
    this.caret = new Caret();
  }

  static get toolbox(): ToolboxConfig {
    return {
      title: "Lista",
      icon:
        '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>',
    };
  }

  onPaste(event: any) {
    const list = event.detail.data;

    this.data = this.pasteHandler(list);

    this.api.blocks.update(
      (this.api.blocks.getBlockByIndex(
        this.api.blocks.getCurrentBlockIndex()
      ) as BlockAPI)?.id,
      this.pasteHandler(list)
    );
  }

  pasteHandler(element: any) {
    const { tagName: tag } = element;

    let style;

    switch (tag) {
      case "OL":
        style = "ordered";
        break;
      case "UL":
      case "LI":
        style = "unordered";
    }

    const data = {
      style,
      items: [],
    } as any;

    function getTextFromHtml(html: string): string {
      return RegExp(/(.*?)(<ol>|<ul>|\\n|$)/i).exec(html)?.[1] as string;
    }

    function getListItemDataFromElement(element: Element): any {
      const items = Array.from(element.querySelectorAll(":scope > * > li"));

      return {
        content: getTextFromHtml(element.innerHTML),
        items: items.map((element) => getListItemDataFromElement(element)),
      };
    }

    if (tag === "LI") {
      data.items = [
        {
          content: getTextFromHtml(element.innerHTML),
        },
      ];
    } else {
      const items = Array.from(element.querySelectorAll(":scope > li"));

      data.items = items.map((li: any) => ({
        content: getTextFromHtml(li.innerHTML),
        items: Array.from(
          li.querySelectorAll(":scope > * > li")
        )?.map((element: any) => getListItemDataFromElement(element)),
      }));
    }

    return data;
  }

  static get pasteConfig() {
    return {
      tags: ["OL", "UL", "LI"],
    };
  }
}
