import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import React, { MouseEvent, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  AlertCircle,
  CircleCheck,
  CircleX,
  FileLike,
} from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Pagination from "../../../components/pagination";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import { BASIC_AUTH, PAGE_SIZE } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError } from "../../../utils/snackbarTypes";
import { ReportedComment } from "../reported-comments";

enum FieldName {
  Reason = "reason",
  Status = "status",
  CreatedAt = "createdAt",
}

export default function ReportedCommentsIndex(): React.ReactElement {
  const location = useLocation();
  let pageNumberFromUrl = Number(location.search.split("?page=").pop());
  if (pageNumberFromUrl < 1) {
    pageNumberFromUrl = 1;
  }
  const [paginationNumber, setPaginationNumber] = useState(pageNumberFromUrl);
  const [pageSize] = useState(PAGE_SIZE);
  const query = new URLSearchParams(useLocation().search);
  const pageNumber = parseInt(query.get("page") as string);
  const [sortBy, setSortBy] = useState<FieldName | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    null
  );
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueue } = useSnackbar();
  const { isFetching, setIsFetching } = useLoading();

  const { isError, data, refetch } = useQuery(
    "reported-comments",
    async () =>
      (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/comments/reports`,
          {
            ...BASIC_AUTH,
            params: {
              limit: PAGE_SIZE,
              offset: (paginationNumber - 1) * pageSize,
              sort: `${sortDirection === SortDirection.ASC ? "" : "-"}${
                sortBy || "createdAt"
              }`,
            },
          }
        )
      ).data
  );

  const handleSorting = (column: FieldName) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Reason)}
            sortDirection={sortBy === FieldName.Reason ? sortDirection : null}
          >
            Treść zgłoszenia
          </SortingTableHeader>
        ),
        id: "reason",
        Cell: ({ row }: { row: any }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(
                `/reported-comments/${row?.original?.id}/comment/${row?.original?.commentId}`
              );
            }}
            href={`/reported-comments/${row?.original?.id}/comment/${row?.original?.commentId}`}
          >
            <div
              className={css({
                width: "25vw",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              })}
            >
              {row.original.reason}
            </div>
          </StyledLink>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Status)}
            sortDirection={sortBy === FieldName.Status ? sortDirection : null}
            $style={{ justifyContent: "center" }}
          >
            Status
          </SortingTableHeader>
        ),
        id: "status",
        Cell: ({ row }: { row: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            {row?.original?.status === "Pending" && (
              <FileLike
                size={14}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.warning,
                })}
              />
            )}
            {row?.original?.status === "Accepted" && (
              <CircleCheck
                size={14}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.positive,
                })}
              />
            )}
            {row?.original?.status === "Rejected" && (
              <CircleX
                size={14}
                className={css({
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginTop: "-2px",
                  display: "inline",
                  color: theme.colors.negative,
                })}
              />
            )}
          </Block>
        ),
      },
      {
        Header: "Autor",
        id: "author",
        Cell: ({ row }: { row: any }) => (
          <FormattedValue
            dataType="model:users"
            data={row?.original?.user.id}
            withoutIcon
            showBlankWhenEmpty
          >
            {row?.original?.user?.nickname}
          </FormattedValue>
        ),
      },
      {
        Header: "IP klienta",
        id: "ipAddress",
        Cell: ({ row }: { row: any }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="center">
              {
                row?.original?.comment?.activityLogs[
                  row?.original?.comment?.activityLogs?.length - 1
                ]?.properties?.client.ipAddress
              }
            </Block>
          );
        },
      },
      {
        Header: "Dotyczy komentarza",
        id: "comment",
        Cell: ({ row }: { row: any }) => {
          return (
            <FormattedValue
              dataType="model:comments"
              data={row?.original?.comment.id}
              withoutIcon
              showBlankWhenEmpty
            >
              {row?.original?.comment?.content}
            </FormattedValue>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.CreatedAt)}
            sortDirection={
              sortBy === FieldName.CreatedAt ? sortDirection : null
            }
            $style={{ justifyContent: "flex-end" }}
          >
            Data dodania
          </SortingTableHeader>
        ),
        accessor: "createdAt",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            <FormattedValue dataType="date" showBlankWhenEmpty>
              {cell?.value}
            </FormattedValue>
          </Block>
        ),
      },

      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/reported-comments/${row?.original?.id}/comment/${row?.original?.commentId}`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(
                  `/reported-comments/${row?.original?.id}/comment/${row?.original?.commentId}`
                );
              }}
              $style={{ marginLeft: "10px" }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    [data, sortBy, sortDirection]
  );

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    refetch();
    setIsFetching(true);
  }, [paginationNumber, pageNumber]);

  useEffect(() => {
    refetch();
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
    pageNumber && setPaginationNumber(pageNumber);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title="Zgłoszone komentarze"
        recordsNum={data?.totalCount}
        labels={["Lista"]}
      />
      <Content>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<ReportedComment>
                  columns={columns}
                  data={data?.results}
                  noOffset
                />
              )
            )}
          </Cell>
          <Cell span={12}>
            {data && !!data?.totalCount && data?.totalCount > PAGE_SIZE && (
              <Pagination
                numPages={Math.ceil(data?.totalCount / pageSize)}
                currentPage={paginationNumber}
                onPageChange={setPaginationNumber}
              />
            )}
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
