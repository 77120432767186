import Account from "./containers/Account";
import ArticleCategoriesCreate from "./containers/ArticleCategories/Create";
import ArticleCategoriesEdit from "./containers/ArticleCategories/Edit";
import ArticleCategoriesIndex from "./containers/ArticleCategories/Index";
import ArticleCategoriesShow from "./containers/ArticleCategories/Show";
import ArticlesCreate from "./containers/Articles/Create";
import ArticlesEdit from "./containers/Articles/Edit";
import ArticlesIndex from "./containers/Articles/Index";
import ArticlesShow from "./containers/Articles/Show";
import Login from "./containers/Auth/Login";
import LoginForumCallback from "./containers/Auth/Login/forum-callback";
import Logout from "./containers/Auth/Logout";
import CommentsEdit from "./containers/Comments/Edit";
import CommentsIndex from "./containers/Comments/Index";
import CommentsShow from "./containers/Comments/Show";
import EditorsCreate from "./containers/Editors/Create";
import EditorsEdit from "./containers/Editors/Edit";
import EditorsIndex from "./containers/Editors/Index";
import EditorsShow from "./containers/Editors/Show";
import GamesCreate from "./containers/Games/Create";
import GamesEdit from "./containers/Games/Edit";
import GamesIndex from "./containers/Games/Index";
import GamesShow from "./containers/Games/Show";
import Home from "./containers/Home";
import LabelsCreate from "./containers/Labels/Create";
import LabelsEdit from "./containers/Labels/Edit";
import LabelsIndex from "./containers/Labels/Index";
import LabelsShow from "./containers/Labels/Show";
import MagazinesCreate from "./containers/Magazines/Create";
import MagazinesEdit from "./containers/Magazines/Edit";
import MagazinesIndex from "./containers/Magazines/Index";
import MagazinesShow from "./containers/Magazines/Show";
import MediaCreate from "./containers/Media/Create";
import MediaEdit from "./containers/Media/Edit";
import MediaIndex from "./containers/Media/Index";
import MediaShow from "./containers/Media/Show";
import ReportedCommentsIndex from "./containers/ReportedComments/Index";
import ReportedCommentsShow from "./containers/ReportedComments/Show";
import SectionsCreate from "./containers/Sections/Create";
import SectionsEdit from "./containers/Sections/Edit";
import SectionsIndex from "./containers/Sections/Index";
import SectionsShow from "./containers/Sections/Show";
import StaticPagesCreate from "./containers/StaticPages/Create";
import StaticPagesEdit from "./containers/StaticPages/Edit";
import StaticPagesIndex from "./containers/StaticPages/Index";
import StaticPagesShow from "./containers/StaticPages/Show";
import TagsCreate from "./containers/Tags/Create";
import TagsEdit from "./containers/Tags/Edit";
import TagsIndex from "./containers/Tags/Index";
import TagsShow from "./containers/Tags/Show";
import UsersCreate from "./containers/Users/Create";
import UsersEdit from "./containers/Users/Edit";
import UsersIndex from "./containers/Users/Index";
import UsersShow from "./containers/Users/Show";
import VideosCreate from "./containers/Videos/Create";
import VideosEdit from "./containers/Videos/Edit";
import VideosIndex from "./containers/Videos/Index";
import VideosShow from "./containers/Videos/Show";

export type Route = {
  path: string;
  component: () => React.ReactElement;
  exact?: boolean;
};

export type ModelRoute = {
  name: string;
  path: string;
  index?: Route;
  show?: Route;
  create?: Route;
  edit?: Route;
};

const ROUTES = {
  private: {
    basic: [
      {
        path: "/",
        component: Home,
        exact: true,
      },
      {
        path: "/account",
        component: Account,
        exact: true,
      },
      {
        path: "/logout",
        component: Logout,
        exact: true,
      },
    ] as Route[],
    models: [
      {
        name: "articles",
        path: "/articles",
        index: {
          path: "/",
          component: ArticlesIndex,
        },
        create: {
          path: "/create",
          component: ArticlesCreate,
        },
        show: {
          path: "/:id",
          component: ArticlesShow,
        },
        edit: {
          path: "/:id/edit",
          component: ArticlesEdit,
        },
      },
      {
        name: "comments",
        path: "/comments",
        index: {
          path: "/",
          component: CommentsIndex,
        },
        show: {
          path: "/:id",
          component: CommentsShow,
        },
        edit: {
          path: "/:id/edit",
          component: CommentsEdit,
        },
      },
      {
        name: "reportedComments",
        path: "/reported-comments",
        index: {
          path: "/",
          component: ReportedCommentsIndex,
        },
        show: {
          path: "/:id/comment/:commentId",
          component: ReportedCommentsShow,
        },
      },
      {
        name: "games",
        path: "/games",
        index: {
          path: "/",
          component: GamesIndex,
        },
        create: {
          path: "/create",
          component: GamesCreate,
        },
        edit: {
          path: "/:id/edit",
          component: GamesEdit,
        },
        show: {
          path: "/:id",
          component: GamesShow,
        },
      },
      {
        name: "articleCategories",
        path: "/article-categories",
        index: {
          path: "/",
          component: ArticleCategoriesIndex,
        },
        create: {
          path: "/create",
          component: ArticleCategoriesCreate,
        },
        show: {
          path: "/:id",
          component: ArticleCategoriesShow,
        },
        edit: {
          path: "/:id/edit",
          component: ArticleCategoriesEdit,
        },
      },
      {
        name: "tags",
        path: "/tags",
        index: {
          path: "/",
          component: TagsIndex,
        },
        create: {
          path: "/create",
          component: TagsCreate,
        },
        show: {
          path: "/:id",
          component: TagsShow,
        },
        edit: {
          path: "/:id/edit",
          component: TagsEdit,
        },
      },
      {
        name: "users",
        path: "/users",
        index: {
          path: "/",
          component: UsersIndex,
        },
        create: {
          path: "/create",
          component: UsersCreate,
        },
        show: {
          path: "/:id",
          component: UsersShow,
        },
        edit: {
          path: "/:id/edit",
          component: UsersEdit,
        },
      },
      {
        name: "editors",
        path: "/editors",
        index: {
          path: "/",
          component: EditorsIndex,
        },
        create: {
          path: "/create",
          component: EditorsCreate,
        },
        show: {
          path: "/:id",
          component: EditorsShow,
        },
        edit: {
          path: "/:id/edit",
          component: EditorsEdit,
        },
      },
      {
        name: "videos",
        path: "/videos",
        index: {
          path: "/",
          component: VideosIndex,
        },
        create: {
          path: "/create",
          component: VideosCreate,
        },
        show: {
          path: "/:id",
          component: VideosShow,
        },
        edit: {
          path: "/:id/edit",
          component: VideosEdit,
        },
      },
      {
        name: "magazines",
        path: "/magazines",
        index: {
          path: "/",
          component: MagazinesIndex,
        },
        create: {
          path: "/create",
          component: MagazinesCreate,
        },
        show: {
          path: "/:id",
          component: MagazinesShow,
        },
        edit: {
          path: "/:id/edit",
          component: MagazinesEdit,
        },
      },
      {
        name: "media",
        path: "/media",
        index: {
          path: "/",
          component: MediaIndex,
        },
        create: {
          path: "/create",
          component: MediaCreate,
        },
        show: {
          path: "/:id",
          component: MediaShow,
        },
        edit: {
          path: "/:id/edit",
          component: MediaEdit,
        },
      },
      {
        name: "labels",
        path: "/labels",
        index: {
          path: "/",
          component: LabelsIndex,
        },
        create: {
          path: "/create",
          component: LabelsCreate,
        },
        show: {
          path: "/:id",
          component: LabelsShow,
        },
        edit: {
          path: "/:id/edit",
          component: LabelsEdit,
        },
      },
      {
        name: "static-pages",
        path: "/static-pages",
        index: {
          path: "/",
          component: StaticPagesIndex,
        },
        create: {
          path: "/create",
          component: StaticPagesCreate,
        },
        show: {
          path: "/:id",
          component: StaticPagesShow,
        },
        edit: {
          path: "/:id/edit",
          component: StaticPagesEdit,
        },
      },
      {
        name: "sections",
        path: "/sections",
        index: {
          path: "/",
          component: SectionsIndex,
        },
        create: {
          path: "/create",
          component: SectionsCreate,
        },
        show: {
          path: "/:id",
          component: SectionsShow,
        },
        edit: {
          path: "/:id/edit",
          component: SectionsEdit,
        },
      },
    ] as ModelRoute[],
  },
  auth: [
    {
      path: "/login",
      component: Login,
      exact: true,
    },
    {
      path: "/login/forum/callback",
      component: LoginForumCallback,
      exact: true,
    },
  ] as Route[],
};

export default ROUTES;
