import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertCircle,
  Check,
  Eye,
  EyeOff,
  Pencil,
  Star,
  StarOff,
} from "tabler-icons-react";

import ActionsList from "../../../components/actions-list";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Table from "../../../components/table";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { CommentableType } from "../comments.d";
import { COMMENTS_FIELDS, FormInputs } from "../comments.form";

export default function CommentsShow(): React.ReactElement {
  const [isHideConfirmDialogOpen, setIsHideConfirmDialogOpen] = useState(false);
  const [isFeatureConfirmDialogOpen, setIsFeatureConfirmDialogOpen] = useState(
    false
  );
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/comments/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const getCommentHistoryLogsData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/comments/${id}/history-logs`,
      BASIC_AUTH
    );
    return data;
  };

  const restoreCommentData = async (values: FormInputs) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/comments/${id}`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { data, isError, refetch } = useQuery(`commentShow${id}`, getData);
  const commentableType = data?.commentableType;

  const { data: commentHistoryLogsData } = useQuery(
    `commentHistoryLogs${id}`,
    getCommentHistoryLogsData
  );

  const { mutateAsync: hideComment } = useMutation(
    data?.isHidden ? `unhideComment${id}` : `hideComment${id}`,
    async () => {
      await axios.post(
        data?.isHidden
          ? `${process.env.REACT_APP_API_URL}/admin/comments/${id}/unhide`
          : `${process.env.REACT_APP_API_URL}/admin/comments/${id}/hide`,
        {},
        BASIC_AUTH
      );
    }
  );

  const { mutateAsync: featureComment } = useMutation(
    data?.isFeatured ? `unfeatureComment${id}` : `featureComment${id}`,
    async () => {
      await axios.post(
        data?.isFeatured
          ? `${process.env.REACT_APP_API_URL}/admin/comments/${id}/unfeature`
          : `${process.env.REACT_APP_API_URL}/admin/comments/${id}/feature`,
        {},
        BASIC_AUTH
      );
    }
  );

  const { mutateAsync: restoreComment } = useMutation(
    `comment${id}Restore`,
    restoreCommentData
  );

  type ActionType = "HideOrUnhide" | "FeatureOrUnfeature";

  const onSubmit = async (action: ActionType): Promise<void> => {
    setIsLoading(true);

    try {
      action === "HideOrUnhide" ? await hideComment() : await featureComment();

      enqueue({
        message:
          action === "HideOrUnhide"
            ? data?.isHidden
              ? "Odkryto pomyślnie"
              : "Ukryto pomyślnie"
            : data?.isFeatured
            ? "Cofnięto wyróżnienie pomyślnie"
            : "Wyróżniono pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      refetch();
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestoreComment = async (content: string) => {
    setIsLoading(true);

    try {
      await restoreComment({
        content,
      });

      enqueue({
        message: "Przywrócono pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      refetch();
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  const commentHistoryLogsColumns = React.useMemo(
    () => [
      {
        Header: "Treść",
        id: "content",
        Cell: ({ row }: { row: any }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/comments/${row?.original?.id}`);
            }}
            href={`/comments/${row?.original?.id}`}
          >
            <FormattedValue>{row.original.content}</FormattedValue>
          </StyledLink>
        ),
      },
      {
        Header: (
          <Block
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            minWidth="142px"
          >
            Data zmiany
          </Block>
        ),
        accessor: "endedAt",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            <FormattedValue dataType="datetime">{cell?.value}</FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                handleRestoreComment(row?.original?.content);
              }}
              $style={{ marginLeft: "10px" }}
            >
              Przywróć
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <article>
      <Header
        title={`Komentarz ${data?.id}`}
        labels={["Komentarze", "Wyświetlanie"]}
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            onClick: () => history.push(`/comments/${id}/edit`),
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <Grid>
          {COMMENTS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={
                          item.id === "commentable"
                            ? commentableType === CommentableType.Article
                              ? "model:articles"
                              : commentableType === CommentableType.Game
                              ? "model:games"
                              : commentableType === CommentableType.Magazine
                              ? "model:magazines"
                              : commentableType === CommentableType.Video
                              ? "model:videos"
                              : undefined
                            : item.dataType
                        }
                        data={
                          item.id === "commentable"
                            ? data?.commentableId
                            : item.id === "author"
                            ? data?.authorId
                            : undefined
                        }
                      >
                        {item.id === "commentable"
                          ? commentableType === CommentableType.Article
                            ? data?.article?.title
                            : commentableType === CommentableType.Game
                            ? data?.game?.originalTitle
                            : commentableType === CommentableType.Magazine
                            ? data?.magazine?.issueNumber
                            : commentableType === CommentableType.Video
                            ? data?.video?.title
                            : undefined
                          : item.id === "author"
                          ? data?.author?.nickname
                          : item.id === "ipAddress" && data?.activityLogs
                          ? data.activityLogs[data.activityLogs.length - 1]
                              ?.properties?.client?.ipAddress
                          : item.show.accessor
                          ? data &&
                            data[item.show.accessor[0]]?.[item.show.accessor[1]]
                          : data && data[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Historia zmian
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              commentHistoryLogsData && (
                <Table<Comment>
                  columns={commentHistoryLogsColumns}
                  data={commentHistoryLogsData}
                  noOffset
                />
              )
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: data?.isHidden
                    ? "Odkryj komentarz"
                    : "Ukryj komentarz",
                  icon: data?.isHidden ? Eye : EyeOff,
                  color: data?.isHidden
                    ? theme.colors.positive
                    : theme.colors.negative,
                  onClick: () => setIsHideConfirmDialogOpen(true),
                },
                {
                  label: data?.isFeatured
                    ? "Cofnij wyróżnienie komentarza"
                    : "Wyróżnij komentarz",
                  icon: data?.isFeatured ? StarOff : Star,
                  color: data?.isFeatured
                    ? theme.colors.negative
                    : theme.colors.positive,
                  onClick: () => setIsFeatureConfirmDialogOpen(true),
                },
              ]}
            />
          </Cell>
          <ConfirmDialog
            isOpen={isHideConfirmDialogOpen}
            label={`${data?.isHidden ? "Odkrycie" : "Ukrycie"} komentarza ${
              data?.id
            }`}
            close={() => setIsHideConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit("HideOrUnhide");
              setIsHideConfirmDialogOpen(false);
            }}
          />
          <ConfirmDialog
            isOpen={isFeatureConfirmDialogOpen}
            label={`${
              data?.isFeatured ? "Cofnięcie wyróżnienia" : "Wyróżnienie"
            } komentarza ${data?.id}`}
            close={() => setIsFeatureConfirmDialogOpen(false)}
            confirm={() => {
              onSubmit("FeatureOrUnfeature");
              setIsFeatureConfirmDialogOpen(false);
            }}
          />
        </Grid>
      </Content>
    </article>
  );
}
