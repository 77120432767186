import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertCircle,
  Check,
  DeviceFloppy,
  FileOff,
  Pencil,
} from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import Content from "../../../components/content";
import DateTimePicker from "../../../components/date-time-picker";
import { ControlledEditor } from "../../../components/editor";
import { ControlledFilesPicker } from "../../../components/files-picker";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ImagePreview, {
  ImagePreviewSize,
} from "../../../components/image-preview";
import { ControlledInput } from "../../../components/input";
import {
  ControlledArticlesCategoriesSelect,
  ControlledArticleStatusSelect,
  ControlledEditorsSelect,
  ControlledGamesSelect,
  ControlledLabelsSelect,
  ControlledSectionsSelect,
  ControlledTagsSelect,
} from "../../../components/select";
import { ControlledTextArea } from "../../../components/text-area";
import { BASIC_AUTH } from "../../../constants";
import { useImageLibrary } from "../../../contexts/image-library-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Label } from "../../Labels/labels";
import { Tag } from "../../Tags/tags";
import { Error } from "../articles";
import { ARTICLES_FIELDS, FormInputs } from "../articles.form";

export default function ArticlesEdit(): React.ReactElement {
  const [publishedAtDate, setPublishedAtDate] = useState<Date | null>(null);
  const [isSlugChangedByUser, setIsSlugChangedByUser] = useState(false);
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    setError,
  } = useForm<FormInputs>();

  const watchTitle = watch("title");
  const watchStatus = watch("statusSelect");

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/articles/${id}`,
      BASIC_AUTH
    );
    return data;
  };

  const { data: queryData, refetch } = useQuery(
    `article${id}CurrentData`,
    getData,
    {
      cacheTime: 0,
    }
  );

  const editData = async (values: FormInputs) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/articles/${id}`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("articleEdit", editData);

  const publishedAt = publishedAtDate && publishedAtDate.toISOString();

  const onSubmit = async ({
    title,
    slug,
    lead,
    summary,
    commentRewardPointCount,
    isCommentingDisabled,
    popularityViewCountThreshold,
    category,
    isPopular,
    isUnpopular,
    isSponsored,
    isForAdults,
    isPinned,
    isRecommended,
    isFeatured,
    bannerUrl,
    tags,
    labels,
    editors,
    statusSelect,
    games,
    body,
    hasRecommendedBadge,
    hasPowerBadge,
    trackingCode,
    sections,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await mutateAsync({
        title,
        slug,
        lead,
        summary,
        commentRewardPointCount,
        publishedAt,
        status: statusSelect && statusSelect[0].id,
        isPopular: !!isPopular,
        isUnpopular: !!isUnpopular,
        isSponsored: !!isSponsored,
        isForAdults: !!isForAdults,
        isPinned: !!isPinned,
        isRecommended: !!isRecommended,
        isFeatured: !!isFeatured,
        isCommentingDisabled: !!isCommentingDisabled,
        bannerUrl,
        popularityViewCountThreshold,
        categoryId: category && category[0]?.id,
        tagIds: tags && tags.map(({ id }: { id: number }) => id),
        labelIds: labels && labels.map(({ id }: { id: number }) => id),
        editorId: editors && editors[0]?.id,
        gameId: games && games[0]?.id,
        body,
        hasRecommendedBadge: !!hasRecommendedBadge,
        hasPowerBadge: !!hasPowerBadge,
        trackingCode,
        sectionIds: sections && sections.map(({ id }: { id: number }) => id),
      });

      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/articles/${id}`);
    } catch (error: any) {
      (error.response.data as Error).validationErrors.map((error) => {
        setError(error.property as keyof FormInputs, {
          type: "manual",
          message: error.errors[0],
        });
      });

      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    setIsSlugChangedByUser(false);
    if (queryData) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (queryData) {
      setIsFetching(false);
    }
  }, [queryData]);

  useEffect(() => {
    if (queryData) {
      setValue("title", queryData?.title);
      setValue("slug", queryData?.slug);
      setValue("lead", queryData?.lead);
      setValue("summary", queryData?.summary);
      setValue("commentRewardPointCount", queryData?.commentRewardPointCount);
      setValue(
        "popularityViewCountThreshold",
        queryData?.popularityViewCountThreshold
      );
      setValue("category", [{ id: queryData?.categoryId }]);
      setValue("editors", [
        { id: queryData?.editorId, label: queryData?.editor.nick },
      ]);
      setValue("isCommentingDisabled", queryData?.isCommentingDisabled);
      setValue("statusSelect", [{ id: queryData.status }]);
      queryData?.publishedAt
        ? setPublishedAtDate(new Date(queryData?.publishedAt))
        : setPublishedAtDate(null);
      setValue("isPopular", queryData?.isPopular);
      setValue("isUnpopular", queryData?.isUnpopular);
      setValue("isSponsored", queryData?.isSponsored);
      setValue("isForAdults", queryData?.isForAdults);
      setValue("isPinned", queryData?.isPinned);
      setValue("isRecommended", queryData?.isRecommended);
      setValue("isFeatured", queryData?.isFeatured);
      setValue("hasRecommendedBadge", queryData?.hasRecommendedBadge);
      setValue("hasPowerBadge", queryData?.hasPowerBadge);
      queryData?.tags &&
        setValue(
          "tags",
          queryData?.tags?.map((tag: Tag) => ({ id: tag.id, label: tag.name }))
        );
      queryData?.labels &&
        setValue(
          "labels",
          queryData?.labels?.map((label: Label) => ({
            id: label.id,
            label: label.name,
          }))
        );
      queryData?.sections &&
        setValue(
          "sections",
          queryData?.sections?.map((label: Label) => ({
            id: label.id,
            label: label.name,
          }))
        );
      setValue("bannerUrl", queryData?.bannerUrl);
      setValue("trackingCode", queryData?.trackingCode);
      setValue("games", [
        { id: queryData?.gameId, label: queryData?.game?.originalTitle },
      ]);
      setValue("body", queryData?.body);
    }
  }, [queryData]);

  const { open } = useImageLibrary();

  return (
    <article>
      <Header
        title={
          (watchTitle && watchTitle !== queryData?.title && (
            <>
              {watchTitle} <Pencil size={15} />
            </>
          )) ||
          queryData?.title
        }
        labels={["Artykuły", "Edytowanie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () =>
              history.push(
                history.location.pathname.split("/").slice(0, -1).join("/")
              ),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {ARTICLES_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? " " : item.label}
                      caption={item.caption}
                      required={item.edit.required}
                      error={
                        item.id === "publishedAt"
                          ? watchStatus &&
                            watchStatus[0].id === "Published" &&
                            !publishedAtDate &&
                            formValidation.messages
                              .requiredDateInPublishedArticle
                          : (errors as any)[item.id] &&
                            (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="48px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={isLoading}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          <span
                            className={css({
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            })}
                          >
                            {item.label}
                          </span>
                        </ControlledCheckbox>
                      ) : item.type === FIELDS.FilesPicker ? (
                        <ControlledFilesPicker
                          control={control}
                          name={item.id}
                          accept={["image/jpeg", "image/png"]}
                          maxSize={134217728}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ArticlesCategoriesSelect ? (
                        <ControlledArticlesCategoriesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ArticlesStatusSelect ? (
                        <ControlledArticleStatusSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.EditorsSelect ? (
                        <ControlledEditorsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.TagsSelect ? (
                        <ControlledTagsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.LabelsSelect ? (
                        <ControlledLabelsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.SectionsSelect ? (
                        <ControlledSectionsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.GamesSelect ? (
                        <ControlledGamesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DateTimePicker ? (
                        <Block display="flex" alignItems="center">
                          <DateTimePicker
                            date={publishedAtDate}
                            setDate={setPublishedAtDate}
                          />
                          <Button
                            kind={KIND.secondary}
                            $style={{ marginBottom: "-12px" }}
                            onClick={(event: MouseEvent) => {
                              event.preventDefault();
                              setPublishedAtDate(new Date());
                            }}
                          >
                            Ustaw aktualny czas
                          </Button>
                        </Block>
                      ) : item.type === FIELDS.Editor ? (
                        <ControlledEditor control={control} name={item.id} />
                      ) : item.type === FIELDS.ImagePicker ? (
                        <>
                          {watch(item.id as keyof FormInputs) && (
                            <ImagePreview
                              image={{
                                path: watch(item.id as keyof FormInputs),
                              }}
                              size={ImagePreviewSize.Large}
                            />
                          )}
                          <Button
                            type="button"
                            size="compact"
                            kind={KIND.secondary}
                            onClick={() =>
                              open((image) =>
                                setValue(
                                  item.id as keyof FormInputs,
                                  `${process.env.REACT_APP_CDN_URL}/images/${image.path}`
                                )
                              )
                            }
                            $style={{ marginTop: "10px" }}
                          >
                            Wybierz z biblioteki lub wgraj
                          </Button>
                        </>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                          {...(item.id === "slug" && {
                            onKeyUp: () => setIsSlugChangedByUser(true),
                            $style: {
                              color: isSlugChangedByUser ? "#000" : "#999",
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
