import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { Option } from "baseui/select";
import formatBytes from "pretty-bytes";
import React, { MouseEvent } from "react";
import { useHistory } from "react-router";
import { StyleObject } from "styletron-standard";
import {
  BoxMultiple,
  BrandFacebook,
  BrandYoutube,
  CircleCheck,
  CircleX,
  Clock,
  DeviceGamepad,
  ExternalLink,
  Eye,
  EyeCheck,
  EyeOff,
  FileLike,
  Frame,
  Ghost,
  Key,
  Lock,
  LockOpen,
  Mail,
  Minus,
  News,
  Pencil,
  Shield,
  User,
  Writing,
} from "tabler-icons-react";

import { TAG_KINDS, USER_ROLES } from "../constants";
import { Label } from "../containers/Labels/labels";
import { BRAND } from "../containers/Videos/videos.d";
import ObjectTag from "./object-tag";

type Props = {
  dataType?: string;
  data?: string;
  date?: Date;
  children?: string | boolean | number;
  $style?: StyleObject;
  textWhenEmpty?: string;
  withoutIcon?: boolean;
  showBlankWhenEmpty?: boolean;
};

export default function FormattedValue({
  dataType,
  data,
  date,
  children,
  textWhenEmpty,
  $style,
  withoutIcon = false,
  showBlankWhenEmpty = false,
}: Props): React.ReactElement | null {
  const [css, theme] = useStyletron();
  const history = useHistory();

  const isDateFuture = (date: Date) => {
    return new Date(date).valueOf() > new Date().valueOf();
  };

  const secondsToHHMMSS = (seconds: number) => {
    return (
      Math.floor(seconds / 3600) +
      ":" +
      ("0" + (Math.floor(seconds / 60) % 60)).slice(-2) +
      ":" +
      ("0" + (seconds % 60)).slice(-2)
    );
  };

  if (dataType === "system")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          color: "#999999",
        })}
      >
        <Ghost
          color="#999999"
          size={18}
          className={css({ marginRight: "5px" })}
        />
        System
      </div>
    );

  if (
    children === undefined ||
    children === null ||
    children === "" ||
    (Array.isArray(children) && children.length === 0)
  )
    return showBlankWhenEmpty ? null : (
      <div
        key="error"
        className={css({
          display: "flex",
          alignItems: "center",
          color: "#999999",
          ...$style,
        })}
      >
        {textWhenEmpty || (
          <>
            <Minus
              color="#999999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
            Brak
          </>
        )}
      </div>
    );

  if (dataType === "role")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        <Key size={16} className={css({ marginRight: "5px" })} />
        {Array.isArray(children) &&
          (children as string[]).map(
            (role) => USER_ROLES.find((item) => item.id === role)?.label
          )}
      </div>
    );

  if (dataType === "login")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        <Key size={18} className={css({ marginRight: "5px" })} />
        {children}
      </div>
    );

  if (dataType === "nip")
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
        })}
      >
        {(children as string).replace(
          /^(\d{3})(\d{3})(\d{2})(\d{2}).*/,
          "$1-$2-$3-$4"
        )}
      </div>
    );

  if (dataType === "pre")
    return (
      <span
        className={css({
          marginTop: "0px",
          marginBottom: "0px",
          fontWeight: 400,
          fontFamily: "monospace",
          fontSize: "14px",
          backgroundColor: theme.colors.backgroundSecondary,
          paddingLeft: theme.sizing.scale300,
          paddingRight: theme.sizing.scale300,
          paddingTop: theme.sizing.scale100,
          paddingBottom: theme.sizing.scale100,
          display: "inline-block",
          borderRadius: theme.borders.radius100,
          ...$style,
        })}
      >
        {children}
      </span>
    );
  else if (dataType === "email")
    return (
      <StyledLink
        target="_blank"
        href={`mailto:${children}`}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <Mail
          size={14}
          className={css({
            verticalAlign: "middle",
            marginRight: "10px",
            display: "inline",
          })}
        />
        {children}
      </StyledLink>
    );
  else if (dataType === "external")
    return (
      <StyledLink
        target="_blank"
        href={children as string}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        <ExternalLink
          size={14}
          className={css({
            verticalAlign: "middle",
            marginRight: "10px",
            display: "inline",
          })}
        />
        {children}
      </StyledLink>
    );
  else if (dataType === "taggableType")
    return <>{children === "Article" ? "Artykuł" : "Gra"}</>;
  else if (dataType === "tagKind")
    return (
      <>
        {TAG_KINDS.find((kind: { id: string }) => kind.id === children)?.label}
      </>
    );
  else if (dataType === "sex")
    return <>{children === "Male" ? "Mężczyzna" : "Kobieta"}</>;
  else if (dataType === "article-status")
    return (
      <>
        {children === "Draft" && (
          <Pencil
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.accent,
            })}
          />
        )}
        {children === "Pending" && (
          <FileLike
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.warning,
            })}
          />
        )}
        {children === "Published" && date && !isDateFuture(date) && (
          <EyeCheck
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children === "Published" && date && isDateFuture(date) && (
          <Clock
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children === "Published" && !date && (
          <Pencil
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.warning500,
            })}
          />
        )}
        {children === "Draft" && "Szkic"}
        {children === "Pending" && "Do weryfikacji"}
        {children === "Published" && "Opublikowany"}
      </>
    );
  else if (dataType === "video-type")
    return (
      <span
        className={css({
          marginTop: "0px",
          marginBottom: "0px",
          fontWeight: 400,
          fontFamily: "monospace",
          fontSize: "14px",
          backgroundColor: theme.colors.backgroundSecondary,
          paddingLeft: theme.sizing.scale300,
          paddingRight: theme.sizing.scale300,
          paddingTop: theme.sizing.scale100,
          paddingBottom: theme.sizing.scale100,
          display: "inline-block",
          borderRadius: theme.borders.radius100,
          ...$style,
        })}
      >
        {children === "YouTube" ? (
          <BrandYoutube
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : children === "Facebook" ? (
          <BrandFacebook
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
            })}
          />
        ) : children === "BrandTwitch" ? (
          <BrandYoutube
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
              color: theme.colors.warning,
            })}
          />
        ) : (
          <Frame
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              display: "inline",
              color: theme.colors.warning,
            })}
          />
        )}

        {children}
      </span>
    );
  else if (dataType?.includes("model:"))
    return (
      <StyledLink
        href={`/${dataType.split(":")[1]}/${data}`}
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          history.push(`/${dataType.split(":")[1]}/${data}`);
        }}
        $style={{
          textDecoration: "underline dotted",
          fontWeight: 400,
          ...$style,
        }}
      >
        {!withoutIcon &&
          (dataType?.includes("roles") ? (
            <Shield
              size={16}
              className={css({
                verticalAlign: "middle",
                marginRight: "10px",
                display: "inline",
              })}
            />
          ) : dataType?.includes("users") ? (
            <User
              size={16}
              className={css({
                verticalAlign: "middle",
                marginRight: "10px",
                display: "inline",
              })}
            />
          ) : dataType?.includes("editors") ? (
            <Writing
              size={16}
              className={css({
                verticalAlign: "middle",
                marginRight: "10px",
                display: "inline",
              })}
            />
          ) : dataType?.includes("article-categories") ? (
            <BoxMultiple
              size={16}
              className={css({
                verticalAlign: "middle",
                marginRight: "10px",
                display: "inline",
              })}
            />
          ) : dataType?.includes("articles") ? (
            <News
              size={16}
              className={css({
                verticalAlign: "middle",
                marginRight: "10px",
                display: "inline",
              })}
            />
          ) : dataType?.includes("games") ? (
            <DeviceGamepad
              size={16}
              className={css({
                verticalAlign: "middle",
                marginRight: "10px",
                display: "inline",
              })}
            />
          ) : (
            <ExternalLink
              size={14}
              className={css({
                verticalAlign: "middle",
                marginRight: "10px",
                display: "inline",
              })}
            />
          ))}
        {children}
      </StyledLink>
    );
  else if (dataType === "inverted-boolean")
    return (
      <>
        {children ? (
          <CircleX
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <CircleCheck
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
      </>
    );
  else if (dataType === "access-boolean")
    return (
      <>
        {children ? (
          <Lock
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <LockOpen
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
      </>
    );
  else if (dataType === "visibility-boolean")
    return (
      <>
        {children ? (
          <EyeOff
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        ) : (
          <Eye
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children ? "Nie" : "Tak"}
      </>
    );
  else if (dataType === "inverted-visibility-boolean")
    return (
      <>
        {children ? (
          <Eye
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <EyeOff
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
      </>
    );
  else if (dataType === "visibility-icon")
    return (
      <>
        {children ? (
          <EyeOff
            size={18}
            className={css({
              color: theme.colors.negative,
            })}
          />
        ) : (
          <Eye
            size={18}
            className={css({
              color: theme.colors.positive,
            })}
          />
        )}
      </>
    );
  else if (dataType === "boolean")
    return (
      <>
        {children ? (
          <CircleCheck
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <CircleX
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
        {children ? "Tak" : "Nie"}
      </>
    );
  else if (dataType === "boolean-icon")
    return (
      <Block>
        {children ? (
          <CircleCheck
            size={18}
            className={css({
              verticalAlign: "middle",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        ) : (
          <CircleX
            size={18}
            className={css({
              verticalAlign: "middle",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
      </Block>
    );
  else if (dataType === "size") return <>{formatBytes(children as number)}</>;
  else if (dataType === "quota")
    return <>{Intl.NumberFormat().format(children as number)}</>;
  else if (dataType === "image")
    return (
      <img
        src={children as string}
        alt="banner"
        className={css({ maxHeight: "400px" })}
      />
    );

  if (dataType === "datetime")
    return (
      <span className={css({ ...$style })}>
        {new Date(String(children)).toLocaleString()}
      </span>
    );

  if (dataType === "brand") {
    if (children === BRAND.CDAction) return <>CD Action</>;
    else if (children === BRAND.PCFormat) return <>PC Format</>;
  }

  if (dataType?.includes("set:")) {
    return (
      <Block display="flex" alignItems="center" marginRight="10px">
        {Array.isArray(children) &&
          children?.map((option: Option) => (
            <ObjectTag
              key={`option-${option.id}`}
              {...(dataType.includes("labels") && {
                forLabels: true,
                labelColor: (option as Label).color,
              })}
            >
              {option.name}
            </ObjectTag>
          ))}
      </Block>
    );
  }

  if (dataType === "duration") {
    return <>{secondsToHHMMSS(Number(children))}</>;
  }

  if (dataType === "rating") {
    return <>{+Number(children).toFixed(1)}</>;
  }

  if (dataType === "reportedCommentStatus") {
    return (
      <>
        {children === "Pending" && (
          <FileLike
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.warning,
            })}
          />
        )}
        {children === "Accepted" && (
          <CircleCheck
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.positive,
            })}
          />
        )}
        {children === "Rejected" && (
          <CircleX
            size={14}
            className={css({
              verticalAlign: "middle",
              marginRight: "10px",
              marginTop: "-2px",
              display: "inline",
              color: theme.colors.negative,
            })}
          />
        )}
        {children === "Pending" && "Do weryfikacji"}
        {children === "Accepted" && "Zaakceptowany"}
        {children === "Rejected" && "Odrzucony"}
      </>
    );
  }

  if (dataType === "date")
    return <>{new Date(String(children)).toLocaleDateString()}</>;
  else
    return <span className={css({ whiteSpace: "pre-wrap" })}>{children}</span>;
}
