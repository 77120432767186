import { useStyletron } from "baseui";
import { DisplayXSmall, LabelMedium } from "baseui/typography";
import React from "react";
import { useHistory } from "react-router";
import { Bulldozer, UserPlus } from "tabler-icons-react";

import ActionsList from "../../components/actions-list";
import Cell from "../../components/cell";
import Content from "../../components/content";
import Grid from "../../components/grid";

export default function Home(): React.ReactElement {
  const [css] = useStyletron();

  const history = useHistory();

  return (
    <article>
      <Content>
        <Grid>
          <Cell span={12}>
            <Bulldozer size={90} />
            <DisplayXSmall marginBottom="scale600">
              W trakcie przygotowania
            </DisplayXSmall>
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Popularne akcje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12}>
            <ActionsList
              actions={[
                {
                  label: "Wyloguj się",
                  icon: UserPlus,
                  onClick: () => history.push("/logout"),
                },
              ]}
            />
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
