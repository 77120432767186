import { useStyletron } from "baseui";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import React, { createContext, useContext, useState } from "react";
import { Mail, X } from "tabler-icons-react";

import Button from "../components/button";
import { SUPPORT_ADMIN_MAIL, SUPPORT_ADMIN_NAME } from "../constants";

type SupportContextProps = {
  openSupportModal: () => void;
};

export const SupportContext = createContext<SupportContextProps>(
  {} as SupportContextProps
);

export function SupportProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);

  function openSupportModal() {
    setIsOpen(true);
  }

  return (
    <SupportContext.Provider
      value={{
        openSupportModal,
      }}
    >
      <Modal
        onClose={() => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        overrides={{
          Root: {
            style: {
              zIndex: 14,
            },
          },
          Dialog: {
            style: {
              borderTopLeftRadius: theme.borders.radius300,
              borderTopRightRadius: theme.borders.radius300,
              borderBottomRightRadius: theme.borders.radius300,
              borderBottomLeftRadius: theme.borders.radius300,
              paddingTop: theme.sizing.scale300,
              paddingBottom: theme.sizing.scale600,
              paddingRight: theme.sizing.scale300,
              paddingLeft: theme.sizing.scale300,
            },
          },
          Close: {
            component: () => (
              <span
                className={css({
                  position: "absolute",
                  right: theme.sizing.scale600,
                  top: theme.sizing.scale600,
                  cursor: "pointer",
                  color: "#444",
                  ":hover": {
                    color: "black",
                  },
                })}
                onClick={() => setIsOpen(false)}
              >
                <X size={20} />
              </span>
            ),
          },
        }}
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Skontaktuj się z administratorem</ModalHeader>
        <ModalBody>
          <p>
            Ta funkcja jest niedostępna. Aby z niej skorzystać, skontaktuj się z
            administratorem systemu.
          </p>
          <p>
            Opiekunem systemu jest: <strong>{SUPPORT_ADMIN_NAME}</strong>.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            $as="a"
            target="_blank"
            href={`mailto:${SUPPORT_ADMIN_MAIL}`}
            startEnhancer={<Mail size={20} />}
          >
            Skontaktuj się
          </Button>
        </ModalFooter>
      </Modal>

      {children}
    </SupportContext.Provider>
  );
}

export const useSupport = (): SupportContextProps => useContext(SupportContext);
