import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import React from "react";
import { StyleObject } from "styletron-react";
import { ArrowDown, ArrowsVertical, ArrowUp } from "tabler-icons-react";

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  sortDirection?: SortDirection | null;
  $style?: StyleObject;
};

export default function SortingTableHeader({
  children,
  onClick,
  sortDirection,
  $style,
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Block
      display="flex"
      alignItems="center"
      width="100%"
      height="100%"
      className={css({ cursor: "pointer", ...$style })}
      onClick={onClick}
    >
      {sortDirection ? (
        sortDirection === SortDirection.ASC ? (
          <ArrowUp
            size={16}
            color={theme.colors.mono600}
            className={css({ marginRight: "5px" })}
          />
        ) : (
          <ArrowDown
            size={16}
            color={theme.colors.mono600}
            className={css({ marginRight: "5px" })}
          />
        )
      ) : (
        <ArrowsVertical
          size={16}
          color={theme.colors.mono600}
          className={css({ marginRight: "5px" })}
        />
      )}
      {children}
    </Block>
  );
}
