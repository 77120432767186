import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  visibility?: boolean;
  isHidden?: boolean;
  isFeatured?: boolean;
  content: string;
};

export const COMMENTS_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "visibility",
        label: "Widoczność",
        type: FIELDS.Checkbox,
        dataType: "visibility-boolean",
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isFeatured",
        label: "Wyróżnienie",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "content",
        label: "Treść",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: true, required: true },
      },
      {
        id: "author",
        label: "Autor",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "commentable",
        label: "Skomentowany obiekt",
        type: FIELDS.Input,
        span: 8,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "ipAddress",
        label: "IP klienta",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdAt",
        label: "Data dodania",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "isHidden",
        label: "Widoczność",
        type: FIELDS.Input,
        dataType: "visibility-boolean",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "isFeatured",
        label: "Wyróżnienie",
        type: FIELDS.Input,
        dataType: "boolean",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "reportCount",
        label: "Liczba zgłoszeń",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "statistics",
    label: "Statystyki",
    fields: [
      {
        id: "upvoteCount",
        label: "Liczba polubień",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "downvoteCount",
        label: "Liczba niepolubień",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
];
