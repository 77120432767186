import {
  API,
  BlockAPI,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";

import { useImageLibrary } from "../../../contexts/image-library-context";

interface GalleryData extends BlockToolData {
  imageUrls: string[];
  isStretched?: boolean;
}

interface GalleryConfig {
  imageLibraryContext: ReturnType<typeof useImageLibrary>;
}

type GalleryNodes = {
  button?: HTMLButtonElement;
  imagesContainer?: HTMLDivElement;
};

type GallerySetting = {
  icon: string;
  name: GallerySettingName;
};

enum GallerySettingName {
  IsStretched = "isStretched",
}

const STYLESHEET = `
  .images-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
  }

  .images-container > div {
    width: 100%;
    height: 120px;
    background-position: center center;
    background-size: auto 90%;
    background-repeat: no-repeat;
    position: relative;
  }

  .images-container > div > button {
    background-color: rgb(239, 56, 48);
    top: 0;
    right: 0;
    position: absolute;
    display: none;
  }

  .images-container > div:hover > button {
    display: block;
  }
`;

export default class Gallery implements BlockTool {
  public static isReadOnlySupported = true;
  public static enableLineBreaks = true;

  private _data: GalleryData;
  private isReadOnly: boolean;
  private api: API;
  private config?: GalleryConfig;
  private nodes: GalleryNodes;
  private block?: BlockAPI;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Galeria",
      // TODO: Find appropriate icon
      icon: "G",
    };
  }

  constructor({
    data,
    readOnly,
    api,
    config,
    block,
  }: BlockToolConstructorOptions<GalleryData, GalleryConfig>) {
    this.isReadOnly = readOnly;
    this.api = api;
    this.config = config;
    this.block = block;

    this.nodes = {
      button: undefined,
      imagesContainer: undefined,
    };

    this._data = {
      ...data,
      imageUrls: data.imageUrls || [],
      isStretched: !!data.isStretched,
    };
  }

  get settings(): GallerySetting[] {
    return [
      {
        icon: `<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>`,
        name: GallerySettingName.IsStretched,
      },
    ];
  }

  get data(): GalleryData {
    return this._data;
  }

  set data(data: GalleryData) {
    this._data = { ...this.data, ...data };

    if (this.block) {
      this.block.stretched = !!data.isStretched;
    }

    this.renderImages();
  }

  private renderImages() {
    if (this.nodes.imagesContainer) {
      const children: Node[] = [];

      for (const imageUrl of this.data.imageUrls) {
        const image = document.createElement("div");
        image.classList.add(this.api.styles.block);
        image.style.backgroundImage = `url(${imageUrl})`;

        if (!this.isReadOnly) {
          const removeImage = (event: Event) => {
            event.preventDefault();

            this.data = {
              ...this.data,
              imageUrls: this.data.imageUrls.filter((url) => url !== imageUrl),
            };
          };

          const button = document.createElement("button");

          button.classList.add(this.api.styles.settingsButton);
          button.innerHTML = "X";
          button.addEventListener("click", removeImage);

          image.appendChild(button);
        }

        children.push(image);
      }

      // TypeScript throws an error that `replaceChildren` is not available on HTMLDivElement which is not true
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.nodes.imagesContainer.replaceChildren(...children);
    }
  }

  render(): HTMLElement {
    const container = document.createElement("div");
    container.classList.add(this.api.styles.block);
    container.style.textAlign = "center";

    const style = document.createElement("style");
    style.appendChild(document.createTextNode(STYLESHEET));

    const openGalleryLibrary = (event: Event) => {
      event.preventDefault();

      this.config?.imageLibraryContext.open((image) => {
        const url = `${process.env.REACT_APP_CDN_URL}/images/${image.path}`;

        this.data = {
          ...this.data,
          imageUrls: [...this.data.imageUrls, url],
        };

        this.api.blocks.insert(undefined, undefined, undefined, Infinity);
        this.api.blocks.delete(Infinity);
      });
    };

    const button = document.createElement("button");

    button.classList.add(this.api.styles.button);
    button.innerHTML = "Wgraj lub wybierz z biblioteki";
    button.addEventListener("click", openGalleryLibrary);

    const imagesContainer = document.createElement("div");
    imagesContainer.classList.add(this.api.styles.block, "images-container");

    container.appendChild(style);
    container.appendChild(imagesContainer);

    if (!this.isReadOnly) {
      container.appendChild(button);

      this.nodes.button = button;
    }

    this.nodes.imagesContainer = imagesContainer;

    this.renderImages();

    // If the stretched prop is set without setTimeout, the block doesn't render
    setTimeout(() => {
      if (this.block) {
        this.block.stretched = !!this.data.isStretched;
      }
    }, 0);

    return container;
  }

  renderSettings(): HTMLElement {
    const wrapper = document.createElement("div");
    const capitalize = (str: string) => str[0].toUpperCase() + str.substr(1);

    this.settings
      .map((setting: GallerySetting) => {
        const el = document.createElement("div");

        el.classList.add(this.api.styles.settingsButton);
        el.innerHTML = setting.icon;
        el.title = `${capitalize(setting.name)}`;

        el.classList.toggle(
          this.api.styles.settingsButtonActive,
          this.data.isStretched ?? false
        );

        wrapper.appendChild(el);

        return el;
      })
      .forEach((element, index, elements) => {
        element.addEventListener("click", () => {
          this.toggleIsStretched();

          elements.forEach((el) => {
            el.classList.toggle(
              this.api.styles.settingsButtonActive,
              this.data.isStretched ?? false
            );
          });
        });
      });

    return wrapper;
  }

  private toggleIsStretched(): void {
    this.data.isStretched = !this.data.isStretched;
    if (this.block) {
      this.block.stretched = !!this.data.isStretched;
    }
    this.api.blocks.update(
      (this.api.blocks.getBlockByIndex(
        this.api.blocks.getCurrentBlockIndex()
      ) as BlockAPI)?.id,
      this.data
    );
  }

  save(rootElement: HTMLDivElement): GalleryData {
    const caption = rootElement.querySelector(".caption")?.innerHTML || "";
    const alt = rootElement.querySelector(".alt")?.innerHTML || "";

    return {
      ...this.data,
      caption,
      alt,
    };
  }
}
