import axios from "axios";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { AlertCircle, Check, FileOff, Plus } from "tabler-icons-react";

import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledArticlesSelect,
  ControlledGamesSelect,
  ControlledTaggableTypesSelect,
  ControlledTagsKindSelect,
} from "../../../components/select";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { FormInputs, TAGS_FIELDS } from "../tags.form";

export default function TagsCreate(): React.ReactElement {
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { isLoading, setIsLoading } = useLoading();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const watchTaggableTypes = watch("taggableTypes");
  const shouldShowArticlesSelect =
    watchTaggableTypes &&
    watchTaggableTypes.length !== 0 &&
    watchTaggableTypes[0].id === "Article";
  const shouldShowGamesSelect =
    watchTaggableTypes &&
    watchTaggableTypes.length !== 0 &&
    watchTaggableTypes[0].id === "Game";

  const postData = async (values: FormInputs) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/tags`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("tagCreate", postData);

  const onSubmit = async ({
    name,
    taggableTypes,
    articlesSelect,
    gamesSelect,
    kinds,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const data = await mutateAsync({
        name,
        taggableId:
          shouldShowArticlesSelect && articlesSelect
            ? articlesSelect[0].id
            : shouldShowGamesSelect && gamesSelect
            ? gamesSelect[0].id
            : null,
        taggableType: taggableTypes && taggableTypes[0]?.id,
        kind: kinds && kinds[0]?.id,
      });
      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/tags/${data?.id}`);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    setValue("kinds", [{ id: "General" }]);
  }, []);

  return (
    <article>
      <Header
        title="Nowy tag"
        labels={["Tagi", "Tworzenie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () => history.push("/tags"),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {TAGS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      required={item.create.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.TaggableTypesSelect ? (
                        <ControlledTaggableTypesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.TagsKindSelect ? (
                        <ControlledTagsKindSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            {(shouldShowArticlesSelect || shouldShowGamesSelect) && (
              <Cell span={8}>
                <FormControl
                  label={shouldShowArticlesSelect ? "Artykuł" : "Gra"}
                  disabled={isLoading}
                  error={
                    errors[
                      shouldShowArticlesSelect
                        ? "articlesSelect"
                        : "gamesSelect"
                    ] &&
                    errors[
                      shouldShowArticlesSelect
                        ? "articlesSelect"
                        : "gamesSelect"
                    ]?.message
                  }
                >
                  {shouldShowArticlesSelect ? (
                    <ControlledArticlesSelect
                      control={control}
                      name="articlesSelect"
                      placeholder="Wybierz"
                      required={shouldShowArticlesSelect}
                      rules={{ required: formValidation.messages.required }}
                    />
                  ) : (
                    <ControlledGamesSelect
                      control={control}
                      name="gamesSelect"
                      placeholder="Wybierz"
                      required={shouldShowGamesSelect}
                      rules={{ required: formValidation.messages.required }}
                    />
                  )}
                </FormControl>
              </Cell>
            )}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
