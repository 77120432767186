import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  nick: string;
  slug: string;
  avatarUrl: string;
  backgroundImageUrl: string;
  description: string;
  socialMediaUrls?: {
    facebook?: string;
    twitter?: string;
    youtube?: string;
    xbox?: string;
    steam?: string;
    twitch?: string;
    discord?: string;
  };
  socialMediaFacebook?: string;
  socialMediaTwitter?: string;
  socialMediaYoutube?: string;
  socialMediaXbox?: string;
  socialMediaSteam?: string;
  socialMediaTwitch?: string;
  socialMediaDiscord?: string;
  socialMediaPlayStation?: string;
  gamingPlatformsSelect?: { id: number; label?: string }[];
  gamingPlatforms?: string;
  favouriteGamesSelect?: { id: number; label?: string }[];
  favouriteGameIds?: number[];
  consideredGamesSelect?: { id: number; label?: string }[];
  consideredGameIds?: number[];
  ownedGamesSelect?: { id: number; label?: string }[];
  ownedGameIds?: number[];
  playedGamesSelect?: { id: number; label?: string }[];
  playedGameIds?: number[];
  completedGamesSelect?: { id: number; label?: string }[];
  completedGameIds?: number[];
  isVisible: boolean;
  trackingCode?: string;
};

export const EDITORS_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "nick",
        label: "Nick",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "slug",
        label: "Slug",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "isVisible",
        label: "Widoczność",
        type: FIELDS.Checkbox,
        dataType: "visibility-boolean",
        span: 4,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "articleCount",
        label: "Liczba artykułów",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "followerCount",
        label: "Liczba obserwatorów",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "socialMedia",
    label: "Social Media",
    fields: [
      {
        id: "socialMediaFacebook",
        label: "Facebook",
        placeholder: "https://link-do-twojego-profilu",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "socialMediaTwitter",
        label: "Twitter",
        placeholder: "https://link-do-twojego-profilu",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "socialMediaYoutube",
        label: "YouTube",
        placeholder: "https://link-do-twojego-profilu",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "socialMediaXbox",
        label: "Xbox",
        placeholder: "https://link-do-twojego-profilu",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "socialMediaSteam",
        label: "Steam",
        placeholder: "https://link-do-twojego-profilu",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "socialMediaTwitch",
        label: "Twitch",
        placeholder: "https://link-do-twojego-profilu",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "socialMediaDiscord",
        label: "Discord",
        placeholder: "pseudonim#0000",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "socialMediaPlayStation",
        label: "PlayStation",
        placeholder: "https://link-do-twojego-profilu",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "games",
    label: "Gry",
    fields: [
      {
        id: "gamingPlatformsSelect",
        label: "Platformy do gier",
        type: FIELDS.GamingPlatformsSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "favouriteGamesSelect",
        label: "Ulubione gry",
        type: FIELDS.GamesSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "consideredGamesSelect",
        label: "Rozważane gry",
        type: FIELDS.GamesSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "ownedGamesSelect",
        label: "Posiadane gry",
        type: FIELDS.GamesSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "playedGamesSelect",
        label: "Grane gry",
        type: FIELDS.GamesSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "completedGamesSelect",
        label: "Ukończone gry",
        type: FIELDS.GamesSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: "trackingCode",
        label: "Kod śledzenia",
        caption: "Ukryty kod HTML niewidoczny dla użytkownika.",
        span: 12,
        type: FIELDS.TextArea,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "avatar",
    label: "Avatar",
    fields: [
      {
        id: "avatarUrl",
        label: "URL",
        type: FIELDS.ImagePicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "backgroundImage",
    label: "Tło",
    fields: [
      {
        id: "backgroundImageUrl",
        label: "URL",
        type: FIELDS.ImagePicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
