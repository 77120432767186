import axios from "axios";
import { DURATION, useSnackbar } from "baseui/snackbar";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router";
import { AlertCircle, Check } from "tabler-icons-react";

import { AUTH_TOKEN, BASIC_AUTH } from "../../../constants";
import { useAuth } from "../../../contexts/auth-context";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import Login from ".";

export default function LoginForumCallback(): React.ReactElement {
  const { setIsLoading } = useLoading();
  const { enqueue } = useSnackbar();
  const { setIsAuthenticated, setUser } = useAuth();

  const history = useHistory();
  const location = useLocation();

  const login = async ({ loginToken }: { loginToken: string }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/auth/login`,
      {
        loginToken,
      },
      BASIC_AUTH
    );

    return data;
  };

  const { data, isError, isSuccess, mutate } = useMutation("login", login);

  useEffect(() => setIsLoading(true), []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has("loginToken")) {
      mutate({
        loginToken: params.get("loginToken") as string,
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem(AUTH_TOKEN, data.accessToken);
      setUser(data.user);
      setIsAuthenticated(true);

      enqueue({
        message: "Zalogowano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });

      history.push(`/`);

      setIsLoading(false);
    }

    if (isError) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
      setIsLoading(false);
    }
  }, [isError, isSuccess]);

  return <Login />;
}
