import { BlockAPI, ToolboxConfig } from "@editorjs/editorjs";
import BaseQuote from "@editorjs/quote";

export default class Quote extends BaseQuote {
  constructor({ data, config, api, readOnly }: any) {
    super({ data, config, api, readOnly });
    this.api = api;
    this.readOnly = readOnly;
    this.config = config;

    this.quotePlaceholder = "Treść";
    this.captionPlaceholder = "Podpis";

    this.data = {
      text: data.text || "",
      caption: data.caption || "",
      size: data.size || "small",
    };
  }

  get settings() {
    return [
      {
        name: "small",
        icon: `S`,
        label: "Mały",
      },
      {
        name: "large",
        icon: `L`,
        label: "Duży",
      },
    ];
  }

  renderSettings() {
    const wrapper = this._make("div", [this.CSS.settingsWrapper], {});
    const capitalize = (str: string) => str[0].toUpperCase() + str.substr(1);

    this.settings
      .map((tune) => {
        const el = this._make("div", this.CSS.settingsButton, {
          innerHTML: tune.icon,
          title: `${capitalize(tune.label)} rozmiar`,
        });

        el.classList.toggle(
          this.CSS.settingsButtonActive,
          tune.name === this.data.size
        );

        wrapper.appendChild(el);

        return el;
      })
      .forEach((element, index, elements) => {
        element.addEventListener("click", () => {
          this._toggleTune(this.settings[index].name);

          elements.forEach((el, i) => {
            const { name } = this.settings[i];

            el.classList.toggle(
              this.CSS.settingsButtonActive,
              name === this.data.size
            );
          });
        });
      });

    return wrapper;
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
      caption: {
        br: true,
      },
      size: {},
    };
  }

  _toggleTune(tune: string) {
    this.data.size = tune;

    this.api.blocks.update(
      (this.api.blocks.getBlockByIndex(
        this.api.blocks.getCurrentBlockIndex()
      ) as BlockAPI)?.id,
      this.data
    );
  }

  static get toolbox(): ToolboxConfig {
    return {
      icon:
        '<svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg"><path d="M13.53 6.185l.027.025a1.109 1.109 0 0 1 0 1.568l-5.644 5.644a1.109 1.109 0 1 1-1.569-1.568l4.838-4.837L6.396 2.23A1.125 1.125 0 1 1 7.986.64l5.52 5.518.025.027zm-5.815 0l.026.025a1.109 1.109 0 0 1 0 1.568l-5.644 5.644a1.109 1.109 0 1 1-1.568-1.568l4.837-4.837L.58 2.23A1.125 1.125 0 0 1 2.171.64L7.69 6.158l.025.027z" /></svg>',
      title: "Cytat",
    };
  }
}
