import { OutputData } from "@editorjs/editorjs";

import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  issueNumber: string;
  description: string;
  issuedOn: string | null;
  nextIssueOn?: string | null;
  issueCoverUrl: string;
  issueCoverArtUrl: string;
  paperIssuePrice: number | null;
  digitalIssuePrice: number | null;
  bannerUrl?: string;
  storeUrl?: string;
  lead?: string;
  content?: Record<string, any>[];
  publishedAt: string | null;
  status?: string;
  statusSelect?: { id: string; label?: string }[];
  tags?: { id: number; label?: string }[];
  tagIds?: number[] | [];
  body?: OutputData[];
  trackingCode?: string;
};

export const MAGAZINES_FIELDS: FieldsGroup[] = [
  {
    id: "publication",
    label: "Publikacja",
    fields: [
      {
        id: "status",
        label: "Status",
        type: FIELDS.Input,
        dataType: "article-status",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "statusSelect",
        label: "Status publikacji",
        type: FIELDS.MagazinesStatusSelect,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "publishedAt",
        label: "Data publikacji",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "datetime",
      },
      {
        id: "publishedAt",
        label: "",
        type: FIELDS.DateTimePicker,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "issueNumber",
        label: "Numer wydania",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "issuedOn",
        label: "Data wydania",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "paperIssuePrice",
        label: "Cena papierowego wydania",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "digitalIssuePrice",
        label: "Cena elektronicznego wydania",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "readTime",
        label: "Czas czytania (min.)",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "nextIssueOn",
        label: "Data następnego wydania",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "storeUrl",
        label: "Link do sklepu",
        type: FIELDS.Input,
        dataType: "external",
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "lead",
        label: "Lead",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: "body",
        label: "",
        span: 12,
        type: FIELDS.Editor,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "trackingCode",
        label: "Kod śledzenia",
        caption: "Ukryty kod HTML niewidoczny dla użytkownika.",
        span: 12,
        type: FIELDS.TextArea,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "issueCoverUrl",
    label: "Grafiki",
    fields: [
      {
        id: "issueCoverUrl",
        label: "Okładka",
        type: FIELDS.ImagePicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "issueCoverArtUrl",
        label: "Art",
        type: FIELDS.ImagePicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "bannerUrl",
    label: "Banner",
    fields: [
      {
        id: "bannerUrl",
        label: "",
        type: FIELDS.ImagePicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "tags",
    label: "Tagi",
    fields: [
      {
        id: "tags",
        label: "",
        type: FIELDS.TagsSelect,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
