import { ToolboxConfig } from "@editorjs/editorjs";
import List from "@editorjs/list";

export default class Summary extends List {
  static get toolbox(): ToolboxConfig {
    return {
      title: "W skrócie",
      // TODO: Find appropriate icon
      icon: "S",
    };
  }

  renderSettings() {
    return null;
  }

  onPaste() {
    return false;
  }

  static get pasteConfig() {
    return {
      tags: [],
    };
  }
}
