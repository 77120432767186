import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  nickname: string;
  email: string;
  password: string;
  avatarUrl: string;
  backgroundImageUrl: string;
  age: number;
  sex: string | null;
  sexSelect?: { id: string; label?: string }[];
  residence: string;
  rolesSelect?: { id: string; label?: string }[];
  roles?: string[];
};

export const USERS_FIELDS: FieldsGroup[] = [
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "nickname",
        label: "Nick",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "rolesSelect",
        label: "Rola",
        type: FIELDS.UserRolesSelect,
        dataType: "role",
        span: 4,
        show: { visible: true, accessor: ["roles"] },
        create: { visible: true },
        edit: { visible: true },
        role: "Administrator",
      },
      {
        id: "email",
        label: "E-mail",
        type: FIELDS.Input,
        dataType: "email",
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "password",
        label: "Hasło",
        type: FIELDS.Input,
        span: 4,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: false },
      },
      {
        id: "age",
        label: "Wiek",
        type: FIELDS.NumberInput,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "sexSelect",
        label: "Płeć",
        type: FIELDS.SexSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "sex",
        label: "Płeć",
        type: FIELDS.Input,
        dataType: "sex",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "residence",
        label: "Miejsce",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "lastActivityAt",
        label: "Data ostatniej aktywności",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "statistics",
    label: "Statystyki",
    fields: [
      {
        id: "recommendationsCount",
        label: "Liczba rekomendacji",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "providedRatingsCount",
        label: "Liczba podanych ocen",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "graphics",
    label: "Grafiki",
    fields: [
      {
        id: "avatarUrl",
        label: "Avatar",
        type: FIELDS.Input,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "backgroundImageUrl",
        label: "Tło",
        type: FIELDS.Input,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
