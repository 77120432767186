import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ButtonProps } from "baseui/button";
import { FileUploader, FileUploaderProps } from "baseui/file-uploader";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import * as React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { AlertCircle, Trash } from "tabler-icons-react";

import { SnackbarError } from "../utils/snackbarTypes";
import Button from "./button";

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 bajtów";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bajtów", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

type Props = FileUploaderProps;

export default function FilesPicker(props: Props): React.ReactElement {
  const [, theme] = useStyletron();

  const { enqueue } = useSnackbar();

  return (
    <FileUploader
      overrides={{
        ContentMessage: () => (
          <LabelSmall marginBottom="scale600" color="inputPlaceholder">
            Przenieś i upuść plik tutaj
          </LabelSmall>
        ),
        ButtonComponent: {
          props: {
            overrides: {
              BaseButton: {
                component: (props: ButtonProps) => (
                  <Button {...props}>Wybierz pliki</Button>
                ),
              },
            },
          },
        },
        FileDragAndDrop: {
          style: {
            backgroundColor: theme.colors.inputFill,
            borderTopLeftRadius: theme.borders.radius300,
            borderTopRightRadius: theme.borders.radius300,
            borderBottomRightRadius: theme.borders.radius300,
            borderBottomLeftRadius: theme.borders.radius300,
            borderTopColor: theme.colors.inputBorder,
            borderRightColor: theme.colors.inputBorder,
            borderBottomColor: theme.colors.inputBorder,
            borderLeftColor: theme.colors.inputBorder,
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
          },
        },
      }}
      onDropRejected={(rejectedFiles: File[]) =>
        enqueue(
          {
            message:
              "Następujące pliki nie spełniają wymagań: " +
              rejectedFiles.map((i) => i.name + "; "),
            overrides: SnackbarError,
            //eslint-disable-next-line react/display-name
            startEnhancer: ({ size }: { size: number }) => (
              <AlertCircle size={size} />
            ),
          },
          DURATION.short
        )
      }
      {...props}
    />
  );
}

export function ControlledFilesPicker({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [css, theme] = useStyletron();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <div>
          {value && (
            <Block
              display="flex"
              flexDirection="column"
              marginBottom="scale200"
            >
              {value.map((file: File, index: number) => (
                <Block
                  key={index}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  paddingTop="scale100"
                  paddingBottom="scale100"
                >
                  <Trash
                    size={18}
                    className={css({
                      marginRight: theme.sizing.scale200,
                      color: theme.colors.negative,
                      ":hover": {
                        cursor: "pointer",
                        color: theme.colors.negative500,
                      },
                    })}
                    onClick={() =>
                      onChange(value.filter((i: File) => i !== file))
                    }
                  />
                  <LabelXSmall $style={{ fontWeight: 400 }}>
                    {file.name}
                  </LabelXSmall>

                  <LabelXSmall marginLeft="auto" $style={{ fontWeight: 400 }}>
                    {formatBytes(file.size)}
                  </LabelXSmall>
                </Block>
              ))}
            </Block>
          )}
          <FilesPicker
            onDropAccepted={(acceptedOrRejected: File[]) => {
              if (value) onChange([...value, ...acceptedOrRejected]);
              else onChange(acceptedOrRejected);
            }}
            onBlur={onBlur}
            {...rest}
          />
        </div>
      )}
    />
  );
}
